import React, { useState } from "react";
import RichTextEditor from "react-rte";
import { useFirestore } from "../hooks/useFirestore";

const TextEditor = ({
  initialDescription,
  documentId,
  docFieldName,
  collectionName,
  onSave,
  onCancel,
}) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(initialDescription, "html")
  );
  const { updateDocument, response } = useFirestore(collectionName);

  const handleSaveClick = async () => {
    try {
      const updateData = {};
      updateData[docFieldName] = value.toString("html");
      await updateDocument(documentId, updateData);
      onSave();
    } catch (error) {
      console.error("Error updating description:", error);
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };
  return (
    <div>
      <div>
        <RichTextEditor value={value} onChange={setValue} />
        <button className="product_edit_save_btn" onClick={handleSaveClick}>
          Save
        </button>
        <button
          className="product_edit_save_btn m-2"
          onClick={handleCancelClick}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TextEditor;
