import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { format } from "date-fns";
import { useFirestore } from "../hooks/useFirestore";
import { projectFirestore } from "../firebase/config";

// import scss
import "./UserList.css";

const UserList = ({ user }) => {
  let userRoleClass = "";
  if (user.role == "admin") {
    userRoleClass = "admin";
  } else if (user.role == "frontdesk") {
    userRoleClass = "front_desk";
  } else {
    userRoleClass = "customer";
  }

  const firestore = useFirestore("../hooks/useFirestore");

  const updateUserStatus = (newStatus) => {
    // Update the user's status in Firestore
    projectFirestore
      .collection("users")
      .doc(user.id) // Replace "users" with the actual Firestore collection name
      .update({ status: newStatus })
      .then(() => {
        // Success, you can handle it as needed
        console.log("User status updated successfully");
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error updating user status:", error);
      });
  };

  const updateUserRole = (newRole) => {
    // Update the user's role in Firestore
    projectFirestore
      .collection("users")
      .doc(user.id) // Replace "users" with the actual Firestore collection name
      .update({ role: newRole })
      .then(() => {
        // Success, you can handle it as needed
        console.log("User role updated successfully");
      })
      .catch((error) => {
        console.error("Error updating user role:", error);
      });
  };

  return (
    <>
      <div
        className={`user_card_single relative ${userRoleClass} ${
          user.status === "inactive" ? "inactive" : ""
        }`}
      >
        <div className="ucs_body">
          <div className="ucsb_left relative">
            <div className="img_container">
              <img src={user.photoURL} alt="user img 1" />
            </div>
          </div>
          <div className="ucsb_right">
            <div className="ucs_header">
              <div className="ucsh_left">
                <h6>Created at</h6>
                {/* <h5>{ user.createdAt }</h5> */}
                <h5>{format(user.createdAt.toDate(), "MMM dd, yyyy")}</h5>
              </div>
              <div className="ucsh_right">
                <h6>Last login</h6>
                <h5>
                  {format(user.lastLoginTimestamp.toDate(), "MMM dd, yyyy")}
                </h5>
              </div>
            </div>
            <h4 className="u_name">{user.fullName}</h4>
            <h4 className="u_email">{user.email}</h4>
            <h4 className="u_phone">{user.phoneNumber}</h4>
          </div>
        </div>

        <div className="ucs_footer ">
          <h4 className="u_status relative">
            {user.status}
            <Popup
              trigger={
                <div className="pointer edit_bg">
                  <img
                    src="./assets/img/edit_icon.png"
                    className="edit_sq_icon"
                    alt="user img 2"
                  />
                </div>
              }
              position="left"
              className="userlist_parent_popup"
            >
              <div className="userlist-popup-parent-div">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "var(--pink)",
                      backgroundColor: "#f2f2f2",
                      width: "100%",
                    }}
                  >
                    Change Status
                  </h5>
                  <br />
                  <button
                    className="btn_fill"
                    style={{
                      margin: "0px 5px 5px 5px",
                      backgroundColor: "var(--peach-color)",
                    }}
                    onClick={() => updateUserStatus("active")}
                  >
                    ACTIVE
                  </button>
                  <button
                    className="btn_fill"
                    style={{ margin: "5px" }}
                    onClick={() => updateUserStatus("inactive")}
                  >
                    INACTIVE
                  </button>
                </div>
              </div>
            </Popup>
          </h4>

          <h4 className="u_role relative">
            {user.role}
            <Popup
              trigger={
                <div className="pointer edit_bg">
                  <img
                    src="./assets/img/edit_icon.png"
                    className="edit_sq_icon"
                    alt="user img 3"
                  />
                </div>
              }
              position="left"
              className="userlist_parent_popup"
            >
              <div className="userlist-popup-parent-div">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "var(--pink)",
                      backgroundColor: "#f2f2f2",
                      width: "100%",
                    }}
                  >
                    Change Role
                  </h5>
                  <br />
                  <button
                    className="btn_fill"
                    style={{
                      margin: "0px 5px 5px 5px",
                      backgroundColor: "var(--peach-color)",
                    }}
                    onClick={() => {
                      updateUserRole("admin");
                    }}
                  >
                    ADMIN
                  </button>
                  <button
                    className="btn_fill"
                    style={{ margin: "5px" }}
                    onClick={() => updateUserRole("customer")}
                  >
                    CUSTOMER
                  </button>
                  <button
                    className="btn_fill"
                    style={{ margin: "5px", backgroundColor: "var(--pink)" }}
                    onClick={() => updateUserRole("frontdesk")}
                  >
                    FRONTDESK
                  </button>
                </div>
              </div>
            </Popup>
          </h4>
        </div>
      </div>
    </>
  );
};

export default UserList;
