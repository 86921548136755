import React, { useState, useEffect } from "react";
import "./Team.css";
import { Link, useLocation } from "react-router-dom";
import { FaExpand } from "react-icons/fa";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCollection } from "../hooks/useCollection";
import { useFirestore } from "../hooks/useFirestore";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";

const Team = () => {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const { user } = useAuthContext();
  // Fetch data from the "media" collection
  const { documents: galleryMedia, error: galleryMediaError } = useCollection(
    "media"
    // [("category", "==", "gallery")]
  );

  // Fetch data from the "doctors team" collection
  const { documents: doctorsteam, error: doctorsteamError } =
    useCollection("doctors_team");
  // Add doctors
  const { addDocument, response } = useFirestore("doctors_team");

  // fetch recent added images from gallery
  const recentImages =
    galleryMedia &&
    galleryMedia
      .filter(
        (item) =>
          item.pageName?.toUpperCase() === "GALLERY" &&
          item.mediaType?.toUpperCase() === "IMAGE"
      )
      .sort((a, b) => b.createdAt - a.createdAt)
      .slice(0, 8);

  const [isEditing, setIsEditing] = useState(false);
  const [productName, setProductName] = useState("Product Name");
  const [selectedImage, setSelectedImage] = useState(null);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [addingDoctor, setAddingDoctor] = useState(false);

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setShowFileInput(true); // Show file input after selecting an image
  };

  // Add images in Firestore and Firebase Storage
  const handleAddDoctor = async () => {
    // Validation: Check if product name is provided
    if (!productName || productName == "Product Name") {
      alert("Please provide product name.");
      return;
    }

    try {
      // Check if there is a selected image
      if (selectedImage) {
        setIsUploading(true); // Set uploading status to true

        // Upload image to Firebase Storage
        const storageRef = projectStorage.ref(
          `doctor_img/${selectedImage.name}`
        );
        await storageRef.put(selectedImage);

        // Get the download URL
        const image_url = await storageRef.getDownloadURL();

        // After uploading, show the confirmation button
        setShowFileInput(false);

        // Create a new doctor object
        const newDoctor = {
          name: productName,
          price: "Add Price",
          discount: "Add Discount",
          rating: "Add Rating",
          specialization: "Add Specialization",
          product_img_1: image_url,
          short_description: "Add Short Description",
          description: "Add Full Description About Doctor",
          status: "active",
          createdAt: timestamp.fromDate(new Date()),
        };
        // Add a new doctor document to Firestore
        await addDocument(newDoctor);
        // Reset product name and selected image after adding the product
        setProductName("Product Name");
        setSelectedImage(null);
        setAddingDoctor(false); // Set addingDoctor back to false after adding the doctor
      }
      console.log("After image upload");
    } catch (error) {
      console.error("Error adding product:", error);
    } finally {
      setIsUploading(false); // Reset uploading status
    }
  };

  // Reset product name and selected image
  const handleCancel = () => {
    setProductName("Product Name");
    setSelectedImage(null);
    setAddingDoctor(false); // Set addingDoctor back to false on cancel
  };

  const handleImageClick = (image) => {
    setFullscreenOpen(image);
  };

  const closeFullScreen = () => {
    setFullscreenOpen(null);
  };
  return (
    <div>
      <div className="pg_contact_us">
        <div className="banner relative">
          <img
            src="./assets/img/team_pg_banner.jpg"
            alt="team 3 logo img"
          ></img>
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">
                  Experience the Excellence of Our Team
                </h3>
                <h2 className="banner_heading">
                  Meet our Renowned team of Doctors
                </h2>
                {/* <Link to="contactus">
                  <button className="btn_fill"> Us</button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="doctors-area">
        <div className="container">
          <div className="section-title">
            <h2>Meet Our Doctors</h2>
          </div>
          <div
            className="row"
            style={{
              justifyContent: "center",
            }}
          >
            {user && user.role == "admin" && (
              <div className="col-12 col-sm-6 col-lg-4 products-cards-parent-div">
                <div className="doctor-item ss">
                  <div className="doctor-top product-thumb-img d-flex align-items-center justify-content-center">
                    {selectedImage ? (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="team 34logo img"
                      />
                    ) : (
                      <img
                        src="https://securitysolutions.me/wp-content/uploads/2018/12/dummy-person.jpg"
                        alt="service 37 logo img"
                      />
                    )}
                    <div className="d-flex align-items-center justify-content-center">
                      <div style={{ margin: "auto" }}>
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="imageInput"
                          style={{ cursor: "pointer" }}
                        >
                          Add Images
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="booking_services_price_location">
                    {/* <h5>Add Price</h5> */}
                  </div>
                  <div className="booking_services_name_haedings_parent">
                    {/* {isEditing ? ( */}
                    <input
                      type="text"
                      placeholder="Add Name"
                      // value={productName}
                      onChange={handleNameChange}
                      onBlur={handleNameBlur}
                      required
                    />

                    <div className="booking_services_two_buttons">
                      {showFileInput ? (
                        <>
                          <button
                            className="btn_fill"
                            onClick={handleAddDoctor}
                            disabled={isUploading} // Disable the button when uploading
                          >
                            {isUploading ? "Uploading..." : "Confirm"}
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="booking_services_book_now_btn"
                          onClick={handleAddDoctor}
                        >
                          Add Doctor
                        </button>
                      )}
                      <button
                        type="button"
                        className="d-flex booking_services_see_all_btn"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {doctorsteam &&
              doctorsteam.map((doctor, index) => {
                // Check if the user is an admin or if the doctor's status is active
                const isAdminOrActive =
                  user?.role == "admin" || doctor.status == "active";

                // Render the doctor only if the user is admin or the doctor is active
                if (isAdminOrActive) {
                  return (
                    <div
                      key={index}
                      className="col-sm-6 col-lg-4  pghome-doctor-column"
                    >
                      <Link to={`/doctor-details/${doctor.id}`}>
                        <div className="doctor-item">
                          <div className="doctor-top">
                            <div
                              className="d-flex align-items-center toglle_btn"
                              style={{ position: "absolute", margin: "10px" }}
                            >
                              {user?.role == "admin" && (
                                <button
                                  className={`booking_services_book_now_btn ${doctor.status}`}
                                  style={{
                                    backgroundColor:
                                      doctor.status === "active"
                                        ? "var(--click-color)"
                                        : "var(--pink)",
                                  }}
                                >
                                  {doctor.status}
                                </button>
                              )}
                            </div>
                            <img src={doctor.product_img_1} alt="Doctor img" />
                            {/* <Link to="/contactus">Get Appointment</Link> */}
                          </div>
                          <div className="doctor-bottom">
                            <h3>
                              <a
                                href="/doctor-details"
                                style={{ color: "var(--black-color)" }}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: doctor.name.toString("html"),
                                  }}
                                ></p>
                              </a>
                            </h3>
                            <span>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    doctor.specialization.toString("html"),
                                }}
                              ></p>
                            </span>
                            <p style={{ color: "var(--black-color)" }}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    doctor.short_description.toString("html"),
                                }}
                              ></p>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }
                // If the user is not an admin and the doctor is not active, render nothing
                return null;
              })}
          </div>
        </div>
      </section>
      {/* <Gallery category="gallery" showAddMoreButton={false} /> */}
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h2>Gallery Showcase</h2>
          </div>
          {recentImages &&
            recentImages.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-6 col-lg-3"
                  style={{ marginTop: "10px" }}
                >
                  <div className="show_gallery_data">
                    <div style={{ position: "relative" }}>
                      <div className="gallery-fullscreen-icon">
                        <FaExpand onClick={() => handleImageClick(item)} />
                      </div>
                      <img
                        src={item.mediaUrl}
                        alt={item.title}
                        loading="lazy"
                        className="show_gallery_data_images"
                        onClick={() => handleImageClick(item)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {fullscreenOpen && (
        <div className="fullscreen-overlay" onClick={closeFullScreen}>
          <img
            src={fullscreenOpen.mediaUrl}
            alt={fullscreenOpen.title}
            className="fullscreen-image"
          />
        </div>
      )}
      <div
        className="d-flex justify-content-center"
        style={{ margin: "20px 0px 20px 0px" }}
      >
        <Link to="/gallery">
          <button className="btn_fill">More</button>
        </Link>
      </div>
    </div>
  );
};

export default Team;
