import React, { useState, useRef, useEffect } from "react";
import Popup from "./Popup";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import ReactSwitch from "react-switch";
import { FaExpand } from "react-icons/fa";
import { useFirestore } from "../hooks/useFirestore";
import { useCollection } from "../hooks/useCollection";
import "./Gallery.css"; // Import your CSS file
import AddMedia from "./AddMedia";
import YoutubeVideos from "./YoutubeVideos";

export default function Gallery({
  section,
  // category = "gallery",
  showAddMoreButton = true,
}) {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";
  // const { documents: galleryData } = useCollection("gallery");
  // const fileInputRef = useRef(null);

  const { documents: dbMediaDocuments, error: dbMediaError } =
    useCollection("media");

  // const offerImagesDocuments =
  //   dbMediaDocuments &&
  //   dbMediaDocuments.filter((item) => item.mediaType.toUpperCase() == "IMAGE");

  // allImagesSection
  const allImagesSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "MORE"
    );
  // dentalImagesSection
  const dentalImagesSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "DENTAL"
    );
  // dermatologyImagesSection
  const dermatologyImagesSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "DERMATOLOGY"
    );
  // homeopathyImagesSection
  const homeopathyImagesSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "HOMEOPATHY"
    );
  //  hijamaImagesSection
  const hijamaImagesSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "HIJAMA"
    );

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const videoRef = useRef(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting with productId:", productIdToDelete);

      if (productIdToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Offer deleted successfully", productIdToDelete);
      } else {
        console.error("No productId specified for deletion");
      }
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  useEffect(() => {
    const handleInteraction = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
      document.removeEventListener("click", handleInteraction);
    };

    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeFullScreen = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery_parent_div">
      <div className="container">
        <div className="row">
          <Popup
            showPopupFlag={isDeletePopupOpen}
            setShowPopupFlag={setIsDeletePopupOpen}
            msg="Are you sure you want to delete this?"
            setPopupReturn={(confirmed) => {
              if (confirmed) {
                handleConfirmDeleteProduct();
              }
            }}
          />
          <div className="section-title">
            <h2>Gallery Showcase</h2>
          </div>
          {allImagesSection &&
            allImagesSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == section
                  : media.status == "active" &&
                    (!section || media.section == section)
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        {" "}
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.title}
                          loading="lazy"
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>
                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="more" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="more"
                pageName="gallery"
                section="more"
                addMoreVideosButton={false}
              />{" "}
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="more"
                pageName="gallery"
                section="more"
                addMoreImagesButton={false}
              />{" "}
            </div>
          )}
          {/* End All Images Gallery Showcase */}
          {/* dental section */}
          <div className="section-title">
            <h2>Dental Showcase</h2>
          </div>
          {dentalImagesSection &&
            dentalImagesSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == section
                  : media.status == "active" &&
                    (!section || media.section == section)
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        {" "}
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.title}
                          loading="lazy"
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="dental" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="dental"
                pageName="gallery"
                section="dental"
                addMoreVideosButton={false}
              />{" "}
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="dental"
                pageName="gallery"
                section="dental"
                addMoreImagesButton={false}
              />{" "}
            </div>
          )}
          {/* End dental Gallery */}
          {/* dermatology section */}
          <div className="section-title">
            <h2>Dermatology Showcase</h2>
          </div>
          {dermatologyImagesSection &&
            dermatologyImagesSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == section
                  : media.status == "active" &&
                    (!section || media.section == section)
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        {" "}
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.title}
                          loading="lazy"
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="dermatology" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="dermatology"
                pageName="gallery"
                section="dermatology"
                addMoreVideosButton={false}
              />{" "}
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="dermatology"
                pageName="gallery"
                section="dermatology"
                addMoreImagesButton={false}
              />{" "}
            </div>
          )}{" "}
          {/* End dermatology Gallery */}
          {/* homeopathy section */}
          <div className="section-title">
            <h2>Homeopathy Showcase</h2>
          </div>
          {homeopathyImagesSection &&
            homeopathyImagesSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == section
                  : media.status == "active" &&
                    (!section || media.section == section)
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        {" "}
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.title}
                          loading="lazy"
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="homeopathy" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="homeopathy"
                pageName="gallery"
                section="homeopathy"
                addMoreVideosButton={false}
              />{" "}
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="homeopathy"
                pageName="gallery"
                section="homeopathy"
                addMoreImagesButton={false}
              />{" "}
            </div>
          )}
          {/* End homeopathy Gallery */}
          {/* Hijama section */}
          <div className="section-title">
            <h2>Hijama Showcase</h2>
          </div>
          {hijamaImagesSection &&
            hijamaImagesSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == section
                  : media.status == "active" &&
                    (!section || media.section == section)
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        {" "}
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.title}
                          loading="lazy"
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "white",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}{" "}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="hijama" />
          {/* add new images */}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="hijama"
                pageName="gallery"
                section="hijama"
                addMoreVideosButton={false}
              />{" "}
            </div>
          )}
          {/* add new videos */}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="hijama"
                pageName="gallery"
                section="hijama"
                addMoreImagesButton={false}
              />{" "}
            </div>
          )}
          {/* End Hijama Gallery */}
        </div>
      </div>
    </div>
  );
}
