import React from "react";
import "./PaymentList.css";
const PaymentList = ({ bookingdocuments }) => {
    return (
        <div className="row">
            {bookingdocuments.map((document) => (
                <div className="col-md-4">
                    <div className={`p_card ${document.paymentstatus !== "paid" ? "paymentunpaid" : ""}`}>
                        <div className="ribbon">
                            <span>{document.paymentstatus}</span>
                        </div>
                        <div className="p_card_header">
                            <span className="ra pch_left">
                                <span class="material-symbols-outlined rs">currency_rupee</span>
                                <span className="pa">
                                    {document.BookingAmount}
                                </span>
                            </span>
                            <div className="pch_right">
                                <div className="d_single">
                                    <div className="label">Paid on</div>
                                    <div className="value">
                                        {document.paymentstatus === "paid" ? (
                                            document.TransactionTime
                                        ) : (
                                            "----------"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p_card_body">
                            <div className="pcb_left">

                                <div className="d_single">
                                    <div className="label">Name</div>
                                    <div className="value">{document.customerName}</div>
                                </div>
                                <div className="d_single">
                                    <div className="label">Email</div>
                                    <div className="value">{document.customerEmail}</div>
                                </div>
                                <div className="d_single">
                                    <div className="label">Booking/Order ID</div>
                                    <div className="value">{document.id}</div>
                                </div>

                            </div>
                            <div className="pcb_right">
                                <div className="d_single">
                                    <div className="label">Phone Number</div>
                                    <div className="value">{document.customerPhone}</div>
                                </div>
                                <div className="d_single">
                                    <div className="label">Paid for</div>
                                    <div className="value">{document.bookingReason}</div>
                                </div>
                                <div className="d_single">
                                    <div className="label">Transaction ID</div>
                                    <div className="value">
                                        {document.paymentstatus === "paid"
                                            ?
                                            "896587456985"
                                            :
                                            "------------"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p_card_footer">
                            {document.paymentstatus === "paid"
                                ?
                                "The payment was made using a debit card."
                                :
                                "Payment is currently unpaid."
                            }
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PaymentList;