import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLocation } from "react-router-dom";
import { useDocument } from "../hooks/useDocument";
import { useFirestore } from "../hooks/useFirestore";
import { projectStorage } from "../firebase/config";
import "reactjs-popup/dist/index.css";
import { Popup as Popup1 } from "reactjs-popup";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import Popup from "./Popup";
import "./BookingList.css";
//Date Formatter MMM dd, yyyy
import { format, isBefore } from "date-fns"; // Import the date comparison function

// START REACT STEPPER
const QontoConnector = styled(StepConnector)(({ theme, ownerState }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        ownerState && ownerState.status === "rejected"
          ? "red"
          : ownerState && ownerState.status === "upcoming"
          ? "orange"
          : "green",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        ownerState && ownerState.status === "rejected"
          ? "red"
          : ownerState && ownerState.status === "upcoming"
          ? "orange"
          : "green",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color:
    ownerState && ownerState.status === "rejected"
      ? "red"
      : ownerState && ownerState.status === "upcoming"
      ? "orange"
      : "green",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color:
      ownerState && ownerState.status === "rejected"
        ? "red"
        : ownerState && ownerState.status === "upcoming"
        ? "orange"
        : "green",
  }),
  "& .QontoStepIcon-completedIcon": {
    color:
      ownerState && ownerState.status === "rejected"
        ? "red"
        : ownerState && ownerState.status === "upcoming"
        ? "orange"
        : "green",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, ownerState } = props;

  let iconComponent;

  switch (ownerState.status) {
    case "approved":
      iconComponent = <InfoIcon style={{ fontSize: 18 }} />;
      break;
    case "rejected":
      iconComponent = <ClearIcon style={{ fontSize: 18 }} />;
      break;
    case "waiting for approval":
      iconComponent = <HourglassEmptyIcon style={{ fontSize: 18 }} />;
      break;
    case "upcoming": // Handle "upcoming" status
      iconComponent = <HourglassEmptyIcon style={{ fontSize: 18 }} />;
      break;
    default:
      iconComponent = <div className="QontoStepIcon-circle" />; // Default circle icon
      break;
  }

  return (
    <QontoStepIconRoot ownerState={ownerState} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        iconComponent
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  ownerState: PropTypes.object,
};
// END REACT STEPPER

// Define an array of allowed file extensions
const allowedExtensions = [
  ".doc",
  ".pdf",
  ".docx",
  ".jpg",
  ".jpeg",
  ".png",
  ".txt",
];
export default function BookingList({ booking }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  const [expanded, setExpanded] = useState(false);
  const [showAttachmentButtons, setShowAttachmentButtons] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isApproveStatusPopupOpen, setIsApproveStatusPopupOpen] =
    useState(false);
  const [isRejectionPopupOpen, setIsRejectionPopupOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [isCompleteStatusPopupOpen, setIsCompleteStatusPopupOpen] =
    useState(false);
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (paymentMessage) {
      paymentStatus();
    }
  }, [paymentMessage]);

  const paymentStatus = () => {
    updateDocument(booking.id, {
      paymentMessage,
      paymentstatus: "paid",
    })
      .then(() => {
        console.log("payment status changed successfully");
        setIsRejectionPopupOpen(false);
      })
      .catch((error) => {
        console.error("Error rejecting booking:", error);
      });
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  // Toggle the visibility of the attachment button div
  const toggleAttachmentButtons = () => {
    setShowAttachmentButtons(!showAttachmentButtons);
  };

  const { document: bookingUser, error: bookingUserError } = useDocument(
    "users",
    booking && booking.createdBy
  );

  const { document: productDetails, error: productError } = useDocument(
    "products",
    booking && booking.productId
  );

  const { updateDocument, deleteDocument } = useFirestore("bookings");

  // APPROVE/REJECT BOOKING OF CUSTOMER
  const handleApproveBooking = () => {
    setIsApproveStatusPopupOpen(true);
  };

  const approveBooking = () => {
    updateDocument(booking.id, {
      status: "approved",
      rejectionReason: "",
    })
      .then(() => {
        console.log("Booking approved successfully");
      })
      .catch((error) => {
        console.error("Error approving booking:", error);
      });
  };

  const handleRejectBooking = () => {
    setIsRejectionPopupOpen(true);
  };

  const handleCompleteBooking = () => {
    setIsCompleteStatusPopupOpen(true);
  };

  useEffect(() => {
    if (rejectionReason) {
      rejectBooking();
    }
  }, [rejectionReason]);

  const rejectBooking = () => {
    updateDocument(booking.id, {
      rejectionReason,
      status: "rejected",
    })
      .then(() => {
        console.log("Booking rejected successfully");
        setIsRejectionPopupOpen(false);
      })
      .catch((error) => {
        console.error("Error rejecting booking:", error);
      });
  };

  const completeBooking = () => {
    updateDocument(booking.id, {
      status: "completed",
    })
      .then(() => {
        console.log("Booking completed successfully");
      })
      .catch((error) => {
        console.error("Error approving booking:", error);
      });
  };
  // END APPROVE/REJECT BOOKING OF CUSTOMER

  // Handle file selection
  const handleFileSelect = (event, bookingId) => {
    const files = event.target.files;
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [bookingId]: files,
    }));
  };

  // Add .doc .pdf .docx .img(all extenstion) in firesbase
  const handleUpload = async (bookingId) => {
    try {
      const selectedFilesForBooking = selectedFiles[bookingId];

      if (selectedFilesForBooking && selectedFilesForBooking.length > 0) {
        const filesArray = Array.from(selectedFilesForBooking);
        let hasInvalidFiles = false;

        const bookingFolderRef = projectStorage.ref(`attachments/${bookingId}`);
        const attachmentURLs = [];

        for (const file of filesArray) {
          const extension = file.name.split(".").pop().toLowerCase();
          if (!allowedExtensions.includes(`.${extension}`)) {
            console.error(`File ${file.name} has an invalid extension.`);
            hasInvalidFiles = true;
            continue;
          }

          const fileRef = bookingFolderRef.child(file.name);
          await fileRef.put(file);
          const fileURL = await fileRef.getDownloadURL();
          attachmentURLs.push(fileURL);
          console.log(`File ${file.name} uploaded successfully.`);
        }

        if (hasInvalidFiles) {
          alert(
            "Please select files with valid extensions (.doc, .pdf, .docx, .jpg, .jpeg, .png, .txt)."
          );
          return;
        }

        // Here, update the attachment URLs in Firestore directly without changing status
        const existingAttachmentURLs = booking.attachmentURL || [];
        const updatedAttachmentURLs = [
          ...existingAttachmentURLs,
          ...attachmentURLs,
        ];

        const updatedBooking = {
          ...booking,
          attachmentURL: updatedAttachmentURLs,
        };

        await updateDocument(bookingId, updatedBooking);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleDownload = (fileURL) => {
    // Create a new anchor element
    const link = document.createElement("a");
    // Set the href attribute to the file URL
    link.href = fileURL;
    // Specify that the file should be downloaded when clicked
    link.setAttribute("download", "");
    // Append the anchor element to the body
    document.body.appendChild(link);
    // Programmatically trigger a click event on the anchor element
    link.click();
    // Remove the anchor element from the DOM
    document.body.removeChild(link);
  };

  // UPDATE PAYMENT STATUS
  const updateUserPaymentStatus = (paymentstatus) => {
    updateDocument(booking.id, {
      paymentstatus: paymentstatus,
    })
      .then(() => {
        console.log("Payment status changed successfully");
      })
      .catch((error) => {
        console.error("Error update payment:", error);
      });
  };

  const getSteps = () => {
    if (booking.status === "upcoming") {
      return ["Booked", "Waiting For Approval", "Completed"];
    }
    if (booking.status === "approved") {
      return ["Booked", "Approved", "Completed"];
    }
    if (booking.status === "rejected") {
      return ["Booked", "Rejected", "Completed"];
    }
    if (booking.status === "completed ") {
      return ["Booked", "Approved", "Completed"];
    }
    return ["Booked", "Approved", "Completed"];
  };

  const isBookingInFuture = (bookingDate) => {
    // Convert seconds and nanoseconds to milliseconds
    const formattedBookingDate = new Date(
      bookingDate.seconds * 1000 + bookingDate.nanoseconds / 1000000
    );

    if (isNaN(formattedBookingDate.getTime())) {
      return false;
    }

    const currentDate = new Date();

    return currentDate < formattedBookingDate;
  };

  return (
    <div className="col-lg-4">
      <div className="bookinglist">
        {/* POPUP ON HOVER i BUTTON */}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {booking && booking.paymentMessage}
          </Typography>
        </Popover>
        {/* POPUP FOR CHANGE PAYMENT STATUS */}
        <Popup
          showPopupFlag={isPaymentPopupOpen}
          setShowPopupFlag={setIsPaymentPopupOpen}
          msg="Are you sure you want to mark as PAID?"
          inputType="text"
          setPopupReturn={(confirmed, value) => {
            if (confirmed) {
              setPaymentMessage(value);
            }
          }}
        />
        {/* POPUP FOR BOOKING ACCEPT */}
        <Popup
          showPopupFlag={isApproveStatusPopupOpen}
          setShowPopupFlag={setIsApproveStatusPopupOpen}
          msg="Are you sure you want to Approve It?"
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              approveBooking();
            }
          }}
        />
        {/* POPUP FOR BOOKING COMPLETE */}
        <Popup
          showPopupFlag={isCompleteStatusPopupOpen}
          setShowPopupFlag={setIsCompleteStatusPopupOpen}
          msg="Are you sure you want to complete this booking? You can also add prescription documents or images if necessary."
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              completeBooking();
            }
          }}
        />
        {/* POPUP FOR BOOKING REJECTION */}
        <Popup
          showPopupFlag={isRejectionPopupOpen}
          setShowPopupFlag={setIsRejectionPopupOpen}
          msg="Enter Reason for Rejection"
          inputType="text"
          setPopupReturn={(confirmed, value) => {
            if (confirmed) {
              setRejectionReason(value);
            }
          }}
        />

        <div className="final_doctor_booking_card ">
          <div className="box-1">
            <img
              src={productDetails && productDetails.images}
              alt={productDetails && productDetails.name}
              className="addbooking_booking_card_dr_img"
            />
          </div>
          <div className="box-2">
            <p
              dangerouslySetInnerHTML={{
                __html: productDetails && productDetails.name.toString("html"),
              }}
            ></p>
          </div>
          <div className="box-3">
            {/* <div>Track Status</div> */}

            {/* <span class="material-symbols-outlined">ink_pen</span> */}
          </div>
          {booking && booking.bookingDate && (
            <div className="box-5">
              <span className="material-symbols-outlined">event_available</span>
              &nbsp;
              {format(booking.bookingDate.toDate(), "dd-MMM-yy")}
              {booking.bookingTimeSlot && (
                <span>
                  &nbsp;|&nbsp;
                  {booking.bookingTimeSlot.startTime}&nbsp;-&nbsp;
                  {booking.bookingTimeSlot.endTime}
                </span>
              )}
            </div>
          )}

          <div className="box-4">
            <hr class="hr-line" />
          </div>
          <div className="box-6 ">
            <span class="material-symbols-outlined">smartphone</span>
            {booking && booking.bookingFor == "self"
              ? bookingUser && bookingUser.phoneNumber
              : booking && booking.nameForOther}
          </div>
          <div className="box-7">
            <span class="material-symbols-outlined">
              {(booking && booking.genderForOther == "Male") ||
              !booking.genderForOther
                ? "man"
                : "woman"}
            </span>
            {booking && booking.bookingFor == "self"
              ? bookingUser && bookingUser.fullName
              : booking && booking.nameForOther}
          </div>
          <div className="box-9 d-flex">
            {/* <div>
              <span class="material-symbols-outlined">
                settings_accessibility
              </span>
              {booking && booking.bookingFor}
            </div> */}
            <div className="box-8">
              AED &nbsp;
              <span style={{ color: "var(--pink)", fontWeight: "700" }}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: booking && booking.productAmount.toString("html"),
                  }}
                ></p>
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                Payment :&nbsp;
                {booking && booking.paymentstatus && (
                  <span style={{ textTransform: "capitalize" }}>
                    {booking.paymentstatus}
                  </span>
                )}
                {booking.paymentstatus == "paid" &&
                  booking.paymentMessage != "" && (
                    <Typography
                      aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      style={{ marginLeft: "5px" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span class="material-symbols-outlined">info</span>
                    </Typography>
                  )}
              </div>
              {user && user.role === "admin" && (
                <>
                  <Popup1
                    trigger={
                      <div className="pointer booking_list_pyment_edit_icon">
                        <span className="material-symbols-outlined">edit</span>
                      </div>
                    }
                    position="left"
                    className="userlist_parent_popup"
                  >
                    <div className="userlist-popup-parent-div">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h5
                          style={{
                            textAlign: "center",
                            fontWeight: "600",
                            color: "var(--pink)",
                            backgroundColor: "#f2f2f2",
                            width: "100%",
                          }}
                        >
                          Change Status
                        </h5>
                        <br />
                        <button
                          className="btn_fill"
                          style={{
                            margin: "0px 5px 5px 5px",
                            backgroundColor: "var(--pink)",
                          }}
                          onClick={() => setIsPaymentPopupOpen(true)}
                        >
                          PAID
                        </button>
                        <button
                          className="btn_fill"
                          style={{ margin: "5px" }}
                          onClick={() => updateUserPaymentStatus("unpaid")}
                        >
                          UNPAID
                        </button>
                      </div>
                    </div>
                  </Popup1>
                </>
              )}
            </div>
          </div>
          <div className="box-10">
            <hr class="hr-line" />
          </div>
          <div className="box-11">
            <p
              style={{
                color: "#858585",
                fontSize: "14px",
                padding: "10px 0px 10px 0px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: expanded ? "normal" : "nowrap",
              }}
            >
              <span style={{ color: "var(--pink)" }}>Purpose : </span>
              {booking && booking.bookingReason}
            </p>
            <div onClick={toggleExpand} style={{ cursor: "pointer" }}>
              <span class="material-symbols-outlined">arrow_drop_down</span>
            </div>
          </div>
          {((booking && booking.status === "approved") ||
            (booking && booking.status === "completed")) && (
            <div className="box-12 mb-2">
              <div>
                {!booking ||
                !booking.attachmentURL ||
                booking.attachmentURL.length === 0 ? null : (
                  <>
                    <div
                      onClick={toggleAttachmentButtons}
                      style={{ cursor: "pointer" }}
                      className="d-flex align-items-center flex-column"
                    >
                      <div className="d-flex">
                        <p>
                          View&nbsp;
                          {booking &&
                            booking.attachmentURL &&
                            booking.attachmentURL.length}
                          &nbsp;Attachments
                        </p>

                        <span class="material-symbols-outlined">
                          arrow_drop_down
                        </span>
                      </div>
                    </div>
                  </>
                )}

                {showAttachmentButtons && (
                  <div
                    style={{
                      color: "#858585",
                      fontSize: "14px",
                      padding: "10px 0px 10px 0px",
                      overflow: "hidden",
                      whiteSpace: expanded ? "normal" : "nowrap",
                    }}
                  >
                    <div>
                      {booking && booking.attachmentURL && (
                        <div className="d-flex">
                          {booking.attachmentURL.map((url, index) => (
                            <div key={index}>
                              <div>
                                <span
                                  class="material-symbols-outlined"
                                  onClick={() => handleDownload(url)}
                                >
                                  description
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {user && user.role === "admin" && (
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center attachemtn-icon-badge-parent">
                    <div className="attachemtn-icon-badge">
                      {selectedFiles[booking.id]
                        ? selectedFiles[booking.id].length
                        : 0}
                    </div>
                    <label
                      htmlFor={`fileInput-${booking.id}`}
                      className="file-input-label"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "40px" }}
                          title="Add doctor's prescription document for customer"
                        >
                          attachment
                        </span>
                      </div>
                      <input
                        id={`fileInput-${booking.id}`}
                        type="file"
                        onChange={(event) =>
                          handleFileSelect(event, booking.id)
                        }
                        multiple
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                  {selectedFiles[booking.id] &&
                    selectedFiles[booking.id].length > 0 && (
                      <button
                        className="btn_fill"
                        onClick={() => handleUpload(booking.id)}
                      >
                        Upload
                      </button>
                    )}
                </div>
              )}
            </div>
          )}

          {booking && user.role === "admin" && (
            <>
              {booking && (
                <div className="box-13 mb-2 gap-2">
                  {booking && booking.status === "upcoming" && (
                    <>
                      <div>
                        <button
                          className="btn_border"
                          onClick={handleRejectBooking}
                          title="Reject Booking"
                        >
                          Reject
                        </button>
                      </div>
                      <div className="">
                        <button
                          className="btn_fill"
                          onClick={handleApproveBooking}
                          title="Approve Booking"
                        >
                          Approve
                        </button>
                      </div>
                    </>
                  )}

                  {booking &&
                    booking.status === "rejected" &&
                    isBookingInFuture(booking.bookingDate) && (
                      <div>
                        <button
                          className="btn_fill"
                          onClick={handleApproveBooking}
                          title="Approve Booking"
                        >
                          Approve
                        </button>
                      </div>
                    )}

                  {booking &&
                    booking.status === "completed" &&
                    isBookingInFuture(booking.bookingDate) && (
                      <div>
                        <button
                          className="btn_border"
                          onClick={handleRejectBooking}
                          title="Reject Booking"
                        >
                          Reject
                        </button>
                      </div>
                    )}

                  {booking && booking.status === "approved" && (
                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="btn_border"
                        onClick={handleRejectBooking}
                        title="Reject Booking"
                      >
                        Reject
                      </button>
                      <div className="">
                        <button
                          className="btn_fill"
                          onClick={handleCompleteBooking}
                          title="Change status into completed"
                        >
                          Complete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {/* {booking && booking.status == "rejected" && (
            <>
              <div className="box-14 d-flex justify-content-start box-13">
                <h6 style={{ fontWeight: "700" }}>
                  Booking Rejected :
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "100",
                    }}
                  >
                    &nbsp;{booking.rejectionReason}
                  </span>
                </h6>
              </div>
            </>
          )} */}

          <div className="box-14">
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={
                  booking.status === "completed"
                    ? 3
                    : booking.status === "rejected"
                    ? 2
                    : booking.status === "approved"
                    ? 1
                    : booking.status === "upcoming"
                    ? 1
                    : 0
                }
                connector={
                  <QontoConnector ownerState={{ status: booking.status }} />
                }
              >
                {getSteps().map((label) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={(props) => (
                        <QontoStepIcon
                          {...props}
                          ownerState={{ status: booking.status }}
                        />
                      )}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </div>
          {/* {booking && booking.status == "approved" && (
            <>
              <div
                className="box-14 d-flex justify-content-start"
                style={{ width: "100%" }}
              >
                <h6
                  style={{
                    fontWeight: "700",
                    color: "var(--click-color  )",
                  }}
                >
                  Booking Approved
                </h6>
              </div>
            </>
          )}
          {booking && booking.status == "upcoming" && (
            <>
              <div className="box-14 d-flex justify-content-start box-13">
                <h6 style={{ fontWeight: "700", color: "var(--pink)" }}>
                  Waiting for approval
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      fontWeight: "100",
                    }}
                  >
                    &nbsp;{booking.rejectionReason}
                  </span>
                </h6>
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
}
