import React from "react";
import { Link } from "react-router-dom";

import "./NotificationIcon.css";

const NotificationIcon = () => {
  return (
    <div className="d_desktop">
      <Link to="/notifications" className="ntf_icon">
        <span class="material-symbols-outlined">notifications</span>
      </Link>
    </div>
  );
};

export default NotificationIcon;