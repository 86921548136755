import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "./AddHolidays.css";
import { useFirestore } from "../hooks/useFirestore";
import { useDocument } from "../hooks/useDocument";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import firebase from "firebase/app";
import "firebase/firestore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

// HOLIDAY CALENDAR MUI
function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs();

const ServerDay = (props) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isHighlighted = highlightedDays.includes(day.date());

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isHighlighted ? "🔔" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
};

const AddHolidays = ({ date, holidayName }) => {
  const { updateDocument, response } = useFirestore("settings");
  const { document: dbslots, error: dbslotsError } = useDocument(
    "settings",
    "slots_holiday"
  );

  // CODE FOR HOLIDAYS TABS
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with current date
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [weekNamesFromFirestore, setWeekNamesFromFirestore] = useState([]);

  const weekNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const weekAbbreviations = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  useEffect(() => {
    if (dbslots && dbslots.day) {
      const activeWeeks = dbslots.day.map(
        (abbr) => weekNames[weekAbbreviations.indexOf(abbr)]
      );
      setSelectedWeeks(activeWeeks);
    }
  }, [dbslots]);

  useEffect(() => {
    if (dbslots && dbslots.day) {
      setWeekNamesFromFirestore(dbslots.day);
    }
  }, [dbslots]);

  const handleWeekToggle = async (week, index) => {
    const weekAbbreviation = weekAbbreviations[index];
    const isSelected = selectedWeeks.includes(week);

    if (isSelected) {
      setSelectedWeeks((prevSelectedWeeks) =>
        prevSelectedWeeks.filter((w) => w !== week)
      );
      await updateDocument("slots_holiday", {
        day: firebase.firestore.FieldValue.arrayRemove(weekAbbreviation),
      });
    } else {
      setSelectedWeeks((prevSelectedWeeks) => [...prevSelectedWeeks, week]);
      await updateDocument("slots_holiday", {
        day: firebase.firestore.FieldValue.arrayUnion(weekAbbreviation),
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  useEffect(() => {
    fetchHighlightedDays(initialValue);
    return () => requestAbortController.current?.abort();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAddDate = async () => {
    if (selectedDate) {
      const newDate = firebase.firestore.Timestamp.fromDate(
        selectedDate.toDate()
      );
      await updateDocument("slots_holiday", {
        date: firebase.firestore.FieldValue.arrayUnion(newDate),
      });
    }
  };

  // Convert Firestore timestamps to readable dates
  const holidays =
    dbslots?.date?.map((timestamp) => ({
      date: timestamp.toDate().toLocaleDateString(),
      holidayName: "Holiday",
      timestamp: timestamp, // Include the original timestamp
    })) || [];

  useEffect(() => {
    if (dbslots && dbslots.date) {
      const highlightedDays = dbslots.date.map((timestamp) =>
        dayjs(timestamp.toDate()).date()
      );
      setHighlightedDays(highlightedDays);
    }
  }, [dbslots]);

  // DELETE DATE ARRAY FROM DOCUMENT
  const handleRemoveDate = async (timestamp) => {
    try {
      await updateDocument("slots_holiday", {
        date: firebase.firestore.FieldValue.arrayRemove(timestamp),
      });
      console.log("Timestamp removed successfully");
    } catch (error) {
      console.error("Error removing timestamp: ", error);
    }
  };

  return (
    <div>
      <div className="properties_map">
        <div className="pi_cities row">
          <div className="row">
            <div className="col-12 col-md-6">
              <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Holiday for specific date" {...a11yProps(0)} />
                    <Tab label="Holiday for Week days" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <div className="tab-panel-one-container">
                      <div className="date-calendar-container">
                        <LocalizationProvider
                          alizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <DateCalendar
                            value={selectedDate}
                            onChange={handleDateChange}
                            loading={isLoading}
                            // onMonthChange={handleMonthChange}
                            renderLoading={() => <DayCalendarSkeleton />}
                            slots={{
                              day: (props) => (
                                <ServerDay
                                  {...props}
                                  highlightedDays={highlightedDays}
                                />
                              ),
                            }}
                            disablePast
                          />
                        </LocalizationProvider>
                      </div>
                      <button
                        className="add-date-button"
                        onClick={handleAddDate}
                      >
                        Add Holiday
                      </button>
                    </div>
                  </TabPanel>

                  {/* start tab panel two */}
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <div className="week-buttons">
                      {weekNames.map((week, index) => (
                        <button
                          key={index}
                          className={`week-button ${
                            selectedWeeks.includes(week) ? "selected" : ""
                          }`}
                          onClick={() => handleWeekToggle(week, index)}
                        >
                          {week}
                        </button>
                      ))}
                    </div>
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </div>
            {/* <div className="col-12 col-md-6">
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    {holidays.map((holiday, index) => (
                      <div
                        className="col-12 date-holiday-cards" // Use col-12 for full-width on smaller screens
                        key={index}
                      >
                        <div className="date-holiday-cards-content">
                          <div className="dashboard-date-holiday">
                            <div>
                              {dayjs(holiday.date).format("DD MMM YYYY")}
                            </div>
                          </div>
                          <div className="holiday-name">
                            {dayjs(holiday.date).format("dddd")}
                          </div>
                        </div>
                        <span
                          className="material-symbols-outlined"
                          onClick={() => handleRemoveDate(holiday.timestamp)}
                        >
                          delete
                        </span>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </SwipeableViews>
            </div> */}

            <div className="col-12 col-md-6">
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div className="offers-section-title">
                    <h2
                      style={{
                        fontSize: "20px",
                        margin: "-50px 0px 10px 0px",
                        color: "var(--pink)",
                      }}
                    >
                      Holidays List
                    </h2>
                  </div>
                  <div className="row">
                    {holidays.map((holiday, index) => (
                      <div
                        className="col-12 col-md-6 mb-3 date-holiday-cards" // Use col-12 for full-width on smaller screens, col-md-6 for half-width on larger screens
                        key={index}
                      >
                        <div className="date-holiday-cards-content">
                          <div className="dashboard-date-holiday">
                            <div>
                              {dayjs(holiday.date).format("DD MMM YYYY")}
                            </div>
                          </div>
                          <div className="holiday-name">
                            {dayjs(holiday.date).format("dddd")}
                          </div>
                        </div>

                        <span
                          className="material-symbols-outlined"
                          onClick={() => handleRemoveDate(holiday.timestamp)}
                        >
                          delete
                        </span>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </SwipeableViews>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHolidays;
