import React from "react";
import { Link } from "react-router-dom";

import "./NotificationIcon.css";

const NotificationIcon = () => {
  return (
    <div className="d_desktop w_parent">
      <a href="https://wa.me/+971545171799" className="ntf_icon w_icon">
        <img
          src="./assets/img/whatsapp-logo-png.png"
          alt="icon"
          style={{ width: "40px" }}
        />
      </a>
    </div>
  );
};

export default NotificationIcon;
