import { useCollection } from "../../../hooks/useCollection";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";

// components
import AddBookingIcon from "../../../components/AddBookingIcon";
import NotificationIcon from "../../../components/NotificationIcon";
import OfferBanners from "../../../components/OfferBanners";
import HomeHeroBanners from "../../../components/HomeHeroBanners";
import CollapsibleGroup from "../../../components/CollapsibleGroup";
import Counter from "../../../components/Counter";
import BeforeAfterSmile from "../../../components/BeforeAfterSmile";
import BeforeAfterBeauty from "../../../components/BeforeAfterBeauty";
import YoutubeVideos from "../../../components/YoutubeVideos";
import { useDocument } from "../../../hooks/useDocument";
// styles
import "./PGHome.css";
import AddMedia from "../../../components/AddMedia";
import Insurances from "../../../components/Insurances";
import UpcomingBookingsCard from "../../../components/UpcomingBookingsCard";

export default function PGHome() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  const { documents: dbBookingsDocuments, error: dbBookingsError } =
    useCollection("bookings", ["status", "in", ["upcoming", "approved"]]);

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );

  // Filter bookings to include only the ones created by the logged-in user
  const userBookings =
    dbBookingsDocuments && user
      ? dbBookingsDocuments.filter((booking) => booking.createdBy === user.uid)
      : [];

  // Find the latest upcoming booking from the user's bookings
  const latestUserBooking =
    dbBookingsDocuments && dbBookingsDocuments.length > 0
      ? dbBookingsDocuments.reduce((latest, booking) => {
          return new Date(booking.date) > new Date(latest.date)
            ? booking
            : latest;
        }, dbBookingsDocuments[0])
      : null;

  // Fetch data from the "doctors team" collection
  const { documents: doctorsteam, error: doctorsteamError } =
    useCollection("doctors_team");

  // fetch recent added images from gallery
  const recentDoctorsImages =
    doctorsteam &&
    doctorsteam.sort((a, b) => b.createdAt - a.createdAt).slice(0, 4);

  // CODE FOR BOOK UPCOMING POPUP BOOKING
  const [popupVisible, setPopupVisible] = useState(false); // State to manage popup visibility

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    // Show popup after 5 seconds
    const timer = setTimeout(() => {
      setPopupVisible(true);
    }, 5000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="pghome">
      {/* START UPCOMING BOOKINGS POPUP HERE */}
      {appointmentPermission && appointmentPermission.permission == true && (
        <>
          <div>
            <video
              src="/assets/img/Doctors appointment booking.mp4"
              autoPlay
              loop
              muted
              style={{ display: "none" }}
            />
            {(
              user && user.role === "admin"
                ? dbBookingsDocuments && dbBookingsDocuments.length > 0
                : userBookings && userBookings.length > 0
            ) ? (
              <div>
                {popupVisible && (
                  <div className="book_upcoming_booking_popup">
                    <div className="book_upcoming_booking_popup-content">
                      <div
                        style={{
                          backgroundColor: "var(--grey-light)",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <div className="col-1">
                          <img
                            src="/assets/img/output-onlinegiftools.gif"
                            alt="bell icon gif"
                            style={{ width: "40px" }}
                          ></img>
                        </div>
                        <div className="col-11">
                          <h5>
                            You Have&nbsp;
                            <span
                              style={{
                                fontSize: "25px",
                                color: "var(--pink)",
                              }}
                            >
                              {user && user.role === "admin"
                                ? dbBookingsDocuments &&
                                  dbBookingsDocuments.length
                                : userBookings && userBookings.length}
                            </span>
                            &nbsp;Upcoming Bookings
                          </h5>
                        </div>
                      </div>
                      <video
                        src="/assets/img/Doctors appointment booking.mp4"
                        autoPlay
                        loop
                        muted
                        style={{ width: "90%" }}
                      />
                      <div className="upcoming-booking-popup-buttons">
                        <button onClick={handleClosePopup}>Cancel</button>
                        <Link
                          to={
                            dbBookingsDocuments.length > 0
                              ? "/allcustomerbookings"
                              : "products"
                          }
                        >
                          <button
                            onClick={() => {
                              handleClosePopup();
                            }}
                            style={{
                              backgroundColor: "var(--click-color)",
                            }}
                          >
                            {dbBookingsDocuments.length > 0
                              ? "My Bookings"
                              : "Book Now"}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {popupVisible && (
                  <div className="book_upcoming_booking_popup">
                    <div className="book_upcoming_booking_popup-content">
                      <div
                        style={{
                          backgroundColor: "var(--grey-light)",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <h5>
                          Book your appointment now and enjoy the convenience!
                        </h5>
                      </div>

                      <video
                        src="/assets/img/Doctors appointment booking.mp4"
                        autoPlay
                        loop
                        muted
                        style={{ width: "90%" }}
                      />
                      <div className="upcoming-booking-popup-buttons">
                        <button onClick={handleClosePopup}>Cancel</button>
                        <Link to={user ? "/products" : "/login"}>
                          <button
                            onClick={() => {
                              handleClosePopup();
                            }}
                            style={{
                              backgroundColor: "var(--click-color)",
                            }}
                          >
                            Book Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

      {/* END UPCOMING BOOKINGS POPUP HERE */}
      {/* Add booking Icon */}
      {user && user.role === "admin" && <AddBookingIcon />}

      <HomeHeroBanners
        category="banner"
        pageName="home"
        section="homeherobanners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
      <Counter />

      {/* USER UPCOMING BOOKINGS CODE START */}
      {appointmentPermission && appointmentPermission.permission == true && (
        <UpcomingBookingsCard />
      )}

      {/* USER UPCOMING BOOKINGS CODE END */}

      <OfferBanners category="offer_banners" />
      <div className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-item">
                <div className="about-left">
                  <img src="./assets/img/aboutleftimg.webp" alt="About img" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-item about-right">
                <img src="./assets/img/aboutrightimg.png" alt="About image" />
                <div className="section-title">
                  <p>Our Journey</p>
                  <h2>Unraveling Our Clinic's Journey</h2>
                </div>
                <h6 className="subheading">
                  <b
                    style={{
                      fontSize: "20px",
                      color: "var(--black-color)",
                    }}
                  >
                    Specialized Healthcare Excellence Since 2002
                  </b>
                </h6>
                <p style={{ color: "var(--black-color)" }}>
                  At Dentamax, we take pride in our legacy of providing
                  exceptional healthcare services since 2002. With decades of
                  serving you with quality healthcare, our commitment to
                  specialized care has made us a trusted name in the industry.
                  We prioritize your well-being and strive to deliver
                  outstanding treatment and results with our range of
                  specialized services to you. At Dentamax, we are driven by our
                  commitment to excellence in specialized healthcare. Our team
                  of thorough professionals utilize the latest medical
                  technologies and practices to ensure you receive top-notch
                  treatment and achieve the best possible medication and
                  results. <br />
                  <br />
                  <b>Your Wellness is Our Priority</b> <br /> Your health and
                  satisfaction are at the forefront of everything we do. At
                  Dentamax, we emphasize a patient-centric approach, ensuring
                  that you receive personalized care and attention throughout
                  your healthcare journey.
                </p>

                <ul
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      // marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      alt="Check Circle Icon"
                    >
                      check_circle
                    </span>
                    <Link
                      to="/services"
                      style={{
                        color: "var(--black-color)",
                        marginLeft: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <h1 className="our_journey_services_heading">Dental</h1>
                    </Link>
                  </div>
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      alt="Check Circle Icon"
                    >
                      check_circle
                    </span>
                    <Link
                      to="/services"
                      style={{
                        color: "var(--black-color)",
                        marginLeft: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <h1 className="our_journey_services_heading">
                        Dermatology
                      </h1>
                    </Link>
                  </div>

                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      alt="Check Circle Icon"
                    >
                      check_circle
                    </span>
                    <Link
                      to="/services"
                      style={{
                        color: "var(--black-color)",
                        marginLeft: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <h1 className="our_journey_services_heading">
                        Homeopathy
                      </h1>
                    </Link>
                  </div>

                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      alt="Check Circle Icon"
                    >
                      check_circle
                    </span>
                    <Link
                      to="/services"
                      style={{
                        color: "var(--black-color)",
                        marginLeft: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <h1 className="our_journey_services_heading">Hijama</h1>
                    </Link>
                  </div>
                </ul>
                <Link to="/about">
                  <button className="btn_fill">About Us</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BeforeAfterSmile />
      <BeforeAfterBeauty />
      <YoutubeVideos category="dental" />
      <AddMedia
        category="dental"
        pageName="home"
        section="dental"
        addMoreImagesButton={false}
      />
      <section className="dental_services" id="services-section">
        <div className="container">
          <div className="section-title">
            <p>Our Expertise</p>
            <h2>Dental Treatments</h2>
          </div>
          <div className="ds_inner">
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <Link to={`/services-details/0pcfmq8sS0MwMhJVYkq8`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/dental_filling.png"
                      alt="dental filling img"
                    />
                  </div>
                  <h1 className="service_name">
                    Dental <br />
                    filling
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/straightens-teeth.png"
                      alt="steaghtens img"
                    />
                  </div>
                  <h1 className="service_name">bridges </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/root_canal_treatment.png"
                      alt="root canel img"
                    />
                  </div>
                  <h1 className="service_name">
                    endodontics
                    <br />
                    treatment
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img src="./assets/img/denture.png" alt="dentare img" />
                  </div>
                  <h1 className="service_name">primary tooth extracation </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/dental-implant.png"
                      alt="dental implant img"
                    />
                  </div>
                  <h1 className="service_name">
                    fiber <br />
                    post
                  </h1>
                </Link>
              </div>
            </div>

            <div className="ds_single dsb_b dsmb_b">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/teethpair.png"
                      alt=" teethpair img"
                    />
                  </div>
                  <h1 className="service_name">
                    crown <br /> re-cementation
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_b dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img src="./assets/img/children.png" alt="children img" />
                  </div>
                  <h1 className="service_name">
                    rct <br /> anterior
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_b dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/kidsteeth.png"
                      alt="kids theeth img"
                    />
                  </div>
                  <h1 className="service_name">
                    post <br /> & care
                  </h1>
                </Link>
              </div>
            </div>

            <div className="ds_single dsb_r dsmb_b">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/tooth-whitening.png"
                      alt="tooth whitening img"
                    />
                  </div>
                  <h1 className="service_name">
                    teeth <br /> cleaning
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img
                      src="./assets/img/smilemakeover.png"
                      alt="smile makeover img"
                    />
                  </div>
                  <h1 className="service_name">
                    zoom <br /> whitening
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_r">
              <div className="dss_inner">
                <Link to={`/services`}>
                  <div className="service_icon">
                    <img src="./assets/img/mouth.png" alt="mouth img" />
                  </div>
                  <h1 className="service_name">
                    night <br /> guard
                  </h1>
                </Link>
              </div>
            </div>
            <div className="ds_single">
              <div className="dss_inner">
                <Link to="/services">
                  <div className="service_icon">
                    <img
                      src="./assets/img/viewmoreeye.png"
                      alt="view more img"
                    />
                  </div>

                  <button className="btn_fill">View More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="him_her_area" id="skin_services">
        <div className="container">
          <div className="section-title">
            <p>Skin Treatments</p>
            <h2>Advance care for any skin condition</h2>
          </div>
          <div className="himherbutton">
            {/* <div className={`${activeHimHer === "her" ? "her" : "him"}`}> */}
            {/* <button onClick={showHim} className="btn_fill him">
                for him
              </button>
              <button onClick={showHer} className="btn_fill her">
                for her
              </button> */}

            <div className="himherimg desktop">
              <Link to="/services">
                <img
                  className="himimg"
                  src="./assets/img/him-her-img.svg"
                  alt="her him img"
                />
                {/* <img
                  className="herimg"
                  src="./assets/img/him-her-img.svg"
                  alt=""
                /> */}
              </Link>
            </div>
            <div className="himherimg mobile">
              <Link to="/services">
                <img
                  className="herimg"
                  src="./assets/img/him-her-img.svg"
                  alt="him her img"
                />
                {/* <img
                    className="himimg"
                    src="./assets/img/him-img-mobile.png"
                    alt=""
                  /> */}
              </Link>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <YoutubeVideos category="dermatology" />
      <AddMedia
        category="dermatology"
        pageName="home"
        section="dermatology"
        addMoreImagesButton={false}
      />
      <section className="expertise-area">
        <div className="container">
          <div className="section-title">
            <p>Our Solutions</p>
            <h2>Guiding With Expertise Towards Excellence</h2>
          </div>
          <div className="row align-items-center tab_reverse">
            <div className="col-lg-6">
              <div className="expertise-item left">
                <div className="row">
                  <div className="col-sm-6 col-lg-6">
                    <Link to="/services">
                      <div className="expertise-inner">
                        <div className="ei_circleimg">
                          <img
                            src="./assets/img/expert_one.png"
                            alt="expert one img"
                          />
                        </div>
                        <h1>Dental</h1>
                        <p>Achieve a Radiant Smile with our Dental Expertise</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <Link to="/services">
                      <div className="expertise-inner">
                        <div className="ei_circleimg">
                          <img
                            src="./assets/img/expert_two.png"
                            alt="expert two img"
                          />
                        </div>
                        <h1>Dermatology</h1>
                        <p>
                          Your Skin Deserves the Best - Transform it with Us
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <Link to="/services">
                      <div className="expertise-inner">
                        <div className="ei_circleimg">
                          <img
                            src="./assets/img/expert_four.png"
                            alt="exper four img"
                          />
                        </div>
                        <h1>Homeopathy</h1>
                        <p>
                          Enhancing Wellness through Personalized Homeopathic
                          Care
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <Link to="/services">
                      <div className="expertise-inner">
                        <div className="ei_circleimg">
                          <img
                            src="./assets/img/expert_three.png"
                            alt="expert three img"
                          />
                        </div>
                        <h1>Hijama</h1>
                        <p>
                          Ancient Therapy, Modern Healing, Your Path to Wellness
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="expertise-item">
                <div className="expertise-right">
                  <img
                    src="./assets/img/ourexpertice_rightimg.webp"
                    alt="Expertise img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <YoutubeVideos category="hijama" />
      <AddMedia category="hijama" addMoreImagesButton={false}
/> */}
      <section className="doctors-area">
        <div className="container">
          <div className="section-title">
            <p>Meet Our Doctors</p>
            <h2>Expert Care & Personalized Health Solutions</h2>
          </div>

          <div className="row pghome-doctor-row">
            {recentDoctorsImages &&
              recentDoctorsImages.map((doctor, index) => {
                // Check if the user is an admin or if the doctor's status is active
                const isAdminOrActive =
                  user?.role == "admin" || doctor.status == "active";

                // Render the doctor only if the user is admin or the doctor is active
                if (isAdminOrActive) {
                  return (
                    <div
                      key={index}
                      className="col-sm-6 col-lg-3 pghome-doctor-column"
                    >
                      <Link to={`/doctor-details/${doctor.id}`}>
                        <div className="doctor-item">
                          <div className="pghome-doctor-top">
                            <div className="doctor-top">
                              <div
                                className="d-flex align-items-center toglle_btn"
                                style={{ position: "absolute", margin: "10px" }}
                              >
                                {user?.role == "admin" && (
                                  <button
                                    className={`booking_services_book_now_btn ${doctor.status}`}
                                    style={{
                                      backgroundColor:
                                        doctor.status === "active"
                                          ? "var(--click-color)"
                                          : "var(--pink)",
                                    }}
                                  >
                                    {doctor.status}
                                  </button>
                                )}
                              </div>
                              <img
                                src={doctor.product_img_1}
                                alt="Doctor img 3"
                              />
                              {/* <Link to="/contactus">Get Appointment</Link> */}
                            </div>
                            <div className="doctor-bottom">
                              <h3>
                                <a
                                  href="/doctor-details"
                                  style={{ color: "var(--black-color)" }}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: doctor.name.toString("html"),
                                    }}
                                  ></p>
                                </a>
                              </h3>
                              <span>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      doctor.specialization.toString("html"),
                                  }}
                                ></p>
                              </span>
                              <span>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      doctor.short_description.toString("html"),
                                  }}
                                ></p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }
                return null;
              })}
          </div>
          <Link to="/our-team">
            <div className="doctor-btn">
              <button className="btn_fill">Meet All</button>
            </div>
          </Link>
        </div>
      </section>
      <YoutubeVideos category="other" />
      <AddMedia
        category="other"
        pageName="home"
        section="other"
        addMoreImagesButton={false}
      />
      {/* Collapsible Section  */}
      <CollapsibleGroup />
      {/* insurance section */}
      <Insurances />
    </div>
  );
}
