import React from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { Link } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import format from "date-fns/format";

import "./Notification.css";

const Notification = () => {
  const { user } = useAuthContext();

  const isAdmin = user && user.role == "admin";

  const { documents: dbupcomingbookings, error: dbupcomingbookingserror } =
    useCollection("bookings");

  // SHOW UPCOMING BOOKINGS BASED ON USER ROLE
  const userbasedbookings = isAdmin
    ? dbupcomingbookings
    : user &&
      dbupcomingbookings &&
      dbupcomingbookings.filter(
        (loggedinuserbookings) => loggedinuserbookings.createdBy === user.uid
      );

  return (
    <div className="container">
      <div className="row notification_div_parent">
        {/* <AddNotification /> */}
        <div className="pg_header">
          <h2 className="p_title">Alert Center!</h2>
          <h4 className="p_subtitle">Your Notifications, Stay Updated</h4>
        </div>

        {userbasedbookings &&
          userbasedbookings.map((notification) => (
            <div className="col-12 col-md-6 col-lg-4" key={notification.id}>
              <br />
              <Link to={"/allcustomerbookings"}>
                <div className="propagentusersingle">
                  <div className="left">
                    <div>
                      <div className="img_div">
                        <img
                          src="./assets/img/speker-logo.webp"
                          alt="speaker icon"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: "22px" }}>
                      <h5 className="name">{notification.productName}</h5>
                      <h6 className="phone_number">
                        {format(
                          notification.bookingDate.toDate(),
                          "dd MMM yyyy"
                        )}
                      </h6>
                    </div>
                  </div>
                  <h4 className="notification_date">
                    <span>
                      {formatDistanceToNow(notification.createdAt.toDate(), {
                        addSuffix: true,
                      })}
                    </span>
                  </h4>
                  <div
                    style={{
                      marginTop: "10px",
                      backgroundColor: "var(--grey-light)",
                      borderRadius: "10px",
                      color: "black",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <p style={{ fontSize: "14px" }}> Upcoming Booking</p>{" "}
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Notification;
