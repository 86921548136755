import React from "react";
import "./Counter.css";
import CountUp from "react-countup";
import { useAuthContext } from "../hooks/useAuthContext";

const Counter = () => {
  const { user } = useAuthContext();

  const startBedNumber = 0;
  const targetBedNumber = 22;
  const startPatientNumber = 0;
  const targetPatientNumber = 22;
  const startDoctorNumber = 0;
  const targetDoctorNumber = 22;
  const startYearNumber = 0;
  const targetYearNumber = 22;

  return (
    <div className="counter">
      <div className="container mobilecontainer">
        {" "}
        <div
          className={`row ${
            user && user.role === "admin" ? "counter-bg-admin" : "counter-bg"
          }`}
          style={{
            backgroundImage: "url('./assets/img/counterbg.png')",
            backgroundColor: "var(--card-bg)",
            color: "var(--black-color)",
          }}
        >
          <div className="col-sm-3 mobwidth">
            <div className="counter-item ptop">
              <img src="./assets/img/award.png" alt="award img"></img>
              <h3 className="counter">
                <span>
                  <CountUp
                    start={startYearNumber}
                    end={targetYearNumber}
                    duration={3.5}
                    separator=","
                  />
                </span>
                +
              </h3>
              <p>Years Of Experience</p>
            </div>
          </div>
          <div className="col-sm-3 mobwidth mbod_right">
            <div className="counter-item mbod_bottom">
              <img src="./assets/img/crowd-of-users.png" alt="crowd img"></img>

              <h3>
                <span>
                  <CountUp
                    start={startPatientNumber}
                    end={targetPatientNumber}
                    duration={3.5}
                    separator=","
                  />
                  K
                </span>
                +
              </h3>
              <p>Happy Customers</p>
            </div>
          </div>
          <div className="col-sm-3 mobwidth">
            <div className="counter-item mbod_bottom">
              <img src="./assets/img/counter_1.png" alt="counter img"></img>
              <h3 className="counter">
                <span>
                  <CountUp
                    start={startBedNumber}
                    end={targetBedNumber}
                    duration={3.5}
                    separator=","
                  />
                </span>
                +
              </h3>

              <p>Customer Services</p>
            </div>
          </div>

          <div className="col-sm-3 mobwidth mbod_right">
            <div className="counter-item ptop">
              <img src="./assets/img/counter_3.png" alt="counter logo img"></img>

              <h3 className="counter">
                <span>
                  <CountUp
                    start={startDoctorNumber}
                    end={targetDoctorNumber}
                    duration={3.5}
                    separator=","
                  />
                </span>
                +
              </h3>
              <p>Helpful Staff</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
