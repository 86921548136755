import React, { useState, useEffect, useMemo } from "react";
import { useCollection } from "../../../hooks/useCollection";
import UserList from "../../../components/UserList";
import { useLocation } from "react-router-dom";

const PGAdminUser = () => {
  const location = useLocation();

  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { documents: allUsers, error } = useCollection("users", null, [
    "createdAt",
    "desc",
  ]);
  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const filteredUsers = useMemo(() => {
    return allUsers?.filter(
      (user) =>
        user.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.phoneNumber.includes(searchValue)
    );
  }, [allUsers, searchValue]);

  const filteredUsersByTime = useMemo(() => {
    return filteredUsers?.filter((user) => {
      const createdAt = user.createdAt.toDate();

      if (filterOption === "last3months") {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return createdAt >= threeMonthsAgo;
      }

      if (filterOption === "last6months") {
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        return createdAt >= sixMonthsAgo;
      }

      if (filterOption === currentYear.toString()) {
        return (
          createdAt >= new Date(currentYear, 0, 1) &&
          createdAt < new Date(currentYear + 1, 0, 1)
        );
      }

      if (filterOption === previousYear.toString()) {
        return (
          createdAt >= new Date(previousYear, 0, 1) &&
          createdAt < new Date(currentYear, 0, 1)
        );
      }

      return true; // default to no filter
    });
  }, [filteredUsers, filterOption, currentYear, previousYear]);

  const filteredUsersByType = useMemo(() => {
    return filteredUsersByTime?.filter((user) => {
      switch (activeFilter) {
        case "inactive":
          return user.status.toLowerCase() === "inactive";
        case "customer":
          return (
            user.role.toLowerCase() === "customer" &&
            user.status.toLowerCase() != "inactive"
          );
        case "admin":
          return user.role.toLowerCase() === "admin";
        case "frontdesk":
          return user.role.toLowerCase() === "frontdesk";
        case "all":
        default:
          return true;
      }
    });
  }, [filteredUsersByTime, activeFilter]);

  const handleFilterClick = (filter) => {
    setSearchValue("");
    setActiveFilter((prev) => (prev === filter ? "all" : filter));
    // Clear filterOption when clicking on role filters
    setFilterOption("");
  };

  const getUsersCountByType = (type) => {
    if (!filteredUsersByTime) return 0;
    switch (type) {
      case "inactive":
        return filteredUsersByTime.filter(
          (user) => user.status.toLowerCase() === "inactive"
        ).length;
      case "customer":
        return filteredUsersByTime.filter(
          (user) =>
            user.role.toLowerCase() === "customer" &&
            user.status.toLowerCase() != "inactive"
        ).length;
      case "admin":
        return filteredUsersByTime.filter(
          (user) => user.role.toLowerCase() === "admin"
        ).length;
      case "frontdesk":
        return filteredUsersByTime.filter(
          (user) => user.role.toLowerCase() === "frontdesk"
        ).length;
      default:
        return 0;
    }
  };

  return (
    <div>
      <div className="user_list_filter_search">
        <div className="search_bar">
          <input
            type="text"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="user_list_filter_dropdown yearly">
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            className="pc_search_y_year"
          >
            <option value="">Select Filter</option>
            <option value="last3months">Last 3 Months</option>
            <option value="last6months">Last 6 Months</option>
            <option value={currentYear.toString()}>{currentYear}</option>
            <option value={previousYear.toString()}>{previousYear}</option>
          </select>
        </div>
      </div>

      <div className="user_numbers-stats">
        <p
          className="user_numbers-customers d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("customer")}
        >
          Customers: {getUsersCountByType("customer")} &nbsp;
          {getUsersCountByType("customer") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "customer" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-admin d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("admin")}
        >
          Admin: {getUsersCountByType("admin")} &nbsp;
          {getUsersCountByType("admin") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "admin" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-frontdesk d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("frontdesk")}
        >
          Frontdesk: {getUsersCountByType("frontdesk")}
          &nbsp;
          {getUsersCountByType("frontdesk") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "frontdesk"
                ? "arrow_downward"
                : "arrow_upward "}
            </span>
          )}
        </p>
        <p
          className="user_numbers-inactive d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleFilterClick("inactive")}
        >
          Inactive: {getUsersCountByType("inactive")}
          &nbsp;
          {getUsersCountByType("inactive") > 0 && (
            <span className="material-symbols-outlined">
              {activeFilter === "inactive" ? "arrow_downward" : "arrow_upward "}
            </span>
          )}
        </p>
      </div>

      {error && <p className="error">{error}</p>}
      <div className="userlist">
        <div className="container-fluid">
          <div className="user_card">
            {filteredUsersByType &&
              filteredUsersByType.map((user) => (
                <UserList key={user.uid} user={user} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PGAdminUser;
