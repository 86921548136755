import React from "react";
import "./Insurances.css";

const Insurances = () => {
  return (
    <div className="insurance-container">
      <div className="row">
        <div className="d-flex justify-content-center align-items-center col-4 col-md-2 insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fneuron.png?alt=media&token=cd894cf2-96db-4d33-a0dd-5b308b8c32e5"
            alt="insurance img 1"
            className="insurance-image"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center col-4 col-md-2 insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Falmadallah.png?alt=media&token=66d28e8a-6316-4179-a6fb-0e3ace84e40e"
            alt="insurance img 2"
            className="insurance-image"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center col-4 col-md-2 insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fnextcare.png?alt=media&token=caeb935d-bdbd-4024-bb6b-dc413c5311f0"
            alt="insurance img 3"
            className="insurance-image"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center col-4 col-md-2 mt-4 insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Flifline.png?alt=media&token=7be4b079-d882-462a-a691-7f99497ca02b"
            alt="insurance img 3"
            className="insurance-image"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center col-4 col-md-2 mt-4 insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fadnic.png?alt=media&token=54b6ad30-dcdd-42dd-9394-36bbfd2ed380"
            alt="insurance img 4"
            className="insurance-image"
          />
        </div>  
        <div className="d-flex justify-content-center align-items-center col-4 col-md-2 mt-4 insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fglobemed.png?alt=media&token=4fd5e3f0-64b9-45e6-b991-74622810a3c5"
            alt="insurance img 3"
            className="insurance-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Insurances;
