import { React, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "./NavbarBottom.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDocument } from "../hooks/useDocument";

export default function NavbarBottom() {
  const location = useLocation(); // Get the current location
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [showFlag, setShowFlag] = useState(false);

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );

  useEffect(() => {
    // console.log('location.pathname:', location.pathname)
    const fullPath = window.location.pathname;
    const pathSegments = fullPath.split("/");
    // console.log('pathSegments:', pathSegments)
    const firstPathSegment = pathSegments[1];
    // console.log('firstPathSegment:', firstPathSegment)

    // if (location.pathname === firstPathSegment) {
    if (
      firstPathSegment === "product-details"
      // || firstPathSegment === "ticketdetail"
    ) {
      // console.log('inside of firstPathSegment')
      setShowFlag(false);
    } else {
      setShowFlag(true);
    }
  }, [location.pathname]);
  const showProfile = () => {
    navigate("/profile");
  };

  const showHome = () => {
    navigate("/");
  };

  const showSecondPage = () => {
    if (user && user.role === "admin") {
      navigate("/adminproperties");
    }
    if (user && user.role === "customer") {
      navigate("/services");
    }
    if (user && user.role === "doctor") {
      navigate("/calendarbooking");
    } else {
      navigate("/services");
    }
  };

  const showThirdPage = () => {
    if (user && user.role === "admin") {
      navigate("/products");
    }
    if (user && user.role === "customer") {
      {
        appointmentPermission && appointmentPermission.permission == true
          ? navigate("/products")
          : navigate("/our-team");
      }
    } else {
      {
        appointmentPermission && appointmentPermission.permission == true
          ? navigate("/products")
          : navigate("/our-team");
      }
    }
  };

  const showFourthPage = () => {
    navigate("/more");
  };

  const showFifthPage = () => {
    navigate("/customerdashboard");
  };

  //Menus as per roles
  let firstMenuIcon = "";
  let firstMenu = ""; //This is for all user type
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";
  let fifthMenu = "";
  let fifthMenuIcon = "";

  firstMenuIcon = "home";
  firstMenu = "Home";
  fourthMenuIcon = "deployed_code";
  fourthMenu = "More";
  fifthMenuIcon = "dashboard";
  fifthMenu = "Dashboard";

  if (user && user.role === "customer") {
    secondMenuIcon = "checklist";
    secondMenu = "Services";
    {
      appointmentPermission && appointmentPermission.permission == true
        ? (thirdMenuIcon = "handshake")
        : (thirdMenuIcon = "groups");
    }
    {
      appointmentPermission && appointmentPermission.permission == true
        ? (thirdMenu = "Appoinment")
        : (thirdMenu = "Team");
    }
  }

  if (user && user.role === "admin") {
    secondMenuIcon = "analytics";
    secondMenu = "Properties";
    thirdMenuIcon = "confirmation_number";
    thirdMenu = "Users";
  }

  if (user && user.role === "frontdesk") {
    secondMenu = "Bills";
    thirdMenu = "Tickets";
  }

  if (user && user.role === "doctor") {
    secondMenuIcon = "free_cancellation";
    secondMenu = "Calendar";
    thirdMenuIcon = "edit_note";
    thirdMenu = "Setup";
  } else {
    secondMenuIcon = "checklist";
    secondMenu = "Services";
    {
      appointmentPermission && appointmentPermission.permission == true
        ? (thirdMenuIcon = "handshake")
        : (thirdMenuIcon = "groups");
    }
    {
      appointmentPermission && appointmentPermission.permission == true
        ? (thirdMenu = "Appoinment")
        : (thirdMenu = "Team");
    }
  }

  return (
    <>
      {showFlag && (
        <section className="bottom_menu_bar">
          <div className="small navbar-mobile-bottom">
            <div className="navbar-mobile-bottom-menu" id="divBottomNavBar">
              <div
                className={`navbar-mobile-bottom-menu-a bm_single ${
                  location.pathname === "/" ? "active_bottom_menu" : ""
                } `}
                style={{ display: "flex", flexDirection: "column" }}
                onClick={showHome}
              >
                <span className="material-symbols-outlined">
                  {firstMenuIcon}
                </span>
                <small>{firstMenu}</small>
              </div>

              {/* SERVICES */}
              {user &&
              appointmentPermission &&
              appointmentPermission.permission == true ? (
                <div
                  className={`navbar-mobile-bottom-menu-a bm_single ${
                    location.pathname === "/customerdashboard"
                      ? "active_bottom_menu"
                      : ""
                  } `}
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={showFifthPage}
                >
                  <span className="material-symbols-outlined">
                    {fifthMenuIcon}
                  </span>
                  <small>{fifthMenu}</small>
                </div>
              ) : (
                <>
                  <div
                    className={`navbar-mobile-bottom-menu-a bm_single ${
                      location.pathname === "/services"
                        ? "active_bottom_menu"
                        : ""
                    } `}
                    style={{ display: "flex", flexDirection: "column" }}
                    onClick={showSecondPage}
                  >
                    <span className="material-symbols-outlined">
                      {secondMenuIcon}
                    </span>
                    <small>{secondMenu}</small>
                  </div>
                </>
              )}

              {/* PROFILE */}
              {appointmentPermission &&
                appointmentPermission.permission == true && (
                  <>
                    {user ? (
                      <div className="bmp_single">
                        <Link to="/profile" className="profile_icon bm_single">
                          <span className="material-symbols-outlined">
                            person
                          </span>
                        </Link>
                      </div>
                    ) : (
                      <div className="bmp_single">
                        <Link to="/login" className="profile_icon bm_single">
                          <span className="material-symbols-outlined">
                            person
                          </span>
                        </Link>
                      </div>
                    )}
                  </>
                )}

              {/* PRODUCTS */}
              <div
                className={`navbar-mobile-bottom-menu-a bm_single ${
                  location.pathname === "/products" ||
                  location.pathname === "/our-team"
                    ? "active_bottom_menu"
                    : ""
                } `}
                style={{ display: "flex", flexDirection: "column" }}
                onClick={showThirdPage}
              >
                <span className="material-symbols-outlined">
                  {thirdMenuIcon}
                </span>
                <small>{thirdMenu}</small>
              </div>

              {/* MORE */}
              <div
                className={`navbar-mobile-bottom-menu-a bm_single ${
                  location.pathname === "/more" ? "active_bottom_menu" : ""
                } `}
                style={{ display: "flex", flexDirection: "column" }}
                onClick={showFourthPage}
              >
                <span className="material-symbols-outlined">
                  {fourthMenuIcon}
                </span>
                <small>{fourthMenu}</small>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
