import React from "react";
import { Link } from "react-router-dom";

import "./AddBookingIcon.css";

const AddBookingIcon = () => {
  return (
    <div>
      <Link to="/products" className="ab_icon call_icon">
        <span class="material-symbols-outlined">add</span>
      </Link>
    </div>
  );
};

export default AddBookingIcon;
