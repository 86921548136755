import React from "react";
import { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useLocation } from "react-router-dom";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import { useDocument } from "../hooks/useDocument";
// import css
import "./InternationalPatients.css";

import Testimonials from "./Testimonials";
import UpcomingBookingsCard from "./UpcomingBookingsCard";

const images = [
  "https://www.greatamericaninsurancegroup.com/images/default-source/career-seekers/nonprofits-and-social-services-unscreen.gif?sfvrsn=df9955b1_1",
  "https://media4.giphy.com/media/bmoKSwzYNVLLnsZARg/giphy.gif?cid=6c09b95297vlpwuexpnw3sy36ojwzgp2hk2ss8924bcg5gwi&ep=v1_stickers_related&rid=giphy.gif&ct=s",
  "https://static.wixstatic.com/media/6e6166_cec12188412a4e72a90d31e2b2be6932~mv2.gif",
  "https://www.qomplx.com/assets/images/ms-icon.gif?v=145cff52da",
];
const expertiseSpecializationTopics = [
  "Medical Expertise",
  "State-of-the-Art Facilities",
  "Convenient Location",
  "Tourism insurance",
];
const expertiseSpecializationcontent = [
  "The center's team consists of highly trained and specialized professionals, ensuring that patients receive the best care for their specific needs.",
  "Equipped with modern, state-of-the-art medical equipment and facilities, allowing for advanced and precise diagnostics and treatment.",
  "The location in Dubai offers convenience for residents and visitors alike. Easy accessibility from Dubai & Sharjah Airport.",
  "Accept various insurance plans, making healthcare more affordable and accessible to a broader range of patients.",
];

const options = {
  items: 3,
  loop: true,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1, // Show only 1 item on small screens (phone view)
    },
    768: {
      items: 3, // Show 3 items on larger screens
    },
  },
};

const InternationalPatients = () => {
  const location = useLocation();

  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );

  return (
    <div>
      {/* <AddBookingIcon />
      <NotificationIcon /> */}

      {/* add banner */}
      <div className="pg_contact_us">
        <div className="banner relative">
          <img
            src="./assets/img/ip_banner.jpg"
            alt="international patients img"
          ></img>

          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">
                  Global patients receive personalized attention.
                </h3>
                <h2 className="banner_heading">
                  Tailored care for our valued international patients.
                </h2>
                {/* <Link to="contactus"> */}
                {/* <button className="btn_fill"> Us</button> */}
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* International Services */}
      <div className="international_cards_design_parent">
        <div className="container">
          <div
            style={{
              backgroundColor: "var(--card-bg)",
              borderRadius: "30px",
              // marginTop: "400px",
            }}
          >
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div
                  className="row"
                  // style={{ marginLeft: "calc(.5 * var(--bs-gutter-x))" }}
                >
                  <div className="interntional_left_top_div d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <h1>The Perfect Treatment Is Right Here</h1>
                      <p>
                        We extend a warm welcome to international patients,
                        providing comprehensive care, multilingual support, and
                        a comforting environment that feels like your home
                        country.
                      </p>
                      <Link to="/our-team">
                        <button className="btn_fill">Find Best</button>
                      </Link>
                      <div style={{ position: "absolute", bottom: "-120px" }}>
                        <p style={{ textAlign: "center" }}>
                          Recommended by <b>1500+</b> people
                        </p>
                        <div className="international-left-top-bottom">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2FWhatsApp_Image_2024-01-18_at_20.52.51_071810df-removebg-preview%20(1).png?alt=media&token=22d95747-91ac-48b2-bf89-37ebebf03c0f"
                            alt="human face icons 1"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginLeft: "-5px",
                              objectFit: "cover",
                            }}
                          />
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2Fteam10.webp?alt=media&token=bc925e53-66b9-4c4e-a8cf-71d5620b896d"
                            alt="human face icons 2"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginLeft: "-5px",
                              objectFit: "cover",
                            }}
                          />
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2Fteam11.webp?alt=media&token=12f5be8b-8a4b-433f-87aa-832401d9a9fc"
                            alt="human face icons 3"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginLeft: "-5px",
                              objectFit: "cover",
                            }}
                          />
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2Fteam12.webp?alt=media&token=962edf62-5034-4c5e-98c4-596099cb3f23"
                            alt="human face icons 4"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginLeft: "-5px",
                              objectFit: "cover",
                            }}
                          />
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2Fteam3.webp?alt=media&token=70921631-a22b-4d3a-844c-831ae47919d7"
                            alt="human face icons 5"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginLeft: "-5px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="interntional_left_bottom_div">
                  <div className="row">
                    <div className="col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                      <div>
                        <img
                          src="./assets/img/calender-image.png"
                          alt="calander image"
                          className="international-booking-dentist"
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-12 d-flex justify-content-center">
                      <div>
                        <img
                          src="https://media.istockphoto.com/id/1362571408/photo/dental-instruments-calendars-blue-background-dentist-visit-concept.jpg?s=612x612&w=0&k=20&c=D2WVlZREOky7VtlK8nkjn3LSGGyrP6QQ8fV7Lt4DIjs="
                          alt="calander image"
                          className="international-booking-dentist"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="d-flex align-items-center">
                    <img
                      src="https://kolkata.apollohospitals.com/wp-content/uploads/2021/06/internationalpatientcare.jpg"
                      alt="international img"
                      className="international-two-img"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex align-items-center">
                    <img
                      src="https://media.istockphoto.com/id/1437821111/photo/customer-service-happy-and-communication-of-woman-at-call-center-pc-talking-with-joyful-smile.webp?b=1&s=170667a&w=0&k=20&c=VaNC1beA8yRqc22HZdOnyyl8KrHNNXNmOoZ5T_xr6HY="
                      alt="international img"
                      className="international-two-img"
                      style={{ marginTop: "0px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* USER UPCOMING BOOKINGS CODE START */}
      {appointmentPermission && appointmentPermission.permission == true ? (
        <UpcomingBookingsCard />
      ) : (
        <br />
      )}
      <div>
        <section
          className="footer_before relative ip_footer_upper_before"
          style={{
            backgroundImage: "url('./assets/img/ip_green_section_bg.jpeg')",
          }}
        >
          <div className="container">
            <div className="fb_inner">
              <h3
                style={{
                  width: "80%",
                  color: "black",
                  fontWeight: "700",
                }}
              >
                Concerned about missing your country during your treatment?
                <br /> <br />
                <span style={{ fontSize: "20px", fontWeight: "100" }}>
                  We're here to create a home-like experience for you.
                </span>
              </h3>
              <br />
              <br />
              <div className="btn_group">
                <Link to="/our-team">
                  <button
                    className="btn_fill"
                    style={{ backgroundColor: "var(--click-color)" }}
                  >
                    Our Team
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* why choose us Section only for desktop */}
      <div className="international_why_choose_parent_div">
        <div className="container">
          <div className="row">
            <div
              style={{
                marginTop: "0px",
              }}
            >
              <div className="ip_middle_circle">
                <div className="ip_why_choose_circle">
                  Why
                  <br />
                  Choose
                  <br />
                  us
                </div>
                <div className="ip_middle_div_text">
                  We stay up-to-date with the latest advancements in medical
                  technology and treatments. This commitment to innovation
                  ensures that patients have access to the most effective and
                  minimally invasive options.
                </div>
                <div style={{ display: "flex" }}>
                  <div className="ip_last_circles-1">
                    <img
                      alt="airplane logo img"
                      className="ip_airplane_icon"
                      src="https://media4.giphy.com/media/bmoKSwzYNVLLnsZARg/giphy.gif?cid=6c09b95297vlpwuexpnw3sy36ojwzgp2hk2ss8924bcg5gwi&ep=v1_stickers_related&rid=giphy.gif&ct=s"
                    ></img>
                  </div>
                  <div className="ip_whychoose_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        1. Expertise & Specialization
                      </h5>
                      <p
                        style={{
                          color: "var(--black-color)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        The center's team consists of highly trained and
                        specialized professionals, ensuring that patients
                        receive the best care for their specific needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ip_last_circles-2">
                  <img
                    className="ip_hand_icon"
                    alt="hand img"
                    src="https://www.greatamericaninsurancegroup.com/images/default-source/career-seekers/nonprofits-and-social-services-unscreen.gif?sfvrsn=df9955b1_1"
                  ></img>
                  <div className="ip_whychoose_second_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        2. State-of-the-Art Facilities
                      </h5>
                      <p
                        style={{
                          color: "var(--black-color)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        Equipped with modern, state-of-the-art medical equipment
                        and facilities, allowing for advanced and precise
                        diagnostics and treatment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ip_last_circles-3">
                  <img
                    alt="time logo"
                    className="ip_time_icon"
                    src="https://static.wixstatic.com/media/6e6166_cec12188412a4e72a90d31e2b2be6932~mv2.gif"
                  ></img>
                  <div className="ip_whychoose_third_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        3. Convenient Location
                      </h5>
                      <p
                        style={{
                          color: "var(--black-color)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        The location in Dubai offers convenience for residents
                        and visitors alike. Easy accessibility from Dubai &
                        Sharjah Airport.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ip_last_circles-4">
                  <img
                    alt="security logo img"
                    className="ip_securty_icon"
                    src="https://www.qomplx.com/assets/images/ms-icon.gif?v=145cff52da"
                  ></img>
                  <div className="ip_whychoose_fourth_details">
                    <hr
                      style={{
                        backgroundColor: "var(--black-color)",
                        width: "300px",
                        height: "2px",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "700",
                          color: "var(--black-color)",
                        }}
                      >
                        4. Insurance Acceptance
                      </h5>
                      <p
                        style={{
                          color: "var(--black-color)",
                          fontSize: "15px",
                          fontWeight: "100",
                        }}
                      >
                        Accept various insurance plans, making healthcare more
                        affordable and accessible to a broader
                        range of patients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* why choose us Section only for phone & tab */}
      <div className="offer_banner_parent ip_whychoose_phone_div_parent">
        <OwlCarousel {...options}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`item ${index === 1 ? "highlighted" : ""}`}
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
              }} // Add margin and center-align
            >
              <Card
                data-resizable
                sx={{
                  width: 343,
                  overflow: "auto",
                  resize: "horizontal",
                  "--icon-size": "100px",
                }}
              >
                <CardOverflow
                  // variant="solid"
                  className="ip_whychoose_upper_bg_color"
                >
                  {/* <h3>Why Choose Us Because</h3> */}
                  <AspectRatio
                    ratio="1"
                    sx={{
                      m: "auto",
                      transform: "translateY(50%)",
                      borderRadius: "50%",
                      width: "var(--icon-size)",
                      boxShadow: "sm",
                      // bgcolor: "background.surface",
                      // position: "relative",
                    }}
                  >
                    <div>
                      {/* Use the image URL from the array */}
                      <img src={images[index]} alt={`Image ${index + 1}`} />
                    </div>
                  </AspectRatio>
                </CardOverflow>
                <Typography
                  level="title-lg"
                  sx={{
                    mt: "calc(var(--icon-size) / 2)",
                    textAlign: "center",
                    fontWeight: "700",
                    color: "var(--pink)",
                  }}
                >
                  {expertiseSpecializationTopics[index]}
                </Typography>

                <CardContent
                  sx={{ maxWidth: "40ch", textAlign: "center", color: "grey" }}
                >
                  {expertiseSpecializationcontent[index]}
                </CardContent>
              </Card>
            </div>
          ))}
        </OwlCarousel>
      </div>

      {/* Our Journey Section */}
      <section className="journey-section">
        <div className="journeyBox">
          <div className="journey">
            <div className="row">
              <div className="col-sm-6 block">&nbsp;</div>
              <div className="col-sm-6 block">
                <div className="media">
                  <img
                    src="./assets/img/ip-analyze.png"
                    alt="analyze image"
                  ></img>
                </div>
                <div className="text">
                  <h3 className="title3">
                    1. Medical history to be analyzed by our team
                  </h3>
                  <p></p>
                  <p>
                    Our team examines your medical history to recommend suitable
                    treatment options, create a personalized care plan, and
                    ensure the best possible outcomes for your health.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* second row */}
          <div className="journey">
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 block">&nbsp;</div>
              <div className="col-sm-6 col-sm-pull-6 block">
                <div className="media">
                  <img src="./assets/img/ip-trip.png" alt="trip logo img"></img>
                </div>
                <div className="text">
                  <h3 className="title3">
                    2. Video consultation before making your trip
                  </h3>
                  <p></p>
                  <p>
                    Prior to finalizing your travel arrangements, we can arrange
                    an online video meeting to thoroughly examine your medical
                    condition, gaining a better understanding of your treatment
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* third row */}
          <div className="journey">
            <div className="row">
              <div className="col-sm-6 block">&nbsp;</div>
              <div className="col-sm-6 block">
                <div className="media">
                  <img src="./assets/img/ip-rest.png" alt="rest logo img"></img>
                </div>
                <div className="text">
                  <h3 className="title3">
                    3. Confirm your travel to ensure smooth coordination
                  </h3>
                  <p></p>
                  <p>
                    Once you confirm your travel dates, our team will coordinate
                    all your appointments accordingly. Upon your arrival, our
                    designated executive will be in contact with you, offering
                    comprehensive assistance throughout your entire treatment
                    period.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* fourth row */}
          <div className="journey">
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 block">&nbsp;</div>
              <div className="col-sm-6 col-sm-pull-6 block">
                <div className="media">
                  <img
                    src="./assets/img/ip-discharge.png"
                    alt="discharge logo img"
                  ></img>
                </div>
                <div className="text">
                  <h3 className="title3">
                    4. Get assistance from the moment you arrive until departure
                  </h3>
                  <p></p>
                  <p>
                    At our Medical Center, starting from the initial procedure
                    and doctor consultations through to treatment and recovery.
                    Our international patient services executive will be your
                    constant companion. After successful treatment, the doctor
                    will provide guidance on post-treatment care and offer
                    assistance with any necessary follow-up appointments in the
                    future.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* fifth row */}
          <div className="journey">
            <div className="row">
              <div className="col-sm-6 block">&nbsp;</div>
              <div className="col-sm-6 block">
                <div className="media">
                  <img
                    src="./assets/img/ip-takeCare.png"
                    alt="takecare logo img"
                  ></img>
                </div>
                <div className="text">
                  <h3 className="title3">
                    5. All your documentation is properly managed
                  </h3>
                  <p></p>
                  <p>
                    We will create an account for you on our website, enabling
                    you to access your complete medical history, payment
                    history, billing details, and communicate with our
                    healthcare team anytime, anywhere.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* sixth row */}
          <div className="journey">
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 block">&nbsp;</div>
              <div className="col-sm-6 col-sm-pull-6 block">
                <div className="media">
                  <img
                    src="./assets/img/ip-teleMedicine.png"
                    alt="telemedicine logo img"
                  ></img>
                </div>
                <div className="text">
                  <h3 className="title3">
                    6. Follow-up through video calls after returning to
                    your home country
                  </h3>
                  <p></p>
                  <p>
                    Dentamax Medical Center offers convenient ways to reach out
                    to your doctor from the comfort of your home using our
                    user-friendly online phone, video, or email consultation
                    service. Connecting with any of our doctors is
                    just a click away.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* journey end line */}
          <div className="journey evenLast"></div>
        </div>
      </section>
      <Testimonials />
    </div>
  );
};

export default InternationalPatients;
