import React, { useState, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFirestore } from "../hooks/useFirestore";
import { projectStorage, timestamp } from "../firebase/config";
import "./AddMedia.css";

const AddMedia = ({
  category,
  pageName,
  section,
  addMoreImagesButton = true,
  addMoreVideosButton = true,
}) => {
  const { user } = useAuthContext();
  const { addDocument, response } = useFirestore("media");

  const fileInputRef = useRef(null);
  const [newMediaUrl, setNewMediaUrl] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleAddMoreImages = () => {
    // Trigger file input click using ref
    fileInputRef.current.click();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setShowFileInput(true); // Show file input after selecting an image
  };

  // Add images in firesbase
  const handleUpload = async () => {
    try {
      if (selectedImage) {
        setIsUploading(true); // Set uploading status to true

        // Upload image to storage
        const storageRef = projectStorage.ref(`images/${selectedImage.name}`);
        await storageRef.put(selectedImage);

        // Get the download URL
        const imageURL = await storageRef.getDownloadURL();

        // Store the image URL in Firestore or use it as needed
        const newOffer = {
          pageName: pageName,
          mediaType: "image",
          mediaUrl: imageURL,
          category: category,
          section: section,
          status: "active",
          createdAt: timestamp.fromDate(new Date()),
        };
        await addDocument(newOffer);
        // After uploading, show the confirmation button
        setShowFileInput(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false); // Reset uploading status
    }
  };

  // Add video in firesbase
  const handleAddMedia = async () => {
    // Check if the input is empty
    if (!newMediaUrl.trim()) {
      // Display an error message or handle empty input
      alert("Please fill video url");
      return;
    }

    const newMedia = {
      pageName: pageName,
      mediaType: "video",
      mediaUrl: newMediaUrl,
      category: category,
      section: section,
      status: "active",
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(newMedia);

    if (!response.error) {
      // Clear the input field after adding the video
      setNewMediaUrl("");
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          {addMoreVideosButton && user && user.role == "admin" && (
            <div>
              {" "}
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Paste your youtube video URL"
                  className="styled-input"
                  value={newMediaUrl}
                  onChange={(e) => setNewMediaUrl(e.target.value)}
                ></input>
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn_fill"
                  onClick={handleAddMedia}
                >
                  Add Video
                </button>
              </div>
            </div>
          )}

          {addMoreImagesButton && user && user.role == "admin" && (
            <div className="d-flex justify-content-center">
              {showFileInput ? (
                <>
                  <div className="image-preview">
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected offer image"
                      style={{
                        width: "100px",
                        borderRadius: "10px 0px 0px 10px",
                      }}
                    />
                  </div>
                  <button
                    className="btn_fill"
                    onClick={handleUpload}
                    disabled={isUploading} // Disable the button when uploading
                  >
                    {isUploading ? "Uploading..." : "Confirm"}
                  </button>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    id="imageInput"
                    multiple
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <button className="btn_fill" onClick={handleAddMoreImages}>
                    Add {category} Images
                  </button>{" "}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMedia;
