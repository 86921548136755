import * as React from "react";
import { useState, useEffect } from "react";
import LinearProgressBar from "../../../components/LinearProgressBar";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollection } from "../../../hooks/useCollection";
import { Navigate, Link } from "react-router-dom";
import OfferBanners from "../../../components/OfferBanners";
import AddBookingIcon from "../../../components/AddBookingIcon";
import AddHolidays from "../../../components/AddHolidays";
// owl carousel
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carousel
// css
import "./PGCustomerDashboard.css";

const PGCustomerDashboard = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // Scroll to the top of the page whenever the location changes end

  const { user } = useAuthContext();
  const { documents: bookings, error: bookingserror } =
    useCollection("bookings");

  const userBookings = bookings
    ? bookings.filter((booking) => booking.createdBy === user.uid)
    : [];

  // Calculate the count of bookings for each product
  const productCounts =
    user && user.role == "admin"
      ? (bookings || []).reduce((acc, booking) => {
          acc[booking.productName] = (acc[booking.productName] || 0) + 1;
          return acc;
        }, {})
      : (userBookings || []).reduce((acc, booking) => {
          acc[booking.productName] = (acc[booking.productName] || 0) + 1;
          return acc;
        }, {});

  // Sort the products based on their booking counts
  const sortedProducts = Object.keys(productCounts).sort(
    (a, b) => productCounts[b] - productCounts[a]
  );

  // Take the top 4 products
  const top4Products = sortedProducts.slice(0, 4);

  return (
    <div>
      <AddBookingIcon />
      <div className="denta_top_header_pg pg_bg">
        <div className="page_spacing">
          <div className="denta_pg_header">
            <h4 className="m22 mb-1">
              <b>Dashboard</b>
            </h4>
            <h4 className="r18 light_black">
              Welcome <b> {user.fullName} </b>to Dentamax
            </h4>
          </div>
          <div className="pg_body">
            <div className="dentamax_dashboard_inner">
              <section className="row">
                <div className="col-xl-5">
                  <div className="total_prop_card relative">
                    <div className="bg_icon">
                      <img src="/assets/img/flats.png" alt="flat icon img" />
                    </div>
                    <div className="inner">
                      <div className="cust_dash_icon_all_book">
                        <span class="material-symbols-outlined">
                          fact_check
                        </span>
                      </div>
                      <div className="content">
                        <h4 style={{ color: "var(--white-color)" }}>
                          My Bookings
                        </h4>
                        <div className="bar">
                          <LinearProgressBar total="55" current="20" />
                        </div>

                        <h6>Explore your all types of bookings</h6>
                      </div>
                      <div className="number">
                        {/* {userBookings.length} */}
                        {user && user.role === "admin"
                          ? bookings && bookings.length
                          : userBookings.length}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-7 bg_575">
                  <div className="property_status">
                    <Link
                      to={{
                        pathname: "/allcustomerbookings",
                        search: "?filter=UPCOMING",
                      }}
                      style={{ color: "var(--black-color)" }}
                    >
                      <div className="ps_single active">
                        <h5>
                          {user && user.role === "admin"
                            ? bookings &&
                              bookings.filter(
                                (booking) =>
                                  booking.status === "upcoming" ||
                                  booking.status === "approved"
                              ).length
                            : userBookings &&
                              userBookings.filter(
                                (booking) =>
                                  booking.status === "upcoming" ||
                                  booking.status === "approved"
                              ).length}
                        </h5>
                        <h6>Upcoming</h6>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/allcustomerbookings",
                        search: "?filter=COMPLETED",
                      }}
                      style={{ color: "var(--black-color)" }}
                    >
                      <div className="ps_single active">
                        <h5>
                          {user && user.role === "admin"
                            ? bookings &&
                              bookings.filter(
                                (booking) => booking.status === "completed"
                              ).length
                            : userBookings &&
                              userBookings.filter(
                                (booking) => booking.status === "completed"
                              ).length}
                        </h5>
                        <h6>Completed</h6>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/allcustomerbookings",
                        search: "?filter=CANCELLED",
                      }}
                      style={{ color: "var(--black-color)" }}
                    >
                      <div className="ps_single active">
                        <h5>
                          {user && user.role === "admin"
                            ? bookings &&
                              bookings.filter(
                                (booking) => booking.status === "rejected"
                              ).length
                            : userBookings &&
                              userBookings.filter(
                                (booking) => booking.status === "rejected"
                              ).length}
                        </h5>
                        <h6>Cancelled</h6>
                      </div>
                    </Link>
                  </div>
                </div>
              </section>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="d-flex gap-2">
                    <div className="mt-4 mb-4 w-50">
                      <Link to="/products">
                        <button style={{ width: "100%" }} className="btn_fill">
                          New Booking
                        </button>
                      </Link>
                    </div>
                    <div className="mt-4 mb-4 w-50">
                      <Link to="/allcustomerbookings">
                        <button style={{ width: "100%" }} className="btn_fill">
                          Booking History
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="properties_map">
                    <h2
                      className="p_title"
                      style={{
                        fontSize: "21px",
                        fontWeight: "500",
                        marginBottom: "6px",
                      }}
                    >
                      <b>Top Booked Services By You</b>
                    </h2>
                    <div className="pi_cities row">
                      <div className="row">
                        {top4Products.length === 0 ? (
                          <div className="pgcutomer-no-booking-message">
                            <h5>No booking yet</h5>
                          </div>
                        ) : (
                          top4Products.map((productName, index) => {
                            return (
                              <div
                                key={index}
                                className="pi_cities_single mt-4 col-6 col-md-6 col-lg-6"
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: productName.toString("html"),
                                  }}
                                ></p>
                                <h5>{productCounts[productName]}</h5>
                                <div className="bar">
                                  <div
                                    className="bar_fill"
                                    style={{
                                      width:
                                        user && user.role == "admin"
                                          ? (productCounts[productName] /
                                              bookings.length) *
                                              100 +
                                            "%"
                                          : (productCounts[productName] /
                                              userBookings.length) *
                                              100 +
                                            "%",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <section className="self_property_detail">
                    <div className="row">
                      <div className="col-6 col-md-6 col-lg-6">
                        <div className="spd_single">
                          <div className="custo_dash_left_icon residential">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "var(--pink)" }}
                            >
                              credit_card_off
                            </span>
                          </div>
                          <div className="right">
                            <h6>Unpaid Payment</h6>
                            <h5>
                              {user && user.role === "admin"
                                ? bookings &&
                                  bookings.filter(
                                    (unpaidbookings) =>
                                      unpaidbookings.paymentstatus === "unpaid"
                                  ).length
                                : userBookings &&
                                  userBookings.filter(
                                    (unpaidbookings) =>
                                      unpaidbookings.paymentstatus === "unpaid"
                                  ).length}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-lg-6">
                        <div className="spd_single">
                          <div className="custo_dash_left_icon commercial">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "var(--pink)" }}
                            >
                              credit_score
                            </span>
                          </div>
                          <div className="right">
                            <h6>Paid Payment</h6>
                            <h5>
                              {user && user.role === "admin"
                                ? bookings &&
                                  bookings.filter(
                                    (paidpayment) =>
                                      paidpayment.paymentstatus === "paid"
                                  ).length
                                : userBookings &&
                                  userBookings.filter(
                                    (paidpayment) =>
                                      paidpayment.paymentstatus === "paid"
                                  ).length}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-6 col-md-6 col-lg-6">
                        <div className="spd_single">
                          <div className="custo_dash_left_icon commercial">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "var(--pink)" }}
                            >
                              credit_score
                            </span>
                          </div>
                          <div className="right">
                            <h6>Paid Payment</h6>
                            <h5>{userBookings && userBookings.length}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-lg-6">
                        <div className="spd_single">
                          <div className="custo_dash_left_icon commercial">
                            <span
                              class="material-symbols-outlined"
                              style={{ color: "var(--pink)" }}
                            >
                              credit_score
                            </span>
                          </div>
                          <div className="right">
                            <h6>Paid Payment</h6>
                            <h5>{userBookings && userBookings.length}</h5>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {user && user.role == "admin" && <AddHolidays />}
      </div>
      <br />
      <OfferBanners /> <br />
    </div>
  );
};

export default PGCustomerDashboard;
