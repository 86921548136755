import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { useState, useEffect } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { projectFirestore } from "../../firebase/config";
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link } from "react-router-dom";
import AddBookingIcon from "../../components/AddBookingIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import "./PGCalendarBooking.css";
function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs();

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;
  const textColor = isSelected ? "white" : "white"; // Set the text color based on selection

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "🔔" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        style={{ color: textColor, fontSize: "15px" }}
      />
    </Badge>
  );
}

export default function PGCalendarBooking() {
  const { user } = useAuthContext();
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const requestAbortController = React.useRef(null);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
  const [isDateClicked, setIsDateClicked] = useState(false);
  const [isOverlayOut, setIsOverlayOut] = useState(false);
  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [selectedBookingDate, setSelectedBookingDate] = useState(null);

  const { document: userDocument, error: userDocumentError } = useDocument(
    "users",
    user.uid
  );

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setIsDateClicked(true);
    console.log("Selected Date:", date.format("MM/DD/YYYY"));

    // Convert selected date to "MM/DD/YYYY" format
    const formattedSelectedDate = date.format("MM/DD/YYYY");
    console.log("Formatted Selected Date:", formattedSelectedDate);

    // Log the entire bookings array
    console.log("All Bookings:", bookings);

    // Find the booking for the selected date
    const bookingForSelectedDate = bookings.find((booking) => {
      const bookingDate = convertFirestoreTimestamp(booking.bookingDate);

      console.log(
        "Formatted Booking Date:",
        bookingDate,
        "Is Match:",
        formattedSelectedDate === bookingDate
      );

      return formattedSelectedDate === bookingDate;
    });

    if (bookingForSelectedDate) {
      setSelectedBookingDate(
        dayjs(
          convertFirestoreTimestamp(bookingForSelectedDate.bookingDate)
        ).format("DD/MM/YYYY")
      );
    } else {
      setSelectedBookingDate(null);
    }
  };

  // Add a useEffect to log the selectedBookingDate after it has been updated
  useEffect(() => {
    console.log("Booking Date from Firestore:", selectedBookingDate);
  }, [selectedBookingDate]);

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const bookingsCollection = projectFirestore.collection("bookings");
      const querySnapshot = await bookingsCollection.get();

      const bookingsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Fetched Bookings:", bookingsData);

      setBookings(bookingsData);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [selectedDate]);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  function convertFirestoreTimestamp(timestamp) {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;

    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    const date = new Date(milliseconds);

    // Return a formatted date string in "DD/MM/YYYY" format
    return dayjs(date).format("MM/DD/YYYY");
  }

  // Helper function to group bookings by date
  const groupBookingsByDate = (bookings) => {
    const groupedBookings = {};

    bookings.forEach((booking) => {
      const bookingDate = dayjs(
        convertFirestoreTimestamp(booking.bookingDate),
        { timeZone: "Asia/Kolkata" }
      ).format("DD/MM/YYYY");

      if (!groupedBookings[bookingDate]) {
        groupedBookings[bookingDate] = [booking];
      } else {
        groupedBookings[bookingDate].push(booking);
      }
    });

    return groupedBookings;
  };

  const groupedBookings = groupBookingsByDate(bookings);

  // Helper function to sort dates in descending order
  const sortDatesDescending = (dates) => {
    return dates.sort((a, b) => {
      const dateB = dayjs(a, "DD/MM/YYYY");
      const dateA = dayjs(b, "DD/MM/YYYY");
      return dateB - dateA;
    });
  };
  console.log("Selected Booking Date:", selectedBookingDate);
  console.log("Grouped Bookings:", groupedBookings);

  const sortedDates = sortDatesDescending(Object.keys(groupedBookings));
  return (
    <>
      <AddBookingIcon />
      <div style={{ backgroundColor: "#f3f3f3" }}>
        <div className="calendar_booking_upper_heading_parent">
          <h6 style={{ fontWeight: "700", letterSpacing: "1px" }}>
            PATIENT BOOKINGS
          </h6>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="calender_doctor-details_parent">
                <Card
                  className="doctor_detail_right_card"
                  orientation="verticle"
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", aspectRatio: "2/2" }}>
                      <img
                        loading="lazy"
                        src={user.photoURL}
                        alt={user.fullName}
                        className="calender_dr_img"
                      ></img>
                    </div>
                    <div>
                      {" "}
                      <Typography fontSize="xl" fontWeight="lg">
                        {user.fullName}
                      </Typography>
                      <Typography
                        level="body-sm"
                        fontWeight="lg"
                        textColor="text.tertiary"
                      >
                        Senior Journalist
                      </Typography>
                    </div>
                  </div>

                  <CardContent className="calender_doctor_card_details">
                    <Sheet
                      sx={{
                        bgcolor: "background.level1",
                        borderRadius: "sm",
                        p: 1.5,
                        my: 1.5,
                        display: "flex",
                        gap: 1,
                        "& > div": { flex: 1 },
                      }}
                    >
                      <div>
                        <Typography level="body-xs" fontWeight="lg">
                          Pending
                        </Typography>
                        <Typography fontWeight="lg">13</Typography>
                      </div>
                      <div>
                        <Typography level="body-xs" fontWeight="lg">
                          Confirm
                        </Typography>
                        <Typography fontWeight="lg">49</Typography>
                      </div>
                      <div>
                        <Typography level="body-xs" fontWeight="lg">
                          Cancelled
                        </Typography>
                        <Typography fontWeight="lg">9</Typography>
                      </div>
                    </Sheet>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        "& > button": { flex: 1 },
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link to="/profile">
                        <Button variant="outlined" color="neutral">
                          Profile
                        </Button>
                      </Link>
                      <Link to="/products">
                        <Button variant="solid" color="primary">
                          Products
                        </Button>
                      </Link>
                    </Box>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="calender_bookings_parent">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    defaultValue={initialValue}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                      day: (props) => (
                        <ServerDay
                          {...props}
                          onClick={() => handleDateClick(props.day)}
                        />
                      ),
                    }}
                    slotProps={{
                      day: {
                        highlightedDays,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="col col-sm-12 col-md-12 col-lg-12">
              <div className="calender_bookings_right_parent">
                <div className="d-flex justify-content-center">
                  <div className="calender_bookings_right_parent_top_line"></div>
                </div>
                <div className="d-flex justify-content-center">
                  {" "}
                  <p
                    style={{
                      color: "white",
                      fontWeight: "700",
                      margin: "20px 0px 15px 0px",
                      letterSpacing: "1px",
                    }}
                  >
                    Appointments
                  </p>
                </div>
                <div className="">
                  {isDateClicked ? (
                    // Render bookings for the selected date
                    <>
                      {selectedBookingDate &&
                      groupedBookings[selectedBookingDate] ? (
                        <div key={selectedBookingDate}>
                          <h6
                            style={{
                              color: "white",
                              fontWeight: "700",
                              margin: "20px 0px 12px 11px",
                              letterSpacing: "1px",
                            }}
                          >
                            {selectedBookingDate}
                          </h6>
                          <div className="row">
                            {groupedBookings[selectedBookingDate].map(
                              (booking) => (
                                <div
                                  key={booking.id}
                                  className="calender_appointment_time_details"
                                >
                                  <div className="calendar_appointment_detail_crd">
                                    <div className="calendar_appointment_detail_heading">
                                      {booking.selectedTimeSlot}
                                    </div>
                                    <p>
                                      <span style={{ fontWeight: "700" }}>
                                        Name:{" "}
                                      </span>
                                      {booking.customerName}
                                    </p>
                                    <p>
                                      <span style={{ fontWeight: "700" }}>
                                        Booked For:{" "}
                                      </span>
                                      {booking.category}
                                    </p>
                                    <p>
                                      <span style={{ fontWeight: "700" }}>
                                        Type:{" "}
                                      </span>
                                      {booking.bookingReason}
                                    </p>
                                    <hr style={{ margin: "8px 0px" }} />
                                    <a style={{ color: "var(--click-color)" }}>
                                      Cancel
                                    </a>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    <a style={{ color: "var(--click-color)" }}>
                                      View Details
                                    </a>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <h5
                            style={{
                              color: "white",
                              fontWeight: "700",
                            }}
                          >
                            No Patient Bookings On Selected Date
                          </h5>
                        </div>
                      )}
                    </>
                  ) : (
                    // Render all bookings initially
                    <>
                      {sortedDates.map((date) => (
                        <div key={date}>
                          <h6
                            style={{
                              color: "white",
                              fontWeight: "700",
                              margin: "20px 0px 12px 11px",
                              letterSpacing: "1px",
                            }}
                          >
                            {dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY")}
                          </h6>
                          <div className="row">
                            {groupedBookings[date].map((booking) => (
                              <div
                                key={booking.id}
                                className="calender_appointment_time_details"
                              >
                                <div className="calendar_appointment_detail_crd">
                                  <div className="calendar_appointment_detail_heading">
                                    {booking.selectedTimeSlot}
                                  </div>
                                  <p>
                                    <span style={{ fontWeight: "700" }}>
                                      Name:{" "}
                                    </span>
                                    {booking.customerName}
                                  </p>
                                  <p>
                                    <span style={{ fontWeight: "700" }}>
                                      Booked For:{" "}
                                    </span>
                                    {booking.category}
                                  </p>
                                  <p>
                                    <span style={{ fontWeight: "700" }}>
                                      Type:{" "}
                                    </span>
                                    {booking.bookingReason}
                                  </p>
                                  <hr style={{ margin: "8px 0px" }} />
                                  <a style={{ color: "var(--click-color)" }}>
                                    Cancel
                                  </a>
                                  &nbsp;&nbsp;|&nbsp;&nbsp;
                                  <a style={{ color: "var(--click-color)" }}>
                                    View Details
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
