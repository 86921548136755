import React from "react";
import { Link } from "react-router-dom";

import "./AddBookingIcon.css";

const phoneNumber = "+97142636717"; 

const AddBookingIcon = () => {
  return (
    <div>
      <a href={`tel:${phoneNumber}`} className="ab_icon call_icon">
        <img
          src="./assets/img/call-icon.png"
          alt="icon"
          style={{ width: "35px" }}
        />
      </a>
    </div>
  );
};

export default AddBookingIcon;
