import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import OwlCarousel from "react-owl-carousel";
import { useFirestore } from "../../hooks/useFirestore";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../../firebase/config";

// import AddBookingIcon from "../../components/AddBookingIcon";
import "./Products.css";
import { FaEdit } from "react-icons/fa";

const Products = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  // Fetch data from the "services" collection
  const { documents: products, error: productsError } =
    useCollection("products");
  const { addDocument } = useFirestore("slots");

  // Add services in firebase
  const [isEditing, setIsEditing] = useState(false);
  const [productName, setProductName] = useState("Product Name");
  const [selectedProductImage, setProductSelectedImage] = useState(null);

  const handleNameClick = () => {
    setIsEditing(true);
    setProductName("");
  };

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleProductImageChange = (event) => {
    const file = event.target.files[0];
    setProductSelectedImage(file);
  };

  const handleAddProduct = async () => {
    // Validation: Check if product name is provided
    if (!productName || productName === "Product Name") {
      alert("Please provide product name.");
      return;
    }

    try {
      // Create a new product document in the "services" collection
      const newProductRef = await projectFirestore.collection("products").add({
        name: productName,
        price: "",
        discount: "",
        rating: "⭐⭐⭐⭐",
        short_description: "",
        description: "",
        status: "active",
        createdAt: timestamp.fromDate(new Date()),
        images: [],
      });

      // Get the ID of the newly created document
      const newProductId = newProductRef.id;

      // Upload the image to the "product_img" folder in Firebase Storage
      if (selectedProductImage) {
        // Use the document ID as the folder name
        const storageRef = projectStorage.ref(`product_img/${newProductId}`);

        // Upload the image to the created folder in Firebase Storage
        const imageRef = storageRef.child(selectedProductImage.name);
        await imageRef.put(selectedProductImage);

        // Get the URL of the uploaded image
        const imageUrl = await imageRef.getDownloadURL();

        // Update the document with the image URL
        await newProductRef.update({
          images: [imageUrl],
        });

        // Perform actions with the selected image, e.g., store in local storage
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageData = reader.result;
          // Store imageData in local storage or handle as needed
          localStorage.setItem("productImage", imageData);
        };
        reader.readAsDataURL(selectedProductImage);
      }

      // Call the function to add a document in the "slots" collection
      await addSlotDocument(newProductId);

      // Reset product name and selected image after adding the product
      setProductName("Product Name");
      setProductSelectedImage(null);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // ADD DOCUMENT FOR DEFAULT SLOT IN SLOTS COLLECTION
  const addSlotDocument = async (productId) => {
    try {
      await addDocument({
        productId,
        data: [
          { startTime: "09:00 AM", endTime: "12:00 PM" },
          { startTime: "12:00 PM", endTime: "03:00 PM" },
          { startTime: "03:00 PM", endTime: "06:00 PM" },
          { startTime: "06:00 PM", endTime: "09:00 PM" },
        ],
        slotType: "default",
        createdAt: timestamp.fromDate(new Date()),
      });
    } catch (error) {
      console.error("Error adding slot document:", error);
    }
  };

  // Reset product name and selected image
  const handleCancel = () => {
    setProductName("Product Name");
    setProductSelectedImage(null);
  };
  return (
    <div className="booking-services-parent-div">
      <div className="container">
        <div
          className="d-flex align-items-center justify-content-between mb-3"
          style={{ margin: "auto" }}
        >
          <div>
            <div className="products_heding_contner">
              <h4>Appoinment</h4>
              <h5>Book Your Slot</h5>
            </div>
          </div>
          {user && user && (
            <div>
              <Link to="/allcustomerbookings">
                <button className="btn_fill">Booking History</button>
              </Link>
            </div>
          )}
        </div>
        <hr
          style={{
            // width: "95%",
            margin: "0px auto 30px auto",
            height: "2px",
          }}
        />
        {user && user.role == "admin" && (
          <>
            <div className="offers-section-title">
              <h2
                style={{
                  fontSize: "20px",
                  marginLeft: "0px",
                  color: "var(--click-color)",
                }}
              >
                Active Products
              </h2>
            </div>
          </>
        )}
        <div className="row">
          {user && user.role == "admin" && (
            <div className="col-6 col-lg-3 services-cards-parent-div">
              <div className="doctor-item ss">
                <div className="small_margin_div"></div>
                <div className="doctor-top product-thumb-img d-flex align-items-center justify-content-center">
                  {selectedProductImage ? (
                    <img
                      src={URL.createObjectURL(selectedProductImage)}
                      alt="Product img 4"
                    />
                  ) : (
                    <img
                      src="https://securitysolutions.me/wp-content/uploads/2018/12/dummy-person.jpg"
                      alt="Product img 5"
                    />
                  )}
                  <div className="status-button-container">
                    <div style={{ margin: "auto" }}>
                      <input
                        type="file"
                        accept="image/*"
                        id="serviceimageInput"
                        onChange={handleProductImageChange}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="serviceimageInput"
                        style={{ cursor: "pointer" }}
                      >
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
                <div className="booking_services_name_haedings_parent">
                  {isEditing ? (
                    <input
                      type="text"
                      value={productName}
                      onChange={handleNameChange}
                      onBlur={handleNameBlur}
                      required
                    />
                  ) : (
                    <h3>
                      <a
                        className="booking_services_name_haedings"
                        onClick={handleNameClick}
                      >
                        <h5 className="doctors-name-h5">{productName}</h5>
                        <FaEdit
                          style={{
                            fontSize: 20,
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "var(--pink)",
                          }}
                        />
                      </a>
                    </h3>
                  )}
                  <div className="booking_services_two_buttons">
                    <button
                      type="button"
                      className="btn_fill"
                      onClick={handleAddProduct}
                    >
                      Add Product
                    </button>
                    <button
                      type="button"
                      className="booking_services_see_all_btn"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {products &&
            products.map((doc, index) => {
              // Check if the user is an admin or if the product is active
              if (
                (user && user.role === "admin" && doc.status === "active") ||
                doc.status === "active"
              ) {
                return (
                  <div
                    className="col-6 col-sm-6 col-lg-3 products-cards-parent-div"
                    key={index}
                  >
                    <div className="small_margin_div"></div>
                    <Link to={`/product-details/${doc.id}`}>
                      <div className="doctor-item ss">
                        <div className="doctor-top product-thumb-img">
                          <OwlCarousel
                            nav={false}
                            dots={false}
                            loop
                            autoplay
                            autoplayTimeout={3000}
                            items={1}
                          >
                            {doc.images &&
                              doc.images.map((imageUrl, index) => (
                                <div key={index}>
                                  <img
                                    src={imageUrl}
                                    alt={`Product Image ${index}`}
                                  />
                                </div>
                              ))}
                          </OwlCarousel>
                          {user &&
                          user.role == "admin" &&
                          user.status == "active" ? (
                            <div className="status-button-container">
                              <button
                                className={`products_activ_inctive_btn ${doc.status}`}
                                style={{
                                  backgroundColor:
                                    doc.status === "active"
                                      ? "var(--click-color)"
                                      : "var(--pink)",
                                }}
                              >
                                {doc.status}
                              </button>
                            </div>
                          ) : null}
                        </div>

                        <div className="booking_services_price_location">
                          {doc.price && doc.price !== "" ? (
                            <div className="d-flex align-items-center justify-content-center flex-column">
                              <div>
                                <h6
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                  }}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: doc.price.toString("html"),
                                    }}
                                  ></p>
                                </h6>
                              </div>
                              {/* &nbsp; */}
                              {doc.discount && doc.discount == "" ? null : (
                                <div>
                                  <strike style={{ color: "var(--red-color)" }}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: doc.discount.toString("html"),
                                      }}
                                      style={{ fontSize: "13px" }}
                                    ></p>
                                  </strike>
                                </div>
                              )}
                            </div>
                          ) : (
                            <h5>{doc.rating}</h5>
                          )}
                        </div>

                        <div className="booking_services_name_haedings_parent">
                          <h3>
                            <a
                              className="booking_services_name_haedings"
                              href="/doctor-details"
                            >
                              <h5 className="doctors-name-h5">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: doc.name.toString("html"),
                                  }}
                                ></p>
                              </h5>
                            </a>
                          </h3>
                          <p className="product-short-detail">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: doc.short_description.toString("html"),
                              }}
                            ></p>
                          </p>

                          <div className="booking_services_two_buttons">
                            <div className="d-flex align-items-center justify-content-center">
                              <p>Details</p>
                              <span className="material-symbols-outlined booking_services_see_all_btn">
                                chevron_right
                              </span>
                            </div>
                            <div style={{ zIndex: "1" }}>
                              <Link
                                to={
                                  user && user
                                    ? `/product-details/${doc.id}`
                                    : "/login"
                                }
                              >
                                <button className="btn_fill">Book</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
        </div>

        {/* BELOW INACTIVE PRODUCTS */}
        {user && user.role == "admin" && (
          <>
            <br />
            <hr />
            <div className="offers-section-title">
              <h2
                style={{
                  fontSize: "20px",
                  marginLeft: "0px",
                  color: "var(--pink)",
                }}
              >
                Inactive Products
              </h2>
            </div>
            <div className="row">
              {products &&
                products.map((doc, index) => {
                  // Check if the user is an admin or if the product is active
                  if (
                    user &&
                    user.role === "admin" &&
                    doc.status === "inactive"
                  ) {
                    return (
                      <div
                        className="col-6 col-sm-6 col-lg-3 products-cards-parent-div"
                        key={index}
                      >
                        <div className="small_margin_div"></div>
                        <Link to={`/product-details/${doc.id}`}>
                          <div className="doctor-item ss">
                            <div className="doctor-top product-thumb-img">
                              <OwlCarousel
                                nav={false}
                                dots={false}
                                loop
                                autoplay
                                autoplayTimeout={3000}
                                items={1}
                              >
                                {doc.images &&
                                  doc.images.map((imageUrl, index) => (
                                    <div key={index}>
                                      <img
                                        src={imageUrl}
                                        alt={`Product Image ${index}`}
                                      />
                                    </div>
                                  ))}
                              </OwlCarousel>
                              {user &&
                              user.role == "admin" &&
                              user.status == "active" ? (
                                <div className="status-button-container">
                                  <button
                                    className={`products_activ_inctive_btn ${doc.status}`}
                                    style={{
                                      backgroundColor:
                                        doc.status === "active"
                                          ? "var(--click-color)"
                                          : "var(--pink)",
                                    }}
                                  >
                                    {doc.status}
                                  </button>
                                </div>
                              ) : null}
                            </div>

                            <div className="booking_services_price_location">
                              {doc.price && doc.price !== "" ? (
                                <div className="d-flex align-items-center justify-content-center">
                                  <div>
                                    <h6 style={{ fontWeight: "700" }}>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: doc.price.toString("html"),
                                        }}
                                      ></p>
                                    </h6>
                                  </div>
                                  {/* &nbsp; */}
                                  {doc.discount && doc.discount && (
                                    <div>
                                      <strike
                                        style={{ color: "var(--red-color)" }}
                                      >
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              doc.discount.toString("html"),
                                          }}
                                        ></p>
                                      </strike>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <h5>{doc.rating}</h5>
                              )}
                            </div>

                            <div className="booking_services_name_haedings_parent">
                              <h3>
                                <a
                                  className="booking_services_name_haedings"
                                  href="/doctor-details"
                                >
                                  <h5 className="doctors-name-h5">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: doc.name.toString("html"),
                                      }}
                                    ></p>
                                  </h5>
                                </a>
                              </h3>
                              <p className="product-short-detail">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      doc.short_description.toString("html"),
                                  }}
                                ></p>
                              </p>

                              <div className="booking_services_two_buttons">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p>Details</p>
                                  <span className="material-symbols-outlined booking_services_see_all_btn">
                                    chevron_right
                                  </span>
                                </div>
                                <div>
                                  <button className="btn_fill">Book</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                })}
            </div>
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
