import React from "react";
import { Link } from "react-router-dom";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import "./BeforeAfterSmile.css";
const BeforeAfterSmile = () => {
  return (
    <>
      <div className="image_slider_parents_div">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 d-flex align-items-center">
              <div className="beforeaftrer-left-div">
                <h4 style={{ color: "var(--black-color)" }}>Smile Gallery</h4>
                <h1 style={{ color: "var(--black-color)" }}>
                  A Beautiful Smile is Just a Visit Away
                </h1>
                <p style={{ color: "var(--black-color)" }}>
                  Our smile gallery showcases the amazing transformations we’ve
                  accomplished for our patients, highlighting the power of a
                  beautiful smile. From whitening to orthodontics, we offer a
                  range of tailored solutions to help you achieve your dream
                  smile. Explore our gallery and discover how we can help you
                  transform your smile today!
                </p>
                <Link to="/services">
                  <button className="btn_fill">More Services</button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex justify-content-center">
              <div
                className="smile-gallery-parent"
                style={{ position: "relative" }}
              >
                <ReactCompareSlider
                  style={{
                    height: "500px",
                    // width: "400px",
                    borderRadius: "10px",
                    border: "3px solid pink",
                  }}
                  itemOne={
                    <>
                      <ReactCompareSliderImage
                        src="/assets/img/smile-before.jpeg"
                        srcSet="/assets/img/smile-before.jpeg"
                        alt="Image one"
                      />
                      <div
                        className="smile-label"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 10,
                          backgroundColor: "grey",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        <h5>Before</h5>
                      </div>
                    </>
                  }
                  itemTwo={
                    <>
                      <ReactCompareSliderImage
                        src="/assets/img/smile-after.jpeg"
                        srcSet="/assets/img/smile-after.jpeg"
                        alt="Image two"
                      />
                      <div
                        className="smile-label"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          backgroundColor: "grey",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        <h5>After</h5>
                      </div>
                    </>
                  }
                />
              </div>
            </div>

            <div className="beforeafter_dta_right">
              <p>
                Our smile gallery showcases the amazing transformations we’ve
                accomplished for our patients, highlighting the power of a
                beautiful smile. From whitening to orthodontics, we offer a
                range of tailored solutions to help you achieve your dream
                smile. Explore our gallery and discover how we can help you
                transform your smile today!
              </p>
              <Link to="/services">
                <button
                  className="btn_fill"
                  style={{ backgroundColor: "var(--pink)" }}
                >
                  More Services
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeforeAfterSmile;
