import React from "react";
import PaymentList from "../../../components/PaymentList";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollection } from "../../../hooks/useCollection";

import AddBookingIcon from "../../../components/AddBookingIcon";
import NotificationIcon from "../../../components/NotificationIcon";
import "./PGCustomerPayment.css";

const PGCustomerPayments = () => {
  const { user } = useAuthContext();
  const { documents: bookingdocuments, error: bookingerror } = useCollection(
    "bookings",
    ["createdBy", "==", user.uid]
  );
  return (
    <>
      <div className="paybanner">
        <img
          src="./assets/img/paybanner.png"
          className="d_desktop"
          alt="desktop pay icon"
        />
        <img
          src="./assets/img/mobilepaybanner.png"
          className="d_mobile"
          alt="mobile pay icon"
        />
      </div>
      <AddBookingIcon></AddBookingIcon>
      <NotificationIcon></NotificationIcon>
      <div className="">
        <div className="container-fluid">
          {bookingdocuments && (
            <PaymentList bookingdocuments={bookingdocuments} />
          )}
        </div>
      </div>
    </>
  );
};

export default PGCustomerPayments;
