import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useFirestore } from "../hooks/useFirestore";
import { timestamp } from "../firebase/config";
import { useCollection } from "../hooks/useCollection";
import { useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { format, addDays } from "date-fns";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./SetupSlots.css";
import { useDocument } from "../hooks/useDocument";
import TextField from "@mui/material/TextField";

// START CODE FOR CHANGE DEFAULT AND CUSTOM TABS CHANGE
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// END CODE FOR CHANGE DEFAULT AND CUSTOM TABS CHANGE
const SetupSlots = ({ productId }) => {
  const { addDocument, deleteDocument, updateDocument, response } =
    useFirestore("slots");

  const [selectedSlotDate, setSlotDate] = useState(new Date());
  const [selectedStartTime, setStartTime] = useState("");
  const [selectedEndTime, setEndTime] = useState("");
  const [slotAddedMessage, setSlotAddedMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false);
  const [noSlotDate, setNoSlotDate] = useState(false);
  const [selectedSlotTypeOption, setSlotTypeOption] = useState("");

  const selectedDate = (e) => {
    setSlotDate(e);
  };
  const startTime = (e) => {
    setStartTime(e);
    setIsStartTimeSelected(true); // Set flag to true when StartTime is selected
  };
  const endTime = (e) => {
    setEndTime(e);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        const hour = `${i < 10 ? "0" : ""}${i}`;
        const minute = `${j < 10 ? "0" : ""}${j}`;
        options.push(`${hour}:${minute}`);
      }
    }
    return options;
  };

  // Filter end time options based on selected start time
  const generateEndTimeOptions = () => {
    const options = [];
    const startIndex = generateTimeOptions().indexOf(selectedStartTime);
    if (startIndex !== -1) {
      options.push(...generateTimeOptions().slice(startIndex + 1));
    }
    return options;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if noSlotDate is not selected and neither start time nor end time is selected
    if (
      !noSlotDate &&
      (!selectedStartTime || !selectedEndTime || !selectedSlotTypeOption)
    ) {
      let message = "";
      if (!selectedStartTime && selectedEndTime) {
        message += "Please select start time";
      }
      if (selectedStartTime && !selectedEndTime) {
        message += "Please select end time";
      }
      if (selectedStartTime && selectedEndTime && !selectedSlotTypeOption) {
        message += "Please select Slot type is Singal or Multi";
      }
      if (!noSlotDate && !selectedStartTime && !selectedEndTime) {
        message += "Please select no slots option or both start and end times";
      }
      setSlotAddedMessage(message);
      return; // Exit the function early without adding the slot
    }
    const selectedDate = new Date(selectedSlotDate);

    const slot = {
      booked: false,
      date: selectedDate,
      slotType: selectedSlotTypeOption,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      noSlot: noSlotDate,
      productId: productId,
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(slot);

    // Set message to display slot added on selected date with start and end time
    const startTimeString = selectedStartTime ? ` at ${selectedStartTime}` : "";
    const endTimeString = selectedEndTime ? ` until ${selectedEndTime}` : "";
    setSlotAddedMessage(
      `Your slot has been added on ${selectedDate.toDateString()}${startTimeString}${endTimeString}`
    );
  };

  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const dateOptions = [currentDate, tomorrow];

  const { user } = useAuthContext();
  const [bookingDate, setBookingDate] = useState(new Date());
  const [editableStartTime, setEditableStartTime] = useState("");
  const [editableEndTime, setEditableEndTime] = useState("");
  const [editableFields, setEditableFields] = useState({});

  const handleEditableFieldChange = (outerIndex, innerIndex, field, value) => {
    setEditableFields((prev) => ({
      ...prev,
      [`${outerIndex}-${innerIndex}-${field}`]: value,
    }));
  };

  const { documents: slots, errors: slotsErrors } = useCollection("slots", [
    "productId",
    "==",
    productId,
  ]);

  // Assuming slots has a `data` field which is an array
  const defaultSlots =
    slots &&
    slots.filter(
      (slot) => slot.slotType === "default" && slot.data && slot.data.length > 0
    );

  const [timeSlots, setTimeSlots] = useState([]);
  // Initialize time slots state when default slots are available
  useEffect(() => {
    // Check if timeSlots state is already populated
    if (timeSlots.length === 0 && defaultSlots && defaultSlots.length > 0) {
      const initialTimeSlots = defaultSlots.map((slotTiming) => ({
        data: slotTiming.data.map((slot) => ({
          startTime: slot.startTime,
          endTime: slot.endTime,
        })),
      }));
      setTimeSlots(initialTimeSlots);
    }
  }, [defaultSlots]); // Add defaultSlots as a dependency

  // Function to update time slot values
  const updateTimeSlot = async (outerIndex, innerIndex, field, value) => {
    console.log("Updating time slot:", outerIndex, innerIndex);
    const updatedTimeSlots = [...timeSlots];
    console.log("Updated time slots:", updatedTimeSlots);
    if (outerIndex >= 0 && outerIndex < defaultSlots.length) {
      if (updatedTimeSlots[outerIndex] && updatedTimeSlots[outerIndex].data) {
        console.log("defaultSlots:", defaultSlots);
        console.log("updatedTimeSlots:", updatedTimeSlots);
        updatedTimeSlots[outerIndex].data[innerIndex][field] = value;
        setTimeSlots(updatedTimeSlots); // Update the state with the new time slots
        try {
          // Update the document in Firestore
          await updateDocument(defaultSlots[outerIndex].id, {
            data: updatedTimeSlots[outerIndex].data,
          });
          console.log("Document updated successfully");
        } catch (error) {
          console.error("Error updating document:", error);
        }
      } else {
        console.error("Data property is undefined or null.");
      }
    } else {
      console.error("Outer index out of bounds:", outerIndex);
    }
  };

  const { document: dbSlotsDocuments, errors: dbSlotsError } = useDocument(
    "settings",
    "slots_holiday"
  );

  const dbSlotsDates =
    dbSlotsDocuments &&
    dbSlotsDocuments.date.map((timestamp) => {
      const date = new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      );
      return format(date, "yyyy-MM-dd");
    });

  let bookingTimestamp = "";

  bookingTimestamp = bookingDate.toDateString();

  // CODE FOR FETCH SLOTS OF EVERY DATE FOR TABLE
  // Function to generate date options for the next 7 days
  const generateNext7Days = (page) => {
    const startDateIndex = (page - 1) * 7; // Calculate the start index of dates for the given page
    const next7DaysForPage = [];
    for (let i = 0; i < 7; i++) {
      const date = addDays(new Date(), startDateIndex + i);
      next7DaysForPage.push(date);
    }
    return next7DaysForPage;
  };

  const handleDeleteSlot = async () => {
    try {
      // Assuming slots is an array of slot documents
      if (slots && slots.length > 0) {
        // Access the first slot document's ID
        const firstSlotId = slots[0].id; // Adjust this according to your document structure
        // Now you can delete the document
        await deleteDocument(firstSlotId);
      } else {
      }
    } catch (error) {
      console.error("Error deleting slot document:", error);
      // Handle the error as needed
    }
  };

  // Generate next 7 days
  const next7Days = generateNext7Days(currentPage);

  // Function to handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getBookedValueForDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");

    const slotsForDate =
      slots &&
      slots.filter((slot) => {
        return (
          slot.date &&
          format(slot.date.toDate(), "yyyy-MM-dd") === formattedDate
        );
      });

    const startTimes =
      slotsForDate && slotsForDate.map((slot) => slot.startTime);
    const endTimes = slotsForDate && slotsForDate.map((slot) => slot.endTime);
    return { startTimes, endTimes };
  };

  // NO SLOT ON SELECTED DATE
  const noSlot = (e) => {
    if (e.target.checked) {
      setNoSlotDate(true);
      setStartTime(""); // Set selectedStartTime to empty
      setEndTime(""); // Set selectedEndTime to empty
    } else {
      setNoSlotDate(false);
    }
  };

  // CODE FOR SINGAL AND MULTI SLOTS
  const handleChange = (e) => {
    setSlotTypeOption(e.target.value);
  };

  // START CODE FOR CHANGE DEFAULT AND CUSTOM TABS CHANGE
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // END CODE FOR CHANGE DEFAULT AND CUSTOM TABS CHANGE

  return (
    <div className="container">
      <div className="row d-flex align-items-center justify-content-center setslot_div_doctor_parent">
        <div className="section-title">
          <p>Schedule Slots</p>
          <h2>Add slots</h2>
        </div>
        <div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Default" {...a11yProps(1)} />
                <Tab label="Custom Date" {...a11yProps(0)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <div>
                <p className="btn-dont-click-setup-slot">
                  👉 You can set default values for 4 slots in the input below,
                  all days except for holidays and manually set dates will be
                  shown.
                </p>
              </div>
              <br />

              <div className="slots-container">
                {defaultSlots &&
                  defaultSlots.map((slotTiming, outerIndex) => (
                    <div key={outerIndex} className="slot-group">
                      {slotTiming.data &&
                        slotTiming.data.map((map, innerIndex) => (
                          <div key={innerIndex} className="slot-item">
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": {
                                  m: 1,
                                  width: "130px",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <div>
                                <TextField
                                  label="Start Time"
                                  id={`startTime-${outerIndex}-${innerIndex}`}
                                  defaultValue={map.startTime}
                                  size="small"
                                  value={
                                    editableFields[
                                      `${outerIndex}-${innerIndex}-startTime`
                                    ] || map.startTime
                                  }
                                  onChange={(e) => {
                                    handleEditableFieldChange(
                                      outerIndex,
                                      innerIndex,
                                      "startTime",
                                      e.target.value
                                    );
                                    updateTimeSlot(
                                      outerIndex,
                                      innerIndex,
                                      "startTime",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </Box>
                            <span>-</span>
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": {
                                  m: 1,
                                  width: "130px",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <div>
                                <TextField
                                  label="End Time"
                                  id={`endTime-${outerIndex}-${innerIndex}`}
                                  defaultValue={map.endTime}
                                  size="small"
                                  value={
                                    editableFields[
                                      `${outerIndex}-${innerIndex}-endTime`
                                    ] || map.endTime
                                  }
                                  onChange={(e) => {
                                    handleEditableFieldChange(
                                      outerIndex,
                                      innerIndex,
                                      "endTime",
                                      e.target.value
                                    );
                                    updateTimeSlot(
                                      outerIndex,
                                      innerIndex,
                                      "endTime",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </Box>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <form
                onSubmit={handleSubmit}
                className="row d-flex align-items-center justify-content-center"
              >
                <div className="col-8 col-md-3 d-flex align-items-center justify-content-center">
                  <input
                    required
                    type="date"
                    onChange={(e) => selectedDate(e.target.value)}
                    className="setup-slot-canedr"
                    min={format(new Date(), "yyyy-MM-dd")} // Set the minimum value to today's date
                  />
                </div>
                <div className="col-4 col-md-2 d-flex align-items-center justify-content-center">
                  <p className="start_end_slots_text">No Slots :</p> &nbsp;
                  <input type="checkbox" onClick={noSlot}></input>
                </div>
                <div className="col-6 col-md-2 d-flex align-items-center justify-content-center">
                  <p className="start_end_slots_text">Start Time :</p>&nbsp;
                  <select
                    required
                    onChange={(e) => startTime(e.target.value)}
                    className="setup-slot-canedr"
                    disabled={noSlotDate && noSlotDate}
                  >
                    {generateTimeOptions().map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 col-md-2 d-flex align-items-center justify-content-center">
                  <p className="start_end_slots_text">End Time :</p>&nbsp;
                  <select
                    onChange={(e) => endTime(e.target.value)}
                    className="setup-slot-canedr"
                    required={!noSlotDate && isStartTimeSelected} // Make end time required if noSlotDate is not selected and start time is selected
                    disabled={
                      !isStartTimeSelected || (noSlotDate && noSlotDate)
                    } // Disable EndTime if StartTime is not selected
                  >
                    {generateEndTimeOptions().map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                  <div className="radio-container">
                    <label
                      className={
                        selectedSlotTypeOption == "singalslot"
                          ? "radio_btn_checked"
                          : "service_details_radion_btn"
                      }
                    >
                      <input
                        type="radio"
                        name="option"
                        value="singalslot"
                        className="radio-button"
                        onClick={handleChange}
                      />
                      <div className="d-flex align-items-center">
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "21px" }}
                        >
                          {selectedSlotTypeOption == "singalslot"
                            ? "done"
                            : "close"}
                        </span>
                        <div>
                          <p>Singal</p>
                        </div>
                      </div>
                    </label>
                    <label
                      className={
                        selectedSlotTypeOption === "multislot"
                          ? "radio_btn_checked"
                          : "service_details_radion_btn"
                      }
                    >
                      <input
                        type="radio"
                        name="option"
                        value="multislot"
                        className="radio-button"
                        onClick={handleChange}
                      />
                      <div className="d-flex align-items-center">
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "21px" }}
                        >
                          {selectedSlotTypeOption == "singalslot"
                            ? "close"
                            : "done"}
                        </span>
                        <div>
                          <p>Multi</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <h5 style={{ color: "var(--pink)", textAlign: "center" }}>
                  {slotAddedMessage}
                </h5>
                <button
                  type="submit"
                  className="btn_fill setup-slot-add-slot-btn"
                  style={{ margin: "20px 0px" }}
                >
                  Add Slot
                </button>
              </form>
            </CustomTabPanel>
          </Box>
        </div>

        <div className="col-md-12">
          <div
            className="bookpage_date_right_div"
            style={{ margin: "20px 0px" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time Slots</th>
                </tr>
              </thead>
              <tbody>
                {next7Days.map((date, index) => {
                  const { startTimes, endTimes } = getBookedValueForDate(date);
                  const currentDateFormatted = format(date, "yyyy-MM-dd");

                  // Check if the date is present in dbSlotsDocuments.date array
                  const isHoliday =
                    dbSlotsDates && dbSlotsDates.includes(currentDateFormatted);

                  // Check if the day is present in dbSlotsDocuments.day array
                  const isDayHoliday =
                    dbSlotsDocuments &&
                    dbSlotsDocuments.day &&
                    dbSlotsDocuments.day.includes(
                      format(date, "EEE").toLowerCase()
                    );

                  return (
                    <tr key={index}>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: `${format(date, "d MMM")}<br/>${format(
                            date,
                            "EEE"
                          )}`,
                        }}
                      ></td>
                      <td>
                        {startTimes && startTimes.length > 0
                          ? startTimes.map((startTime, i) =>
                              startTime !== "" && endTimes[i] !== "" ? (
                                <button
                                  key={i}
                                  className={
                                    slots[i].booked
                                      ? "booked_true_slot_class"
                                      : "weekly_setup_slot_table_slots"
                                  }
                                >
                                  {`${startTime}-${endTimes[i]}`} &nbsp;
                                  {slots[i].booked === false && (
                                    <span
                                      className="material-symbols-outlined"
                                      onClick={() => handleDeleteSlot(i)}
                                    >
                                      delete
                                    </span>
                                  )}
                                </button>
                              ) : (
                                <p key={i}>
                                  No upcoming slots available on this date.
                                </p>
                              )
                            )
                          : isHoliday || isDayHoliday
                          ? "Today is holiday"
                          : defaultSlots &&
                            defaultSlots.map((slot, index) => (
                              <div key={index}>
                                {slot.data &&
                                  slot.data.map((slotData, dataIndex) => (
                                    <button
                                      key={dataIndex}
                                      type="button"
                                      className="half-hour-button"
                                    >
                                      {slotData.startTime} - {slotData.endTime}
                                    </button>
                                  ))}
                              </div>
                            ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="d-flex justify-content-center mt-4 mb-4">
              <Stack spacing={2}>
                <Pagination
                  count={4} // Assuming you have 10 pages
                  variant="outlined"
                  shape="rounded"
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <div className="col-12 col-md-auto d-flex align-items-center justify-content-start">
                <div>
                  <button
                    type="button"
                    className="half-hour-button-demo"
                    style={{ cursor: "none" }}
                  >
                    01.30-02.00
                  </button>
                </div>
                <div>
                  <p className="btn-dont-click-setup-slot">
                    👉 For Green buttons: This time slot available for booking.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-auto d-flex align-items-center justify-content-start">
                <div>
                  <button
                    className="booked-status-true-setupslot-demo"
                    style={{ cursor: "none" }}
                  >
                    09.30-10.00
                  </button>
                </div>
                <div>
                  <p className="btn-dont-click-setup-slot">
                    👉 For grey buttons: This time slot is already booked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupSlots;
