import React from "react";
// import AddBookingIcon from "../../components/AddBookingIcon";
// import NotificationIcon from "../../components/NotificationIcon";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// component
// import BottomRightFixedIcon from "../../Components/BottomRightFixedIcon";

// css
import "./Faq.css";

const Faq = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // Scroll to the top of the page whenever the location changes end
  return (
    <div>
      {/* <AddBookingIcon />
      <NotificationIcon /> */}

      <div className="pg_contact_us">
        <div className="banner relative">
          <img
            src="./assets/img/faqbanner.jpeg"
            alt="Banner 7"
            className="faq_banner"
          />
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">FAQ Overview Simplified</h3>
                <h2 className="services_banner_heading">Answered Here.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dental FAQ start */}
      <section className="faq sect_padding">
        <div className="container">
          <div className="faq_section_single">
            <div className="section_title">
              <h3>About Dental</h3>
            </div>
            <div class="accordion" id="a1accordion_section">
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseOne"
                    aria-expanded="true"
                    aria-controls="a1collapseOne"
                  >
                    How often should I get a dental cleaning?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="a1headingOne"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Professional cleanings conducted every six months are
                    important for overall dental health, even for those who
                    brush their teeth thoroughly twice a day and floss
                    regularly. But people with high risk of gum disease or tooth
                    decay may need to visit the dentist every three months.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseTwo"
                    aria-expanded="false"
                    aria-controls="a1collapseTwo"
                  >
                    I’m interested in changing the shape of my teeth. What
                    options are available?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingTwo"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Smoothing the tooth, fillings, veneers, crowns or
                    orthodontics are option to consider. A consultation with
                    your dentist for cosmetic dentistry is the best place to
                    start to discuss the correct treatment plan for you.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseThree"
                    aria-expanded="false"
                    aria-controls="a1collapseThree"
                  >
                    What if I crack my tooth?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingThree"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    For a crack tooth, immediately rinse the mouth with warm
                    water to clean the area. Put cold compresses on the face to
                    keep any swelling down. See your dentist as soon as
                    possible.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseFour"
                    aria-expanded="false"
                    aria-controls="a1collapseFour"
                  >
                    Why do my gums bleed?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingFour"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Bleeding gums commonly caused due to deposits on the surface
                    of teeth and gums which leads to red swollen and fragile
                    gums. Treatment of bleeding gums at an early stage can
                    prevent the advancement to severe gum infection.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseFive"
                    aria-expanded="false"
                    aria-controls="a1collapseFive"
                  >
                    Why do I sometimes have bad breath?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingFive"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Bad breath generally comes from improper oral hygiene. The
                    most common culprit are the remaining food particles in your
                    teeth, smoking and tooth decay. To control bad breath the
                    simplest advice is to brush and floss regularly. If you have
                    questions or concern about bad breath, be sure to bring them
                    up with your dentist.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseSix"
                    aria-expanded="false"
                    aria-controls="a1collapseSix"
                  >
                    How can I whiten my teeth?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingSix"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    The teeth whitening procedure makes teeth look visually
                    brighter. It can performed by dental professional in the
                    clinic or by individuals themselves at home. It is
                    recommended to take a dentist advice as bleaching may not be
                    suitable for all types of discoloration.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseSeven"
                    aria-expanded="false"
                    aria-controls="a1collapseSeven"
                  >
                    I have sensitive teeth .What can I do?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingSeven"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    You can try using tooth paste specifically designed for
                    sensitive teeth. Avoid acidic foods and drinks. Grinding
                    your teeth can also increase sensitivity and a mouth guard
                    can help. Speak to your dentist if the pain is critical.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingEight">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseEight"
                    aria-expanded="false"
                    aria-controls="a1collapseEight"
                  >
                    When should a child first be seen by a dentist?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseEight"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingEight"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    It is generally recommended that an infant be seen by a
                    dentist by one year of age or within six months after the
                    first tooth comes in.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingNine">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseNine"
                    aria-expanded="false"
                    aria-controls="a1collapseNine"
                  >
                    Does cleaning of teeth make them loose?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseNine"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingNine"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Teeth will not become loose because of the cleaning
                    procedure. The cleaning procedure helps to remove food
                    debris around the teeth and stop the gum from bleeding. The
                    deposits if not removed periodically (once in every six
                    months)can cause loosening of teeth.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingTen">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseTen"
                    aria-expanded="false"
                    aria-controls="a1collapseTen"
                  >
                    What are my options for replacing a missing tooth?{" "}
                  </button>
                </h2>
                <div
                  id="a1collapseTen"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingTen"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    If missing tooth is not replaced the adjacent teeth start to
                    shift in this empty space. The opposing tooth also starts
                    growing into the space. The option of replacing missing
                    teeth is dental implants, crowns and bridges, removable
                    partial denture and complete denture.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Dental FAQ End */}

      {/* Homeopathy FAQ Start */}
      <section className="faq sect_padding">
        <div className="container">
          <div className="faq_section_single">
            <div className="section_title">
              <h3>About Homeopathy</h3>
            </div>
            <div class="accordion" id="a1accordion_section">
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseOne"
                    aria-expanded="true"
                    aria-controls="a1collapseOne"
                  >
                    What is homeopathy Homeopathy that focus treatment?
                  </button>
                </h2>
                <div
                  id="a1collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="a1headingOne"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Homeopathy is a holistic system of medicine that focus on
                    you body's own healing ability with personalied medicienes.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseTwo"
                    aria-expanded="false"
                    aria-controls="a1collapseTwo"
                  >
                    Is homeopathy only for chronic health issues?
                  </button>
                </h2>
                <div
                  id="a1collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingTwo"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    No, homeopathy can treat acute conditions effectively like
                    cough, Cold, diarrhea, fever etc.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseThree"
                    aria-expanded="false"
                    aria-controls="a1collapseThree"
                  >
                    Can I use homeopathy treatment a long side Conventional
                    treatment?
                  </button>
                </h2>
                <div
                  id="a1collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingThree"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Yes, homeopathy can be used along other types of treatment.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseFour"
                    aria-expanded="false"
                    aria-controls="a1collapseFour"
                  >
                    Is homeopathy same as herbal medicine?
                  </button>
                </h2>
                <div
                  id="a1collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingFour"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    No, both are two different of modes of treatment Homeopathy
                    follows unique methods for the prepration of medicines.
                    Medicines are prepared from other sources like minerals,
                    animal products etc.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseFive"
                    aria-expanded="false"
                    aria-controls="a1collapseFive"
                  >
                    Does one have to take homeopathy treatment for a long time
                    in chronic illiness?
                  </button>
                </h2>
                <div
                  id="a1collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingFive"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    The duration of Treatment depends on various factors like
                    the nature if the discase, chronicity, general health of the
                    patient etc.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseSix"
                    aria-expanded="false"
                    aria-controls="a1collapseSix"
                  >
                    Can patients with diabetes take homeopathy medicines?
                  </button>
                </h2>
                <div
                  id="a1collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingSix"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Yes, infact homeopathy Can treat diabetes.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseSeven"
                    aria-expanded="false"
                    aria-controls="a1collapseSeven"
                  >
                    Is there any food restrictions during the treatment ?
                  </button>
                </h2>
                <div
                  id="a1collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingSeven"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Dutary restrictions are advised for the disease Condition
                    Other than that you can take the medicines 30 minutes before
                    or 30 minutes after eating food.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingEight">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseEight"
                    aria-expanded="false"
                    aria-controls="a1collapseEight"
                  >
                    Who can take homeopathy treatment ?
                  </button>
                </h2>
                <div
                  id="a1collapseEight"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingEight"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Homeopathy medicines are safe to be used by babies,
                    toddlers, kids, adults and older age groups. It Can be
                    prescribed during pregnancy & lactation periods.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingNine">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseNine"
                    aria-expanded="false"
                    aria-controls="a1collapseNine"
                  >
                    Are there any Side effects of homeopathy medications?
                  </button>
                </h2>
                <div
                  id="a1collapseNine"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingNine"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    No, there are no side effects as medicines are prescribed in
                    small doses.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="a1headingTen">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#a1collapseTen"
                    aria-expanded="false"
                    aria-controls="a1collapseTen"
                  >
                    Is homeopathy approved by WHO ?
                  </button>
                </h2>
                <div
                  id="a1collapseTen"
                  class="accordion-collapse collapse"
                  aria-labelledby="a1headingTen"
                  data-bs-parent="#a1accordion_section"
                >
                  <div class="accordion-body">
                    Yes, infact according to WHO homeopathyis the second largest
                    system of medition in the world used by millions worldwide.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Homeopathy FAQ End */}
    </div>
  );
};

export default Faq;
