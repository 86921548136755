import React, { useState } from "react";
import "./Popup.css"; // Import the CSS file

export default function Popup(props) {
  const [inputValue, setInputValue] = useState("");

  const handlePopup = (action) => {
    if (action === "CANCEL") {
      props.setPopupReturn(false); // Pass false to indicate rejection was cancelled
    } else if (action === "CONFIRM") {
      props.setPopupReturn(true, inputValue); // Pass true and the rejection reason when confirmed
    }
    props.setShowPopupFlag(false);
  };

  return (
    <div className={props.showPopupFlag ? "popup-div open" : "popup-div"}>
      <div className="popup-div-content">
        <p className="popup-div-message">{props.msg}</p>
        {props.inputType === "text" && (
          <textarea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter reason..."
            className="popup-div-textarea"
          />
        )}
        <div className="popup-div-buttons">
          <button
            onClick={() => handlePopup("CONFIRM")}
            className="popup-div-btn popup-div-confirm-btn"
          >
            CONFIRM
          </button>
          <button
            onClick={() => handlePopup("CANCEL")}
            className="popup-div-btn popup-div-cancel-btn"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
}
