import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

// import { getFunctions, httpsCallable } from 'firebase/functions'

// DENTAMAX DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyBkzycugPmP-Rh8Dlpk7m_Z8hRDFguHLYI",
//   authDomain: "dentamax-dev.firebaseapp.com",
//   projectId: "dentamax-dev",
//   storageBucket: "dentamax-dev.appspot.com",
//   messagingSenderId: "218073240812",
//   appId: "1:218073240812:web:1cb031aab36655b16801c9",
// };

// DENTAMAX PROD
const firebaseConfig = {
  apiKey: "AIzaSyDIkpc9Ly_vswCmfV1MEAjixPfXwU6kXrQ",
  authDomain: "dentamax-prod.firebaseapp.com",
  projectId: "dentamax-prod",
  storageBucket: "dentamax-prod.appspot.com",
  messagingSenderId: "922699487566",
  appId: "1:922699487566:web:e66f01b1a582c41028261f",
  measurementId: "G-9BNRRL99T0",
};

// initialize firebase
const app = firebase.initializeApp(firebaseConfig);

// initialize Services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectAuthObj = firebase.auth;
const projectStorage = firebase.storage();
const projectFunctions = firebase.functions();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export {
  projectFirestore,
  projectAuth,
  projectAuthObj,
  projectStorage,
  projectFunctions,
  timestamp,
};
