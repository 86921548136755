import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Popup from "./Popup";
import AddMedia from "./AddMedia";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";
import { useFirestore } from "../hooks/useFirestore";
import { useCollection } from "../hooks/useCollection";

const OfferBanners = ({ category, section }) => {
  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
    "media",
    ["category", "==", "offers"]
  );

  const offerImagesDocuments =
    dbMediaDocuments &&
    dbMediaDocuments.filter((item) => item.mediaType.toUpperCase() === "IMAGE");

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      if (productIdToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Offer deleted successfully");
      } else {
        console.error("No productId specified for deletion");
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div>
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this offer?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      {offerImagesDocuments && offerImagesDocuments.length > 0 && (
        <div className="offers-section-title">
          <p>Todays- Limited-Period Offers</p>
          <h2>Exclusive Packages To Maximize Your Savings </h2>
        </div>
      )}
      <div className="offer_banner_parent">
        {offerImagesDocuments && offerImagesDocuments.length > 0 && (
          <OwlCarousel
            items={1}
            loop
            dots={false}
            nav={false}
            autoplay={true} // Enable autoplay
            autoplayTimeout={3000}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 3,
              },
            }}
          >
            {offerImagesDocuments &&
              offerImagesDocuments
                .filter((media) =>
                  isAdmin
                    ? !section || media.section == section
                    : media.status == "active" &&
                      (!section || media.section == section)
                )
                .map((offerImage) => (
                  <div key={offerImage.id}>
                    <div style={{ margin: "10px 10px 0px 10px" }}>
                      <img
                        src={offerImage.mediaUrl}
                        alt={`Offer ${offerImage.id}`}
                      />
                    </div>{" "}
                    {isAdmin && (
                      <>
                        <div style={{ margin: "0px 0px 20px 15px" }}>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex align-items-center mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "white" }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={offerImage.status == "active"}
                                    onChange={() =>
                                      handleToggleChange(
                                        offerImage.id,
                                        offerImage.status
                                      )
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "white" }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 d-flex justify-content-center mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() =>
                                    handleDeleteProduct(offerImage.id)
                                  }
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </>
                    )}
                  </div>
                ))}
          </OwlCarousel>
        )}
        {offerImagesDocuments && offerImagesDocuments.length > 0 && (
          <h6>**Terms and conditions apply.</h6>
        )}
        <AddMedia
          category="offers"
          pageName="home"
          section="offers"
          addMoreVideosButton={false}
        />
      </div>
    </div>
  );
};

export default OfferBanners;
