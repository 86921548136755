import { useNavigate } from "react-router-dom";
// import { useDocument } from '../../hooks/useDocument'
import { useAuthContext } from "../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { useLogout } from "../../hooks/useLogout";
import Popup from "../../components/Popup";
import { projectFirestore } from "../../firebase/config";
import PhoneInput from "react-phone-input-2";
import {
  projectStorage,
  projectAuth,
  projectAuthObj,
} from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import { useImageUpload } from "../../hooks/useImageUpload";
import { Form, Button, Alert } from "react-bootstrap";

import { Link } from "react-router-dom";
// for cursor when edit phone and name
import { useRef } from "react";
// for cursor when edit phone and name

// styles
import "./PGProfile.css";

export default function PGProfile() {
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const [userFullName, setUserFullName] = useState(user.fullName);
  const [changeNumberScroller, setchangeNumberScroller] = useState(false);

  const [userPhoneNumber, setUserPhoneNumber] = useState(user.phoneNumber);
  const [changeNumberDisplay, setchangeNumberDisplay] = useState(false);

  const { updateDocument, response } = useFirestore("users");
  const { documents: bookingdocuments, error: bookingerror } =
    useCollection("bookings");
  const { documents: productsdocuments, error: productserror } =
    useCollection("products");
  const isAdmin = user && user.role === "admin";
  const hasActiveProducts =
    productsdocuments &&
    productsdocuments.some((product) => product.status === "active");

  const userBookings =
    user && user.role === "admin"
      ? bookingdocuments // If user is admin, show all bookings
      : bookingdocuments
      ? bookingdocuments.filter((document) => {
          return document.createdBy == user.uid; // Show only bookings created by the logged-in user
        })
      : null;

  const [formError, setFormError] = useState(null);
  const { logout, isPending } = useLogout();
  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [error, setError] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const { imgUpload, isImgCompressPending, imgCompressedFile } =
    useImageUpload();
  const [AddRole, setAddRole] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selctedRole, setRole] = useState([]);
  const [selectedRoleIndex, setSelectedRolesIndex] = useState(null);
  const [isAddRole, setIsAddRole] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);

  const handleSendVerificationCode = async (e) => {
    e.preventDefault();
    setError("");
    // console.log("phone:", phone);
    if (
      newPhoneNumber === "" ||
      newPhoneNumber === undefined ||
      newPhoneNumber.length < 10
    ) {
      return setError("Please enter valid Phone Number");
    }
    try {
      // hide buttons
      // id_sendotpButton
      document.getElementById("id_sendotpButton").style.display = "none";

      const phoneProvider = new projectAuthObj.PhoneAuthProvider();
      // console.log('phoneProvider: ', phoneProvider)
      const recaptchaVerifier = new projectAuthObj.RecaptchaVerifier(
        "recaptcha-container"
      );
      // console.log('recaptchaVerifier: ', recaptchaVerifier)
      var newPhoneNumberWithPlus = "+" + newPhoneNumber;
      const id = await phoneProvider.verifyPhoneNumber(
        newPhoneNumberWithPlus,
        recaptchaVerifier
      );
      // console.log('id: ', id)
      setVerificationId(id);

      setchangeNumberScroller(true);
      // alert('Verification code sent!');
    } catch (error) {
      console.error("Error sending verification code:", error.message);
      setError("Error sending verification code");
    }
  };
  const handleChangePhoneNumber = async () => {
    // console.log('In handleChangePhoneNumber')
    try {
      const credential = projectAuthObj.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const currentUser = projectAuth.currentUser;
      await currentUser.updatePhoneNumber(credential);

      // console.log('Phone number updated successfully!')

      //Logout and redirect to login page
      logout();
    } catch (error) {
      // Handle any errors
      console.error("Error changing phone number:", error.message);
      setError("Error changing phone number");
    }
  };

  //Popup Flags
  useEffect(() => {
    if (popupReturn) {
      //Logout
      logout();
    }
  }, [popupReturn]);

  //Popup Flags
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };

  const showDashboard = () => {
    if (user && user === "superadmin") {
      // console.log('in superadmin', user.role)
      navigate("/superadmindashboard");
    }

    if (user && user.role === "admin") {
      // console.log('in admin', user.role)
      navigate("/admindashboard");
    }

    if (user && user.role === "owner") {
      // console.log('in user', user.role)
      navigate("/ownerdashboard");
    }

    if (user && user.role === "tenant") {
      // console.log('in user', user.role)
      navigate("/tenantdashboard");
    }

    if (user && user.role === "executive") {
      // console.log('in user', user.role)
      navigate("/executivedashboard");
    }

    if (user && user.role === "user") {
      // console.log('in user', user.role)
      navigate("/userdashboard");
    }
  };

  const handleFileChange = async (e) => {
    let file = e.target.files[0];

    const compressedImage = await imgUpload(file, 300, 300);

    if (!compressedImage) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!compressedImage.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    let imgUrl = "";
    const thumbnailName = "profile.png";
    if (compressedImage) {
      const uploadPath = `userThumbnails/${user.uid}/${thumbnailName}`;
      const img = await projectStorage.ref(uploadPath).put(compressedImage);
      imgUrl = await img.ref.getDownloadURL();
      // console.log('imgUrl:', imgUrl)
      await updateDocument(user.uid, {
        photoURL: imgUrl,
      });
      // await user.updateProfile({ photoURL: imgUrl })
    } else {
      //if thumbnail is null
      imgUrl =
        "https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2";
    }
  };

  // for edit name
  const [isProfileEdit, setisProfileEdit] = useState(false);
  // for edit name
  // for edit phonenumber
  const [isnProfileEdit, setnisProfileEdit] = useState(false);
  // for edit phonenumber

  // for cursor in fields
  const fullNameInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  // for cursor in fields

  // handel for edit name
  const handleProfileEdit = async (e) => {
    setisProfileEdit(true);
    fullNameInputRef.current.focus();
  };
  const handleProfileSave = async (e) => {
    setisProfileEdit(false);
    // setUserFullName(e.target.value)
    // console.log('e.target.value:', e)
    await updateDocument(user.uid, {
      fullName: userFullName,
    });
  };
  // handel for edit name

  // handel for edit phone number
  const handleProfileEditnumber = async (e) => {
    setnisProfileEdit(true);
    phoneNumberInputRef.current.focus();
  };
  const handleProfileSavenumber = async (e) => {
    setnisProfileEdit(false);
    await updateDocument(user.uid, {
      phoneNumber: userPhoneNumber,
    });
  };

  // handel for edit phone number
  const changeNumberBack = () => {
    // id_sendotpButton
    document.getElementById("id_sendotpButton").style.display = "block";
    setchangeNumberScroller(false);
  };
  const openChangeNumber = () => {
    setchangeNumberDisplay(true);
  };
  const closeChangeNumber = () => {
    setchangeNumberDisplay(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);
  };

  const changePwd = (e) => {
    navigate("/updatepwd");
  };

  // START CODE FOR ROLE
  const handleAddRoleClick = () => {
    setIsAddRole(true);
  };
  const handleSaveAddRole = async () => {
    const lowercaseRole = AddRole.toLowerCase(); // Convert role name to lowercase

    try {
      console.log("New role to add:", lowercaseRole); // Check if the new role is set correctly

      // Get the current roles array from the document
      const currentRoles = (user && user.roles) || [];

      // Add the new option to the array
      const updatedRoles = [...currentRoles, lowercaseRole];

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRoles });

      console.log("Roles updated successfully"); // Log success message

      // Reset the AddRole state and close the input field
      setAddRole("");
      setIsAddRole(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  const handleCancelAddRole = () => {
    setIsAddRole(false);
  };

  // const handleRoleChange = (index) => {
  //   setSelectedRole(!selectedRole);
  //   setIsRoleSelected(true);
  //   setSelectedRolesIndex(index);
  // };
  const handleRoleChange = async (roles) => {
    setSelectedRole(roles);
    setIsRoleSelected(true);
    try {
      // Update the user's role in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ role: roles });

      console.log("User role updated successfully to:", roles); // Log success message
    } catch (error) {
      console.error("Error updating user role:", error);
      // Handle the error as needed
    }
  };

  // Fetch roles array from the document state
  const roles =
    user && user
      ? ((user && user.roles) || []).map((role) => role.toLowerCase())
      : [];
  // delete option
  const handleDeleteRole = async (index) => {
    try {
      // Get the current roles array from the document
      const currentRole = (user && user.roles) || [];

      // Remove the selected option from the array
      const updatedRole = currentRole.filter((_, i) => i !== index);

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRole });
    } catch (error) {
      console.error("Error deleting role:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY ROLE

  // --------------------HTML UI Codebase------------------
  return (
    <div className="pgprofile">
      {/* Popup Component */}
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to logout?"}
      />
      <div
        className={
          changeNumberDisplay
            ? "pop-up-change-number-div open"
            : "pop-up-change-number-div"
        }
      >
        <div className="direct-div">
          <span
            onClick={closeChangeNumber}
            className="material-symbols-outlined close-button"
          >
            close
          </span>
          <span style={{ fontSize: "0.8rem" }}>
            Existing no: {userPhoneNumber}
          </span>
          <h3 style={{ color: "var(--pink)" }}>Enter New Number</h3>

          <div className="sroll-outter-div">
            <div className="sroll-inner-div">
              <div
                className="scroll-div"
                style={{
                  transform: changeNumberScroller
                    ? "translateX(-100%)"
                    : "translateX(0%)",
                }}
              >
                {error && <Alert variant="danger">{error}</Alert>}

                <form
                  action=""
                  onSubmit={handleSendVerificationCode}
                  className="form-w"
                >
                  <div className="fl_form_field change-number-input">
                    <PhoneInput
                      country={"in"}
                      // onlyCountries={['in', 'us']}
                      value={newPhoneNumber}
                      onChange={setNewPhoneNumber}
                      international
                      keyboardType="phone-pad"
                      // countryCallingCodeEditable={false}
                      // countryCodeEditable={false}
                      // disableCountryCode={true}
                      placeholder="Enter New Mobile Number"
                      inputProps={{
                        name: "newPhoneNumber",
                        required: true,
                        autoFocus: true,
                      }}
                    ></PhoneInput>
                  </div>

                  {/* new code */}
                  <div
                    id="id_sendotpButton"
                    className="change-number-button-div"
                  >
                    <button onClick={closeChangeNumber} className="btn_border">
                      Cancel
                    </button>
                    <button className="btn_fill">Send OTP</button>
                  </div>
                </form>
              </div>

              <div
                className="scroll-div"
                style={{
                  transform: changeNumberScroller
                    ? "translateX(-100%)"
                    : "translateX(0%)",
                }}
              >
                <div>
                  <input
                    type="text"
                    placeholder="Enter verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="pgprofile-change-numb-inpt"
                  />

                  {/* new code */}
                  <div className="change-number-button-div">
                    <button onClick={changeNumberBack} className="btn_border">
                      Back
                    </button>
                    <button
                      onClick={handleChangePhoneNumber}
                      className="btn_fill"
                    >
                      Confirm
                    </button>
                  </div>
                  {/* new code */}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: changeNumberScroller ? "none" : "block",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            id="recaptcha-container"
          ></div>
          <div
            style={{
              display: changeNumberScroller ? "none" : "block",
              height: "80px",
            }}
          ></div>
        </div>
      </div>
      <div className="more_contant_parnt_div">
        <div className="container">
          <div className="more-div-big-icon-div">
            {user ? (
              <div className="more-div-big-icon-div-inner pointer">
                {user.role == "customer" ? (
                  <Link to="/customerdashboard">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        dashboard
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Dashboard</h1>
                  </Link>
                ) : user.role == "admin" ? (
                  <Link to="/customerdashboard">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        dashboard
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Dashboard</h1>
                  </Link>
                ) : user.role == "frontdesk" ? (
                  <Link to="/allcustomerbookings">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">person</span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Manage Bookings</h1>
                  </Link>
                ) : user.role == "doctor" ? (
                  <Link to="/calendarbooking">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        free_cancellation
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Calender</h1>
                  </Link>
                ) : null}
              </div>
            ) : (
              <div className="more-div-big-icon-div-inner pointer">
                <Link to="/login">
                  <div style={{ margin: "auto" }}>
                    <span className="material-symbols-outlined">person</span>
                  </div>
                  <h1>Login</h1>
                </Link>
              </div>
            )}

            {user ? (
              <div className="more-div-big-icon-div-inner pointer">
                {user.role == "customer" ? (
                  <Link to="/allcustomerbookings">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        list_alt
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>My Bookings</h1>
                  </Link>
                ) : user.role == "admin" ? (
                  <Link to="/allcustomerbookings">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        list_alt
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Manage Bookings</h1>
                  </Link>
                ) : user.role == "frontdesk" ? (
                  <Link to="">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        account_balance_wallet
                      </span>
                    </div>
                    <h1 style={{ color: "black", textAlign: "center" }}>
                      Wallet
                    </h1>
                  </Link>
                ) : user.role == "doctor" ? (
                  <Link to="/products">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        edit_note
                      </span>
                    </div>
                    <h1 style={{ color: "black", textAlign: "center" }}>
                      Setup Slot
                    </h1>
                  </Link>
                ) : null}
              </div>
            ) : (
              <div className="more-div-big-icon-div-inner pointer">
                <Link to="/login">
                  <div style={{ margin: "auto" }}>
                    <span className="material-symbols-outlined">person</span>
                  </div>
                  <h1 style={{ color: "black" }}>My Account</h1>
                </Link>
              </div>
            )}

            {/* Third icon */}
            {user ? (
              <div className="more-div-big-icon-div-inner pointer">
                {user.role == "customer" ? (
                  <Link to="/contactus">
                    <div style={{ margin: "auto" }}>
                      <span class="material-symbols-outlined">
                        connect_without_contact
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Contact</h1>
                  </Link>
                ) : user.role == "admin" ? (
                  <Link to="/contactus">
                    <div style={{ margin: "auto" }}>
                      <span class="material-symbols-outlined">
                        connect_without_contact
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Contact</h1>
                  </Link>
                ) : user.role == "frontdesk" ? (
                  <Link to="/contactus">
                    <div style={{ margin: "auto" }}>
                      <span class="material-symbols-outlined">
                        connect_without_contact
                      </span>
                    </div>
                    <h1 style={{ textAlign: "center" }}>Contact</h1>
                  </Link>
                ) : user.role == "doctor" ? (
                  <Link to="/products">
                    <div style={{ margin: "auto" }}>
                      <span className="material-symbols-outlined">
                        edit_note
                      </span>
                    </div>
                    <h1 style={{ color: "black", textAlign: "center" }}>
                      Setup Slot
                    </h1>
                  </Link>
                ) : null}
              </div>
            ) : (
              <div className="more-div-big-icon-div-inner pointer">
                <Link to="/login">
                  <div style={{ margin: "auto" }}>
                    <span className="material-symbols-outlined">person</span>
                  </div>
                  <h1 style={{ color: "black" }}>My Account</h1>
                </Link>
              </div>
            )}
          </div>

          <div className="profile_pg">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-12">
                <div className="pp_card">
                  <div className="title">My Profile</div>
                  <div className="mydetail">
                    <div className="left">
                      <div className="my_pic">
                        <img
                          src={user.photoURL}
                          className="user_img"
                          alt="pofile img 13"
                        />
                        <div className="edit_icon pointer">
                          <input
                            type="file"
                            onChange={handleFileChange}
                            id="profile-upload-input"
                            className="profile-upload-input"
                          />

                          <label
                            htmlFor="profile-upload-input"
                            className="profile-upload-label pointer"
                          >
                            <img
                              src="./assets/img/edit_icon.png"
                              alt="profile img 14"
                            />
                          </label>
                        </div>
                      </div>
                      <h3 className="my_name">
                        <div
                          className={`c_name relative ${
                            isProfileEdit
                              ? "cn_editlabel cn_editlabel2"
                              : "cn_label cn_label2"
                          }`}
                        >
                          <input
                            type="text"
                            className="profile-change-name"
                            readOnly={isProfileEdit ? false : true}
                            onChange={(e) => setUserFullName(e.target.value)}
                            value={userFullName}
                            ref={fullNameInputRef}
                            style={{ border: "1px solid red" }}
                          ></input>

                          <div className="edit_bg pointer">
                            <img
                              src="./assets/img/edit_icon.png"
                              alt="edit icon img"
                              className={`ed_icon ${
                                isProfileEdit ? "namenotdone" : "namedone"
                              }`}
                              onClick={handleProfileEdit}
                              style={{ width: "16px" }}
                            />

                            <img
                              src="./assets/img/done.png"
                              alt="done icon img"
                              className={`ed_icon ${
                                isProfileEdit ? "namedone" : "namenotdone"
                              }`}
                              onClick={handleProfileSave}
                            />
                          </div>
                        </div>
                      </h3>
                    </div>
                    <div className="right">
                      <div className="profile-page-content profile-change-phone">
                        <h6>Phone Number</h6>
                        <h4 className="relative">
                          <div
                            className={`c_name ${
                              isnProfileEdit ? "cn_editlabel" : "cn_label"
                            }`}
                          >
                            <div className="phone-input-container">
                              <input
                                type="number"
                                maxLength={10}
                                readOnly={!isnProfileEdit}
                                onChange={(e) =>
                                  setUserPhoneNumber(e.target.value)
                                }
                                value={userPhoneNumber}
                                ref={phoneNumberInputRef}
                              />
                              <div
                                className="edit_bg"
                                onClick={openChangeNumber}
                              >
                                <span className="material-symbols-outlined">
                                  edit
                                </span>
                              </div>
                            </div>
                          </div>
                        </h4>
                      </div>
                      {/* CHANGE ROLE START */}
                      {/* {roles.length > 1 && ( */}
                      {user && user.roles.includes("admin") && (
                        <div className="profile-page-content role">
                          <h6>Role</h6>
                          <div className="radio-container">
                            {roles &&
                              roles.map((roles, index) => (
                                <label
                                  key={index}
                                  className={`radio-container ${
                                    selectedRoleIndex === index
                                      ? "radio_btn_checked"
                                      : "service_details_radion_btn"
                                  }`}
                                  style={{
                                    color:
                                      user && user.role === roles
                                        ? "var(--white-color)"
                                        : "inherit",
                                    backgroundColor:
                                      user && user.role === roles
                                        ? "var(--click-color)"
                                        : "inherit",
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="role"
                                      value={roles}
                                      onChange={() => handleRoleChange(roles)}
                                      className="radio-button"
                                    />
                                    <span
                                      className="material-symbols-outlined"
                                      style={{ fontSize: "21px" }}
                                    >
                                      {user && user.role === roles
                                        ? "done"
                                        : "add"}
                                    </span>
                                    {roles}
                                    {user && user.role === "admin" ? (
                                      <span
                                        class="material-symbols-outlined"
                                        onClick={() => handleDeleteRole(index)}
                                      >
                                        close
                                      </span>
                                    ) : null}
                                  </div>
                                </label>
                              ))}
                            {isAddRole ? (
                              <div>
                                <input
                                  type="text"
                                  value={AddRole}
                                  onChange={(e) => setAddRole(e.target.value)}
                                />
                                <div className="d-flex">
                                  <button
                                    className="product_edit_save_btn"
                                    onClick={handleSaveAddRole}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="product_edit_save_btn cancel-btn"
                                    onClick={handleCancelAddRole}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                {user && user.role == "admin" && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={handleAddRoleClick}
                                    style={{ fontSize: "24px" }}
                                  >
                                    add_box
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* )} */}
                      {/* CHANGE ROLE END */}
                      <div className="profile_booking_number_card bglight mobile profile-change-phone">
                        <div className="">
                          <h6>Upcoming Bookings</h6>
                          <h4>
                            {userBookings &&
                              userBookings.filter(
                                (upcoming) => upcoming.status === "upcoming"
                              ).length}
                          </h4>
                        </div>
                        <div className="">
                          <h6>Total Bookings</h6>
                          <h4>{userBookings && userBookings.length}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" profile-change-phone">
                    <div className="profile-page-content d-flex justify-content-center m-2">
                      <button className="btn_fill pointer m-2">
                        <Link to="/gallery" style={{ color: "white" }}>
                          Gallery
                        </Link>
                      </button>
                      {(isAdmin ||
                        (productsdocuments &&
                          productsdocuments.length !== 0 &&
                          hasActiveProducts)) && (
                        <>
                          <button className="btn_fill pointer m-2">
                            <Link to="/products" style={{ color: "white" }}>
                              Appoinment
                            </Link>
                          </button>
                        </>
                      )}
                      <button className="btn_fill pointer m-2">
                        <Link to="/services" style={{ color: "white" }}>
                          Services
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="pp_card rightside">
                <div className="">
                  {user ? (
                    <div className="more-div-big-icon-div-inner pointer">
                      {user.roles == "customer" ? (
                        <div className="myli_single bglight mt-3">
                          <Link to="/products">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    add_shopping_cart
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Add Booking
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      ) : user.roles == "admin" ? null : user.roles ==
                        "frontdesk" ? (
                        <div className="myli_single bglight mt-3">
                          <Link to="/products">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    add_shopping_cart
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Add Booking
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      ) : user.roles == "doctor" ? null : null}
                    </div>
                  ) : (
                    <div className="more-div-big-icon-div-inner pointer">
                      <Link to="/login">
                        <div style={{ margin: "auto" }}>
                          <span className="material-symbols-outlined">
                            person
                          </span>
                        </div>
                        <h1 style={{ color: "black" }}>My Account</h1>
                      </Link>
                    </div>
                  )}

                  <Link to="/allcustomerbookings">
                    <div className="myli_single bglight pointer">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              list_alt
                            </span>
                          </div>
                        </div>
                        <h6>
                          My Bookings
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Link>

                  <div className="myli_single bglight">
                    <Link to="/customerdashboard">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              support
                            </span>
                          </div>
                        </div>
                        <h6>
                          Dashboard
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </Link>
                  </div>

                  <Link to="/notifications">
                    <div className="myli_single bglight pointer ">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              campaign
                            </span>
                          </div>
                        </div>
                        <h6>
                          Notifications
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="pp_card rightside">
                <div className="">
                  <Link to="/services">
                    <div className="myli_single bglight pointer ">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              checklist
                            </span>
                          </div>
                        </div>
                        <h6>
                          Services
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Link>

                  <Link to="/our-team">
                    <div className="myli_single bglight pointer ">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              diversity_1
                            </span>
                          </div>
                        </div>
                        <h6>
                          Team
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Link>
                  {/* <div className="myli_single bglight">
                      <Link to="/contactus">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                support
                              </span>
                            </div>
                          </div>
                          <h6>
                            Help & Support
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div> */}
                  {/* <h6 className="more">More</h6> */}
                  {/* 
                    <div
                      className="myli_single bglight pointer"
                      onClick={changePwd}
                    >
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              lock_open
                            </span>
                          </div>
                        </div>
                        <h6>
                          Change Password
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div> */}
                  <div
                    className="myli_single bglight mt-3 pointer"
                    onClick={showPopup}
                  >
                    <div className="profile-page-content">
                      <div className="icon">
                        <div className="icon_bg">
                          <span class="material-symbols-outlined">logout</span>
                        </div>
                      </div>
                      <h6>
                        Logout
                        <span class="material-symbols-outlined">
                          chevron_right
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
