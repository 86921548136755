import React from "react";
import { useEffect } from "react";
import FooterUpper from "../../components/FooterUpper";
// import AddBookingIcon from "../../components/AddBookingIcon";
// import NotificationIcon from "../../components/NotificationIcon";
import { Link, useLocation } from "react-router-dom";
import "../../components/About.css";
const About = () => {
  const location = useLocation();

  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      {/* <AddBookingIcon />
      <NotificationIcon /> */}

      <div className="pg_contact_us">
        <div className="banner relative">
          <img
            src="./assets/img/privacy-policy-banner.jpg"
            alt="privacy policy img"
          ></img>
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">
                  Our Commitment to Excellence{" "}
                </h3>
                <h2 className="banner_heading">
                  Navigating Success Together: Our Collective Journey{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_data_parent_div">
        <div className="container">
          <div className="row">
            <div className="row-parent">
              <div className="about_img_div">
                <img
                  className="denta_about_img"
                  src="./assets/img/22-logo.webp"
                  alt="logo year 22"
                />
              </div>
              <div className="top_right_div">
                <h2 id="top-heading">Privacy Policy </h2>
                <br />

                <p id="top-para">
                  This Privacy Policy outlines how Dentamax Medical Center,
                  based in Dubai ("we," "our," or "us"), collects, uses,
                  discloses, and protects your personal information in
                  connection with our services. We offer Dental, Dermatology,
                  Homeopathy, Hijama, and other medical services authorized and
                  approved by the Dubai Health Authority (DHA). We are committed
                  to safeguarding your privacy and ensuring the security of your
                  personal data.
                </p>
              </div>
            </div>
            <div className="bottom_text pp_first_heading">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  1. Information We Collect
                </b>
              </h6>
              <p>We may collect the following types of information:</p>
              <br />
              <p>
                <b>1.1. Personal Information:</b> <br />
                This includes your name, contact details (such as email address,
                phone number, and physical address), date of birth, and other
                information you provide when you book an appointment or use our
                services.
              </p>
              <br />
              <p>
                <b>1.2. Medical Information: </b> <br />
                We may collect information about your medical history, symptoms,
                and treatment provided during your visits to our center.
              </p>
              <br />
              <p>
                <b>1.3. Payment Information:</b> <br />
                If you make payments for our services, we may collect payment
                details, such as credit card and debit card information or other
                payment methods.
              </p>
              <br />
              <p>
                <b>1.4. Usage Information:</b> <br />
                We may collect information about how you use our website and
                services, including log data, device information, and browsing
                history.
              </p>
            </div>

            <div className="bottom_text">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  2. How We Use Your Information
                </b>
              </h6>
              <p>We may use your information for the following purposes:</p>
              <br />
              <p>
                <b> 2.1. Providing Services:</b> <br />
                We use your information to provide the Dental, Dermatology,
                Homeopathy, and Hijama services you request.
              </p>
              <br />
              <p>
                <b> 2.2. Appointment and Communication: </b> <br />
                We use your contact details to schedule appointments and
                communicate with you about your healthcare needs.
              </p>
              <br />
              <p>
                <b>2.3. Billing and Payments: </b> <br />
                We process payments and keep records of your financial
                transactions.
              </p>
              <br />
              <p>
                <b> 2.4. Medical Records: </b> <br />
                We maintain medical records as required by law and for your
                treatment.
              </p>
              <br />
              <p>
                <b> 2.5. Improvement and Research:</b> <br />
                We may use data for internal purposes, such as improving our
                services, conducting research, and analyzing trends.
              </p>
            </div>
            <div className="bottom_text">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  3. Disclosure of Your Information
                </b>
              </h6>
              <p>
                We may disclose your information to third parties only for the
                following reasons:
              </p>
              <br />
              <p>
                <b> 3.1. Service Providers: </b> <br />
                We may share your information with third-party service providers
                who assist us in delivering our services.
              </p>
              <br />
              <p>
                <b> 3.2. Legal Requirements:</b> <br />
                We may disclose your information to comply with legal
                obligations or to protect our rights and the safety of others.
              </p>
            </div>
            <div className="bottom_text">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  4. Data Security{" "}
                </b>
              </h6>
              <p>
                We take appropriate measures to secure your personal
                information, but no method of transmission over the Internet or
                electronic storage is 100% secure. We cannot guarantee absolute
                security.
              </p>
            </div>
            <div className="bottom_text">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  5. Your Rights{" "}
                </b>
              </h6>
              <p>
                You have the following rights concerning your personal
                information:
              </p>
              <br />
              <p>
                <b>5.1. Access:</b> <br />
                You can request access to the personal information we hold about
                you.
              </p>
              <br />
              <p>
                <b> 5.2. Correction:</b> <br />
                You may request corrections to inaccuracies in your personal
                information.
              </p>
              <br />
              <p>
                <b>5.3. Deletion:</b> <br />
                You can request the deletion of your personal information,
                subject to legal requirements.
              </p>
              <br />
              <p>
                <b> 5.4. Withdrawal of Consent:</b> <br />
                You can withdraw your consent to the processing of your data.
              </p>
            </div>
            <div className="bottom_text">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                  }}
                >
                  6. Contact Us
                </b>
              </h6>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at
              </p>
              <br />
              <p>
                <b>hello@dentamaxclinic.com</b>
              </p>
              <p>
                <b>Call: +971 42 636 717</b>
              </p>
              <p>
                <b>WhatsApp: +971 54 517 1799</b>
              </p>
            </div>
            <div className="bottom_text">
              <h6 className="subheading">
                <b
                  style={{
                    fontSize: "20px",
                  }}
                >
                  7. Changes to this Policy
                </b>
              </h6>
              <p>
                We may update this Privacy Policy from time to time. We will
                notify you of significant changes through our website or other
                means.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
