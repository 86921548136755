import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../components/Popup";
import { useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { useDocument } from "../../hooks/useDocument";
import { useCollection } from "../../hooks/useCollection";
import { useParams } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { format } from "date-fns";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";

import "./AddBooking.css";
import { useEffect } from "react";
// import { object } from "firebase-functions/v1/storage";

export default function AddBooking() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);
  const dayAfterTomorrow = new Date(currentDate);
  dayAfterTomorrow.setDate(currentDate.getDate() + 2);

  const dateOptions = [tomorrow, dayAfterTomorrow];

  // // Function to format the date as "dd Mon'yy (Day)"
  const formatDateForAvailableSlots = (date) => {
    const dayOfMonth = date.toLocaleDateString(undefined, { day: "2-digit" });
    const month = date.toLocaleDateString(undefined, { month: "short" });
    const year = date.toLocaleDateString(undefined, { year: "2-digit" });
    return `${dayOfMonth} ${month}'${year}`;
  };

  // funtion to toggle am pm btn
  const [selectedPeriod, setSelectedPeriod] = useState("AM");
  const [halfHourSlots, setHalfHourSlots] = useState([]);
  const [checked, setChecked] = useState(true); // Toggle button state
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    // Code for generating half-hour time slots based on selectedPeriod
    const generateHalfHourTimeSlots = (period) => {
      const timeSlots = [];
      let currentHour = period === "AM" ? 9 : 12;
      let currentMinute = 0;

      while (
        !(currentHour === (period === "AM" ? 12 : 18) && currentMinute === 0)
      ) {
        const formattedHour = currentHour === 12 ? 12 : currentHour % 12;
        const ampm = currentHour < 12 ? "" : "";
        const formattedMinute = currentMinute === 0 ? "00" : "30";

        timeSlots.push(`${formattedHour}:${formattedMinute} ${ampm}`);

        currentMinute += 30;
        if (currentMinute === 60) {
          currentMinute = 0;
          currentHour += 1;
        }
      }
      return timeSlots;
    };

    setHalfHourSlots(generateHalfHourTimeSlots(selectedPeriod));
  }, [selectedPeriod]);

  const handleChange = (val) => {
    // Toggle button change handler
    setChecked(val);
    if (val) {
      setSelectedPeriod("AM");
    } else {
      setSelectedPeriod("PM");
    }
  };

  const { productId } = useParams();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { addDocument, response } = useFirestore("bookings");
  const { updateDocument, deleteDocument } = useFirestore("slots");
  const [bookingDate, setBookingDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });

  const [status, setStatus] = useState("upcoming");
  const [formError, setFormError] = useState(null);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [bookingFor, setBookingFor] = useState("self");
  const [nameForOther, setNameForOther] = useState(user.fullName);
  const [phoneForOther, setPhoneForOther] = useState(user.phoneNumber);
  const [bookingReason, setBookingReason] = useState(user.reason);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedSlotDocument, setSlotDocument] = useState("");
  const [genderForOther, setGenderForOther] = useState("");
  const [isOpen, setIsOpen] = useState(false); // State variable to control card visibility
  const [isOpenDetails, setIsOpenDetails] = useState(true); // State variable to control card visibility
  const [fetchedSlotsByDate, setFetchedSlotsByDate] = useState();
  const [noSlotOnSelectedDate, setNoSlotOnSelectedDate] = useState(false);
  const { document, error } = useDocument("products", productId);

  const { document: dbSlotsDocuments, errors: dbSlotsError } = useDocument(
    "settings",
    "slots_holiday"
  );

  const { document: defaultSlots, errors: dbDefaultSlotsError } = useDocument(
    "settings",
    "slots_master"
  );

  // Extract the week names array from the slots_holiday document
  const weekNamesArray = dbSlotsDocuments?.day || [];

  // Assume dbSlotsDocuments.data is an array of timestamps
  const timestampsArray = dbSlotsDocuments?.date || [];
  const datesArray = timestampsArray.map((timestampObj) => {
    const timestampSeconds = timestampObj.seconds || 0;
    const timestampMilliseconds = timestampSeconds * 1000;
    return new Date(timestampMilliseconds);
  });

  // You can then format each date as needed
  const formattedDatesArray = datesArray.map((date) =>
    format(date, "dd-MMM-yy hh:mm a")
  );
  // Extract the date part from bookingDate
  const bookingDateOnly = new Date(bookingDate);
  bookingDateOnly.setHours(0, 0, 0, 0);

  // Convert all dates in formattedDatesArray to date objects with time set to midnight
  const formattedDatesOnly = formattedDatesArray.map((dateString) => {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date;
  });

  // Check if bookingDate matches any date in formattedDatesOnly or if it's a holiday based on week names
  const isBookingDateHoliday = formattedDatesOnly.some((date) => {
    // Check if the booking date matches any date in formattedDatesOnly
    const isMatchingDate = bookingDateOnly.getTime() === date.getTime();

    // Check if the booking date's week name matches any of the week names in the array
    const bookingDateWeekName = format(bookingDate, "eee").toLowerCase();
    const isHolidayByWeekName = weekNamesArray.includes(bookingDateWeekName);

    // Return true if either it's a matching date or a holiday based on week names
    return isMatchingDate || isHolidayByWeekName;
  });

  const { documents: slots, errors: slotsErrors } = useCollection("slots", [
    "productId",
    "==",
    productId,
  ]);

  const slotDefaultSlots =
    slots &&
    slots.filter(
      (slot) => slot.slotType === "default" && slot.data && slot.data.length > 0
    );

  const { documents: allBookings, error: bookingsError } =
    useCollection("bookings");

  useEffect(() => {
    setNoSlotOnSelectedDate(false); // Reset the state when the booking date changes
  }, [bookingDate]);

  useEffect(() => {
    if (fetchedSlotsByDate && fetchedSlotsByDate.length > 0) {
      const hasNoSlot = fetchedSlotsByDate.some((slot) => slot.noSlot);
      setNoSlotOnSelectedDate(hasNoSlot);
    } else {
      setNoSlotOnSelectedDate(false); // Reset the state when fetchedSlotsByDate changes
    }
  }, [fetchedSlotsByDate]);

  const toggleCard = () => {
    setIsOpen(!isOpen); // Toggle card visibility
  };
  const toggleCardDetails = () => {
    setIsOpenDetails(!isOpenDetails); // Toggle card visibility
  };

  const toggleBtnClick = () => {
    if (toggleFlag) {
      setNameForOther(user.fullName); // Setting state value
      setPhoneForOther(user.phoneNumber); // Setting state value
      setBookingReason(user.reason);
      setBookingFor("self");
    } else {
      setNameForOther(""); // Setting state value
      setPhoneForOther(""); // Setting state value
      setGenderForOther("");
      setBookingFor("other");
    }
    setToggleFlag(!toggleFlag);
  };

  let bookingTimestamp = "";

  bookingTimestamp = bookingDate.toDateString();

  // step-1: convert date data into string
  // step-2: convert the this string into date object
  // step-3: convert this date object into firestore timestamp
  const obj = new Date(bookingTimestamp);

  // Convert the JavaScript Date object to a Firestore Timestamp
  const firestoreTimestamp = timestamp.fromDate(obj);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);

    // Check if all form fields are filled
    if (!bookingDate || !selectedTimeSlot || !bookingReason) {
      setFormError("Please choose your slot.");
      return; // Prevent form submission
    }

    // Confirm the booking here
    const booking = {
      // userID: user.uid,
      // approvalStatus: "pending",
      bookingFor,
      nameForOther,
      genderForOther,
      phoneForOther,
      bookingReason,
      status,
      productId: productId,
      // productName: document.name,
      productAmount: document.price,
      productDiscount: document.discount,
      // productImg: document.images[0],
      bookingDate: firestoreTimestamp,
      bookingTimeSlot: selectedTimeSlot,
      paymentstatus: "unpaid",
    };

    await addDocument(booking);

    if (!response.error) {
      // Update the booked field to true in the selected slot document
      if (selectedSlotDocument) {
        await updateDocument(selectedSlotDocument.id, {
          booked: true,
        });
      }
      navigate("/allcustomerbookings");
    }
  };

  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);

  useEffect(() => {
    if (popupReturn) {
    }
  }, [popupReturn]);

  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };
  const handleTimeSlotSelection = (slot) => {
    setSlotDocument(slot);

    setSelectedTimeSlot({
      startTime: slot.startTime,
      endTime: slot.endTime,
    });
  };

  useEffect(() => {
    let todaysDate = new Date();
    let formattedSelectedDate = todaysDate.setHours(0, 0, 0, 0);
    // console.log("formatted Todays Date : ", formattedSelectedDate);
    filteredSlotsByDate(formattedSelectedDate);
  }, [slots]);

  const handleTomorrowSelection = () => {
    // let todaysDate = new Date();
    // // Convert selectedDate to UTC string format
    // let formattedSelectedDate = todaysDate.setHours(0, 0, 0, 0);
    // setBookingDate(todaysDate);

    // filteredSlotsByDate(formattedSelectedDate);
    let tomorrowDate = new Date(); // Get the current date
    tomorrowDate.setDate(tomorrowDate.getDate() + 1); // Set the date to tomorrow
    tomorrowDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00:00

    setBookingDate(tomorrowDate); // Set the booking date to tomorrow

    // Filter slots for tomorrow's date
    filteredSlotsByDate(tomorrowDate.getTime());
  };

  const handleDayAfterTomorrowSelection = () => {
    let tomorrowDate = new Date(); // Get the current date
    tomorrowDate.setDate(tomorrowDate.getDate() + 2); // Set the date to tomorrow
    tomorrowDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00:00

    setBookingDate(tomorrowDate); // Set the booking date to tomorrow

    // Filter slots for tomorrow's date
    filteredSlotsByDate(tomorrowDate.getTime());
  };

  const handleDateSelection = (selectedDate) => {
    // Convert selectedDate to UTC string format
    let formattedSelectedDate = selectedDate.setHours(0, 0, 0, 0);
    setBookingDate(selectedDate);
    filteredSlotsByDate(formattedSelectedDate);
  };

  function filteredSlotsByDate(formattedSelectedDate) {
    // Filter slots based on matching date
    let filteredSlotsByDate =
      slots &&
      slots.filter((finalSlot) => {
        const slotDate = finalSlot.date;
        if (slotDate) {
          const formattedSlotDate = slotDate.toDate().setHours(0, 0, 0, 0);
          return formattedSlotDate === formattedSelectedDate;
        }
        return false; // If finalSlot.date is null or undefined, filter it out
      });

    setFetchedSlotsByDate(filteredSlotsByDate);
  }

  // Filter bookings for the selected product and log filteredBookings
  const filteredBookings = allBookings
    ? allBookings.filter((booking) => booking.productId === productId)
    : [];
  return (
    <>
      {/* Popup Component */}
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to Book?"}
      />
      <section className="add_booking_doctor_parent">
        <MDBContainer className="addbooking_dr_data_parent">
          <div
            className="d-flex align-items-center justify-content-between mb-3"
            style={{ margin: "auto", maxWidth: "95%" }}
          >
            <div>
              <div className="products_heding_contner">
                <h4>Choose Slot</h4>
                <h5>Book Your Slot</h5>
              </div>
            </div>
            <div>
              <Link to="/allcustomerbookings">
                <button className="btn_fill">Booking History</button>
              </Link>
            </div>
          </div>
          <hr
            style={{
              width: "95%",
              margin: "0px auto 10px auto",
              height: "2px",
            }}
          />
          <MDBCol lg="12">
            <div
              className="container add_booking_top_services_details_div"
              onClick={toggleCard}
            >
              <div className="add-booking-collps-btn">
                <div>
                  <h6>
                    <b>More Details</b>
                  </h6>
                </div>
                <div>
                  <button className="booking-page_collpse-btn">
                    <span class="material-symbols-outlined">expand_more</span>
                  </button>
                </div>
              </div>

              <Collapse in={isOpen}>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="pcs_image_area">
                      {document &&
                        document.images &&
                        document.images.length > 0 && (
                          <img
                            src={document.images[0]}
                            className="addbooking_dr_img"
                            alt="Doctor5"
                          />
                        )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 services_details_right_dv">
                    <MDBCard className="mb-4">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Name:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document && document.name.toString("html"),
                                }}
                              ></p>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Rating:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {document && document.rating}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Price:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document && document.price.toString("html"),
                                }}
                              ></p>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Discount:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document &&
                                    document.discount.toString("html"),
                                }}
                              ></p>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Short Description:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document &&
                                    document.short_description.toString("html"),
                                }}
                              ></p>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <div>
                            <h4 style={{ fontSize: "15px" }}>
                              {1000 + filteredBookings.length}+ Bookings
                            </h4>
                            <p style={{ fontSize: "11px" }}>for this service</p>
                          </div>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                </div>
              </Collapse>
            </div>
          </MDBCol>

          <MDBRow lg="12" className="dr-details--lower">
            {/* Add Booking Section */}
            <form onSubmit={handleSubmit}>
              <div className="booking_for">
                <h4 style={{ color: "var(--black-color)" }}>Booking For</h4>
                <div
                  className="residential-commercial-switch"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <span
                    className={toggleFlag ? "active-toggle" : "deactive-toggle"}
                    style={{ color: "var(--click-color)" }}
                  >
                    Self
                  </span>

                  <div
                    className={
                      toggleFlag
                        ? "toggle-switch on commercial"
                        : "toggle-switch off residential"
                    }
                    style={{ padding: "0 10px" }}
                  >
                    <div onClick={toggleBtnClick}>
                      <div></div>
                    </div>
                  </div>
                  <span
                    className={toggleFlag ? "deactive-toggle" : "active-toggle"}
                    style={{ color: "var(--pink)" }}
                  >
                    Other
                  </span>
                </div>
              </div>
              <div className="row">
                {toggleFlag && (
                  <div className="bci_fields">
                    <div className="col-12 col-md-6 form_field">
                      <input
                        required
                        type="text"
                        onChange={(e) => setNameForOther(e.target.value)}
                        value={nameForOther}
                        placeholder="Name"
                      />
                      <div className="field_icon">
                        <span class="material-symbols-outlined">person</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 form_field">
                      <div className="bcard_select">
                        <select
                          required
                          value={genderForOther}
                          onChange={(e) => setGenderForOther(e.target.value)}
                          // onChange={handleGenderChange}
                        >
                          <option value="" hidden>
                            Gender
                          </option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Other</option>
                        </select>
                      </div>
                      <div className="field_icon">
                        <span class="material-symbols-outlined">wc</span>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 form_field">
                      <input
                        required
                        type="number"
                        onChange={(e) => setPhoneForOther(e.target.value)}
                        value={phoneForOther}
                        placeholder="Phone number"
                      />
                      <div className="field_icon">
                        <span class="material-symbols-outlined">call</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-center bci_fields">
                  <div className="col-12 col-md-12 form_field">
                    <input
                      required
                      type="text"
                      onChange={(e) => setBookingReason(e.target.value)}
                      value={bookingReason}
                      placeholder="Provide health issue details"
                    />
                    <div className="field_icon">
                      <span class="material-symbols-outlined">
                        face_retouching_natural
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="bookpage_date_right_div">
                    <div className="add-booking-days-button-parent">
                      {/* Inside the JSX part of your component */}
                      <div className="add-booking-days-button-parent">
                        <button
                          type="button"
                          onClick={handleTomorrowSelection} // Assign handleTomorrowSelection to the onClick handler
                          className={`${
                            bookingDate &&
                            dateOptions[0].toDateString() ===
                              bookingDate.toDateString()
                              ? "selectedDate"
                              : "add-booking-days-button"
                          }`}
                        >
                          <div className="button-content">
                            <div className="">
                              Tomorrow <br />
                              {format(
                                new Date(
                                  new Date().getTime() + 24 * 60 * 60 * 1000
                                ),
                                "d MMM"
                              )}
                            </div>
                          </div>
                        </button>
                        <button
                          type="button"
                          onClick={handleDayAfterTomorrowSelection} // Assign handleDayAfterTomorrowSelection to the onClick handler
                          className={`${
                            bookingDate &&
                            dateOptions[1].toDateString() ===
                              bookingDate.toDateString()
                              ? "selectedDate"
                              : "add-booking-days-button"
                          }`}
                        >
                          <div className="button-content">
                            <div className="">
                              Day After <br />
                              {format(
                                new Date(
                                  new Date().getTime() + 48 * 60 * 60 * 1000
                                ),
                                "d MMM"
                              )}
                            </div>
                          </div>
                        </button>
                      </div>
                      <div>
                        <input
                          className="add-booking-clndr-cls"
                          type="date"
                          min={
                            new Date(
                              new Date().setDate(new Date().getDate() + 3)
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          max={
                            new Date(
                              new Date().setMonth(new Date().getMonth() + 1)
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          onChange={(e) =>
                            handleDateSelection(new Date(e.target.value))
                          }
                        />
                      </div>
                    </div>

                    <div className="addbooking-ampm-button-container">
                      <div className="addbooking_selected_date_div">
                        <p>
                          Available Slots: &nbsp;
                          {formatDateForAvailableSlots(bookingDate)}
                        </p>
                      </div>
                      {/* START CODE FOR FETCHING SLOTS */}
                      <div>
                        {fetchedSlotsByDate && fetchedSlotsByDate.length > 0 ? (
                          // If there are slots available for the selected date
                          fetchedSlotsByDate.map(
                            (slot, index) =>
                              (slot.slotType === "multislot" ||
                                (!slot.booked && !slot.noSlot)) && (
                                <button
                                  key={index}
                                  type="button"
                                  className={`half-hour-button ${
                                    selectedSlotDocument === slot
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleTimeSlotSelection(slot)}
                                >
                                  {/* Convert Firestore Timestamps to JavaScript Date objects and format them */}
                                  {slot.startTime} - {slot.endTime}
                                </button>
                              )
                          )
                        ) : (
                          // If there are no slots available on selected date
                          <>
                            {isBookingDateHoliday ? (
                              // If today is a holiday
                              <p style={{ color: "var(--pink)" }}>
                                No slots available on selected date.
                              </p>
                            ) : (
                              // If today is not a holiday, show slots from dbslotDefaultSlotsDocuments
                              <>
                                {slotDefaultSlots &&
                                  slotDefaultSlots.map((slot, index) => (
                                    <div key={index}>
                                      {slot.data &&
                                        slot.data.map((slotData, dataIndex) => (
                                          <button
                                            key={dataIndex}
                                            type="button"
                                            className={`half-hour-button ${
                                              selectedSlotDocument == slotData
                                                ? "selected"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleTimeSlotSelection(slotData)
                                            }
                                          >
                                            {slotData.startTime} -{" "}
                                            {slotData.endTime}
                                          </button>
                                        ))}
                                    </div>
                                  ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {/* END CODE FOR FETCHING SLOTS */}
                    </div>
                  </div>
                </div>
              </div>
              {!isBookingDateHoliday && (
                <div className="book-page-tntext-end">
                  {formError && <p className="error">{formError}</p>}

                  <button
                    type="submit" // Change the button type to "submit"
                    className="btn_fill"
                    style={{ backgroundColor: "var(--click-color)" }}
                  >
                    Book Appointment
                  </button>
                </div>
              )}
            </form>
          </MDBRow>
        </MDBContainer>
      </section>
    </>
  );
}
