import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Popup from "./Popup";
import AddMedia from "./AddMedia";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";
import { useFirestore } from "../hooks/useFirestore";
import "./HomeHeroBanners.css";
import { useCollection } from "../hooks/useCollection";

const HomeHeroBanners = ({
  category,
  pageName,
  section,
  addMoreVideosButton,
  addMoreTextContentButton,
}) => {
  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
    "media",
    ["section", "==", "homeherobanners"]
  );

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      if (productIdToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Offer deleted successfully");
      } else {
        console.error("No productId specified for deletion");
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div>
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this offer?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div>
        {dbMediaDocuments && dbMediaDocuments.length > 0 && (
          <OwlCarousel
            items={1}
            loop
            dots={false}
            nav={false}
            autoplay={true} // Enable autoplay
            autoplayTimeout={8000}
            smartSpeed={4000}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
            }}
          >
            {dbMediaDocuments &&
              dbMediaDocuments.map((homebanners) =>
                (user && user.role === "admin") ||
                homebanners.status === "active" ? (
                  <div
                    key={homebanners.id}
                    className="dynamic_banners_parent_div"
                  >
                    {homebanners && homebanners.mediaType == "image" ? (
                      <>
                        <img
                          src={homebanners.mediaUrl}
                          alt="banner 2"
                          className="home_baneer_img"
                        />
                      </>
                    ) : (
                      <>
                        <video
                          width="100%"
                          autoPlay
                          loop
                          muted
                          className="home_baneer_img"
                        >
                          <source src={homebanners.mediaUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </>
                    )}
                    {isAdmin && (
                      <>
                        <div className="active_inactive_home_banner">
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex align-items-center mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "red" }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={homebanners.status == "active"}
                                    onChange={() =>
                                      handleToggleChange(
                                        homebanners.id,
                                        homebanners.status
                                      )
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "red" }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 d-flex justify-content-center mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() =>
                                    handleDeleteProduct(homebanners.id)
                                  }
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </>
                    )}
                  </div>
                ) : null
              )}
          </OwlCarousel>
        )}

        {dbMediaDocuments && (
          <div
            className={
              dbMediaDocuments.length > 0
                ? "home-hero-ban-add-media-zero-div"
                : "home-hero-ban-add-media-div"
            }
          >
            <AddMedia
              category={category}
              pageName={pageName}
              section={section}
              addMoreVideosButton={addMoreVideosButton}
              addMoreTextContentButton={addMoreTextContentButton}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeHeroBanners;
