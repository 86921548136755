import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from "../hooks/useAuthContext";

const Hijama = () => {
  const { documents: services, error: servicesError } =
    useCollection("services");
  const { user } = useAuthContext();

  // Check if the user is an admin
  const isAdmin = user && user.role == "admin";
  // Define userStatus state
  const [userStatus, setUserStatus] = useState("active");

  const targetCategory = "Hijama";
  const filteredServices = (services || []).filter(
    (service) => service.service === targetCategory
  );

  const servicesByCategory = filteredServices.reduce((acc, service) => {
    const category = service.category;
    const subHeading = service.sub_heading;
    if (!acc[category]) {
      acc[category] = { subHeading, services: [] };
    }
    acc[category].services.push(service);
    return acc;
  }, {});

  const dental_services_carousel_option = {
    items: 4,
    dots: false,
    loop: true,
    margin: 10,
    nav: false,
    smartSpeed: 1500,
    autoplay: true,
    autoplayTimeout: 9000,
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 5,
      },
    },
  };

  return (
    <div>
      {Object.entries(servicesByCategory).map(
        ([category, { subHeading, services }]) => {
          // Check if user is admin (always show heading for admin)
          const showHeading =
            isAdmin || services.some((service) => service.status === "active");

          return (
            // Conditionally render the container if at least one service is active
            services.length > 0 &&
            showHeading && (
              <div key={category} className="carousel_services_parent">
                {showHeading && (
                  <div className="services_heading carousel_services_heading">
                    <p>{category}</p>
                    <h1 id="top_heading">{subHeading}</h1>
                  </div>
                )}

                <OwlCarousel {...dental_services_carousel_option}>
                  {services.map((service, index) => {
                    const isServiceActive = service.status === "active";
                    const bgColor = isServiceActive
                      ? "var(--click-color)"
                      : "var(--pink)";
                    if (
                      isAdmin ||
                      (userStatus === "active" && service.status !== "inactive")
                    ) {
                      return (
                        <Link
                          to={`/services-details/${service.id}`}
                          className="item carousel_img_parent"
                          key={index}
                        >
                          <img
                            src={service.product_img_1}
                            alt={`Hijama Service ${index + 1}`}
                            className="services_carousel_img"
                          />
                          <h2 className="services_img_heaing">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: service.sub_service.toString("html"),
                              }}
                            ></p>
                          </h2>
                          {isAdmin && (
                            <h2
                              className="services_img_status_heaing"
                              style={{ backgroundColor: bgColor }}
                            >
                              {service.status}
                            </h2>
                          )}
                        </Link>
                      );
                    } else {
                      return null; // Render nothing if the condition is not met
                    }
                  })}
                </OwlCarousel>
              </div>
            )
          );
        }
      )}
    </div>
  );
};

export default Hijama;
