import React from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Ads.css";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Adcarousel = () => {
  return (
    <div className="swipercomp">
      <div className="swipercomp_inner">
        <Swiper
          navigation
          pagination={{ clickable: true }}
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          loop
        >
          <SwiperSlide>
            <div className="ad_container">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Flogin-images%2FWhatsApp%20Image%202024-06-23%20at%201.23.53%20PM.jpeg?alt=media&token=3447eca4-a3cb-4687-bc0c-121e44ca7747"
                alt="login ads img 2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Flogin-images%2FWhatsApp%20Image%202024-06-23%20at%201.23.54%20PM.jpeg?alt=media&token=233ba140-c314-4668-be40-25e99b883cbb"
                alt="login ads img 3"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="ad_container">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Flogin-images%2FWhatsApp%20Image%202024-06-23%20at%201.34.11%20PM.jpeg?alt=media&token=6c6f80f3-aff2-4a13-be1a-ac92347a44cf"
                alt="login ads img 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Flogin-images%2FWhatsApp%20Image%202024-06-23%20at%201.23.52%20PM.jpeg?alt=media&token=91dd955e-1b00-490c-86a4-71187e6726b0"
                alt="login ads img 1"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Adcarousel;
