import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Testimonials.css";

const ProductCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <div className="sect_padding testimonial_sect">
        <div className="container">
          <div className="services_heading testimonials_heading">
            <p>Our Testimonial</p>
            <h1 id="top_heading">
              Discover Success Stories from Delighted Customers
            </h1>
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on the server-side.
            infinite={true}
            autoPlay={true} // Enable auto-play for the second carousel
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            // containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            // dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            className="carousel_container"
          >
            <div className="testimonial_single relative">
              <h5>
                Coming from Saudi Arabia, I trusted Dentamax for a Dental
                Implant, and it was a great decision. The care and results were
                top-notch. My sincere thanks for the life-changing experience.
              </h5>

              <div className="client_info_div">
                <div className="client_img">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Ftestimonial-dummy-img%2F190669.png?alt=media&token=418c41fd-450b-4054-aa87-cebb6b05522c"
                    alt="doctor img 1"
                  />
                </div>
                <div className="client_info">
                  <h4>Mr. Hamid</h4>
                </div>
                <div className="quote_down quote">
                  <img src="./assets/img/quote.png" alt="doctor img 2" />
                </div>
                <div className="quote_up quote">
                  <img src="./assets/img/quote.png" alt="doctor img 3" />
                </div>
              </div>
            </div>

            <div className="testimonial_single relative">
              <h5>
                I came for dental treatment by Dr. Nithya. I strongly recommend
                others also to visit dentamax. Dr. Nithya has really done a good
                job, she is really gentle and nice to do what is required for
                dental care.
              </h5>

              <div className="client_info_div">
                <div className="client_img">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Ftestimonial-dummy-img%2Fclient_img_1.jpeg?alt=media&token=ff3325cd-f0a7-42da-8ce4-90ff4d30a935"
                    alt="doctor img 4"
                  />
                </div>
                <div className="client_info">
                  <h4>Mr. Ruben Antony</h4>
                </div>
                <div className="quote_down quote">
                  <img src="./assets/img/quote.png" alt="doctor img 5" />
                </div>
                <div className="quote_up quote">
                  <img src="./assets/img/quote.png" alt="doctor img 6" />
                </div>
              </div>
            </div>
            <div className="testimonial_single relative">
              <h5>
                Dentamax, under the care of Mrs. Nurag, has been a game-changer
                for my dental health. Professional, painless, and genuinely
                caring. I couldn't ask for a better dental experience. Thank
                you, Dentamax!
              </h5>

              <div className="client_info_div">
                <div className="client_img">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Ftestimonial-dummy-img%2Fface_female_blank_user_avatar_mannequin-512.webp?alt=media&token=2f819c8f-aafc-4933-a2b9-924ca821510a"
                    alt="doctor img 1"
                  />
                </div>
                <div className="client_info">
                  <h4>Ms Nusrat</h4>
                </div>
                <div className="quote_down quote">
                  <img src="./assets/img/quote.png" alt="doctor img 7" />
                </div>
                <div className="quote_up quote">
                  <img src="./assets/img/quote.png" alt="doctor img 8" />
                </div>
              </div>
            </div>
            <div className="testimonial_single relative">
              <h5>
                As a concerned parent, we sought a safe and natural approach for
                our child's allergies, and Dentamax delivered beyond my
                expectations. Their skilled Homeopathic practitioners provided
                personalized care, and we have seen a significant improvement in
                our child's health. Thank you, Dentamax, for offering a holistic
                solution for kids' allergies!
              </h5>

              <div className="client_info_div">
                <div className="client_img">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/images%2Ftestimonial-dummy-img%2FProfile-Transparent.png?alt=media&token=c4bc1ece-4de4-4c4e-90d1-84de222176ab"
                    alt="doctor img 9"
                  />
                </div>
                <div className="client_info">
                  <h4>Mr and Mrs Khan</h4>
                </div>
                <div className="quote_down quote">
                  <img src="./assets/img/quote.png" alt="doctor img 10" />
                </div>
                <div className="quote_up quote">
                  <img src="./assets/img/quote.png" alt="doctor img 11" />
                </div>
              </div>
            </div>
          </Carousel>
        </div>

        {/* <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <div>Item 1</div>
        <div>Item 2</div>
        <div>Item 3</div>
        <div>Item 4</div>
      </Carousel> */}
      </div>
    </div>
  );
};

export default ProductCarousel;
