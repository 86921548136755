import { useState, useEffect } from "react";
import Adcarousel from "../../components/Ads";
import OtpInput from "react-otp-input";
import { useSignupPhone } from "../../hooks/useSignupPhone";
import { useCollection } from "../../hooks/useCollection";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import 'flag-icon-css/css/flag-icon.min.css';
import { Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";

// styles
import "./PGloginsignup.css";

export default function PGLoginPhone() {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // tabpane function start
  const [activeTab, setActiveTab] = useState(1);
  const [otp, setOtp] = useState("");
  const [otptimer, setOtpTimer] = useState(20);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  //   const [isOtpButtonVisible, setIsOtpButtonVisible] = useState(true);
  const [otpSliderState, setotpSliderState] = useState(false);
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const { setUpRecapcha, resendOTP } = useSignupPhone();
  const [confirmObj, setConfirmObj] = useState("");
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [isNewUser, setIsNewUser] = useState(false);

  const { documents: dbuserdocuments, error: dbusererror } = useCollection(
    "users",
    ["status", "==", "active"]
  );
  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");

  const handleWheel = (e) => {
    // Prevent scrolling changes
    e.preventDefault();
  };

  useEffect(() => {
    let interval;
    if (otpSliderState && otptimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (otptimer === 0) {
      setIsResendDisabled(false); // Enable the Resend button when the timer reaches zero
    }

    return () => clearInterval(interval);
  }, [activeTab, otptimer]);

  const handleResendOtp = () => {
    // Logic to resend OTP
    setOtpTimer(20);
    setIsResendDisabled(true);
  };

  const handleOtpButtonClick = async (e) => {
    e.preventDefault();
    setOtpTimer(20);
    setIsResendDisabled(true);
    setError("");
    // console.log("phone:", phone);
    if (phone === "" || phone === undefined || phone.length < 10) {
      return setError("Please enter valid Phone Number");
    }
    // console.log("phone: ", phone)
    // console.log("phoneNumber: ", dbUsers && dbUsers[0].phoneNumber)

    const checkExistingUser =
      dbuserdocuments &&
      dbuserdocuments.filter((item) => item.phoneNumber === phone);
    // console.log('checkExistingUser:', checkExistingUser)

    if (checkExistingUser && checkExistingUser.length > 0) {
      // console.log("checkExistingUser[0].status:", checkExistingUser[0].status);
      if (checkExistingUser[0].status === "inactive") {
        navigate("/inactiveuser");
        return;
      }
      // console.log('existing user')
      setIsNewUser(false);
    } else {
      console.log("set new user");
      setIsNewUser(true);
    }

    try {
      let btnSendOTP = document.getElementById("btn_otp");
      btnSendOTP.style.display = "none";
      // console.log('in try 1', phone);
      const respons = await setUpRecapcha("+" + phone);
      // console.log('in try 2', respons);
      setConfirmObj(respons);
      setotpSliderState(true);
    } catch (error) {
      console.log("2 error.message", error.message);
      setError(error.message);
      await resendOTP("+" + phone);
    }
  };

  //   OTP verify
  const verifyOTP = async (e) => {
    e.preventDefault();
    setError("");
    // setLoading(true);
    if (otp === "" || otp === undefined || otp === null) return;
    try {
      await confirmObj.confirm(otp).then(async (result) => {
        const user = result.user;
        // navigate("/");
        if (isNewUser) {
          console.log("new user: ", user);
          let splitName = userName.split(" ");

          // Extract the first name
          let firstName = splitName[0];

          let imgUrl =
            "https://firebasestorage.googleapis.com/v0/b/townify-dev.appspot.com/o/images%2FDefaultUserIcon.png?alt=media&token=625a6412-f072-4327-97ab-d59c3b67598f";

          await user.updateProfile({
            phoneNumber: phone,
            displayName: firstName,
            photoURL: imgUrl,
          });

          projectFirestore
            .collection("users")
            .doc(user.uid)
            .set({
              online: true,
              displayName: firstName,
              fullName: userName,
              // email,
              phoneNumber: phone,
              email: "",
              city: "",
              address: "",
              photoURL: imgUrl,
              role: "customer",
              roles: ["customer"],
              status: "active",
              createdAt: timestamp.fromDate(new Date()),
              lastLoginTimestamp: timestamp.fromDate(new Date()),
            });
        } else {
          // console.log("existing user:", user);
          let role = "customer";
          const docRef = projectFirestore.collection("users").doc(user.uid);
          // Get the document snapshot
          const docSnapshot = await docRef.get();
          // Check if the document exists
          if (docSnapshot.exists) {
            // Extract the data from the document snapshot
            // const data = docSnapshot.data();
            if (docSnapshot.data().role === "na") role = "customer";
            else role = docSnapshot.data().role;
          }

          // console.log('role: ', role)
          await updateDocument(user.uid, {
            role: role,
            online: true,
            lastLoginTimestamp: timestamp.fromDate(new Date()),
          });
        }
      });

      navigate("/products");
    } catch (error) {
      console.log("error.message", error.message);

      setError(
        "Given OTP is not valid, please enter the valid OTP sent to your mobile"
      );
      // setLoading(false);

      setTimeout(function () {
        setError("");
        // setResendOTPFlag(true);
      }, 3000);
    }
  };

  //   const handleOtpButtonClick = () => {
  //     setOtpTimer(20);
  //     setIsResendDisabled(true);
  //   };

  return (
    <div className="form_page">
      <div className="container">
        <div className="fp_inner d-flex align-items-center">
          <div className="form-parnt">
            <div>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-6 add">
                  <Adcarousel></Adcarousel>
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="/assets/img/DENTAMAX_LOGO__1_-removebg-preview.png"
                    className="phone_dentamax_logo"
                    alt="dentamax logo"
                  />
                  <div className="main_formarea">
                    <form onSubmit={handleSubmit}>
                      <h5 className="login_phone_title">Phone Number</h5>
                      {!otpSliderState && (
                        <div className="tab_pane">
                          <div className="tab-content">
                            <div className="tab_content_single">
                              <div className="form_field">
                                <PhoneInput
                                  country={"ae"}
                                  // onlyCountries={['in', 'us']}
                                  value={phone}
                                  onChange={setPhone}
                                  international
                                  keyboardType="phone-pad"
                                  // countryCallingCodeEditable={false}
                                  countryCodeEditable={true}
                                  // disableCountryCode={true}
                                  //   placeholder="10 Digit Phone Number"
                                  inputStyle={{
                                    // width: "100%",
                                    // padding: "20px",
                                    height: "50px",
                                    fontSize: "15px",
                                    borderRadius: "10px",
                                    border: "1px solid #ccc",
                                  }}
                                  buttonStyle={{
                                    borderRadius: "10px 0px 0px 10px",
                                    border: "1px solid #ccc",
                                  }}
                                  inputProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: false,
                                  }}
                                ></PhoneInput>
                                <div
                                  className="d-flex justify-content-center"
                                  style={{ margin: "10px 0px 10px 0px" }}
                                >
                                  <div id="recapcha-container"></div>
                                </div>

                                <div className="login_call_field_icon">
                                  {/* <span class="material-symbols-outlined">
                                    call
                                  </span> */}
                                </div>
                              </div>
                              <div className="btn_single">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    id="btn_otp"
                                    className="btn_fill"
                                    onClick={handleOtpButtonClick}
                                  >
                                    OTP
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* start otp section */}
                      {otpSliderState && (
                        <div className="d-flex justify-content-center flex-column align-items-center">
                          {isNewUser && (
                            <div>
                              <input
                                type="text"
                                onChange={(e) => setUserName(e.target.value)}
                                placeholder="Enter Your Name"
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid var(--grey-dark)",
                                  padding: "5px",
                                  margin: "10px 0px",
                                }}
                              />
                            </div>
                          )}
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={
                              <span style={{ margin: "10px 5px 20px 5px" }}>
                                -
                              </span>
                            }
                            renderInput={(props) => (
                              <input
                                {...props}
                                type="number"
                                onWheel={handleWheel}
                                inputMode="numeric"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  border: "1px solid gray",
                                  textAlign: "center",
                                  borderRadius: "5px",
                                  margin: "10px 0px 20px 0px",
                                }}
                              />
                            )}
                          />
                          {/* <p className="resend_otp_timer">
                            Haven't received the OTP?
                            {otptimer > 0 ? (
                              <span>Resend In {otptimer} seconds</span>
                            ) : (
                              <span
                                onClick={handleResendOtp}
                                style={{
                                  cursor: isResendDisabled
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              >
                                <a style={{ color: "blue" }}> Resend Now</a>
                              </span>
                            )}
                          </p> */}
                          <button className="btn_fill" onClick={verifyOTP}>
                            Confirm
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                  {error && <Alert variant="danger">{error}</Alert>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
