import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { projectFirestore, projectStorage } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useRef } from "react";
// import owl carousel
import ReactSwitch from "react-switch";
import Popup from "../../components/Popup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TextEditor from "../../components/TextEditor";

import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import { FaEdit } from "react-icons/fa";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./DoctorDetails.css";
import { Carousel } from "bootstrap";
import OfferBanners from "../../components/OfferBanners";

const DoctorDetails = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { productId } = useParams();
  // const { document, error } = useDocument("doctors_team", productId);

  // delete document
  const { deleteDocument } = useFirestore("doctors_team");
  // delete popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const { user } = useAuthContext();
  const [isHeartClicked, setIsHeartClicked] = useState(false);
  const [checked, setChecked] = useState(); // Toggle button state
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedPrice, setEditedPrice] = useState("");
  const [isEditingRating, setIsEditingRating] = useState(false);
  const [editedRating, setEditedRating] = useState("");
  const [isEditingSpecialization, setIsEditingSpecialization] = useState(false);
  const [editedSpecialization, setEditedSpecialization] = useState("");
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [editedDiscount, setEditedDiscount] = useState("");
  const [isEditingShortDescription, setIsEditingShortDescription] =
    useState(false);
  const [editedShortDescription, setEditedShortDescription] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productRating, setProductRating] = useState("");
  const [productSpecialization, setProductSpecialization] = useState("");
  const [productDiscount, setProductDiscount] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);

  // Use selectedProductId for fetching document and error
  const { document, error } = useDocument(
    "doctors_team",
    selectedProductId || productId
  );

  // Function to handle image click and update selectedProductId
  const handleImageClick = (productId) => {
    setSelectedProductId(productId); // Update selectedProductId with the clicked product ID
    window.history.replaceState(null, null, `/doctor-details/${productId}`);
  };

  useEffect(() => {
    // Fetch document data and set editedName
    if (document) {
      setProductName(document.name);
      setEditedName(document.name); // Initialize editedName with the product name
      setProductSpecialization(document.specialization);
      setEditedSpecialization(document.specialization);
      setProductShortDescription(document.short_description);
      setEditedShortDescription(document.short_description);
      setProductDescription(document.description);
      setEditedDescription(document.description);
      setProductDiscount(document.discount);
      setEditedDiscount(document.discount);
      setProductImages(document.images || []);
      // setRating(document.rating || 0);
    }
  }, [document]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all products
        const productsCollection = await projectFirestore
          .collection("doctors_team")
          .get();
        const productsData = [];

        productsCollection.forEach((doc) => {
          const product = doc.data();
          productsData.push({
            id: doc.id,
            name: product.name,
            thumbnail: product.product_img_1,
            status: product.status,
          });
        });

        setAllProducts(productsData);

        // Find the selected product in the fetched products
        const selectedProduct = productsData.find(
          (product) => product.id === productId
        );

        if (selectedProduct) {
          // Update the 'checked' state based on the current status
          setChecked(selectedProduct.status === "active");

          // Fetch product images for the selected product
          const productDoc = await projectFirestore
            .collection("doctors_team")
            .doc(productId)
            .get();
          if (productDoc.exists) {
            const productData = productDoc.data();
            const imageUrls = [];

            for (let i = 1; productData[`product_img_${i}`]; i++) {
              imageUrls.push(productData[`product_img_${i}`]);
            }
            setProductImages(imageUrls);
          }

          // Optionally, you can scroll to the top of the page or perform other actions
          window.scrollTo(0, 0);

          // If needed, you can conditionally fetch additional product details here
          // based on the status, or you can use another useEffect for that purpose.
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed
      }
    };

    // Call the function to fetch all products and product images
    fetchData();
  }, [productId]);

  useEffect(() => {
    // Initialize Owl Carousel with the fetched products
    window.$(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 3500,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }, [allProducts]);

  if (error) {
    return (
      <div className="error" style={{ marginTop: "100px" }}>
        Data Not Found
      </div>
    );
  }
  if (!document) {
    return <div className="loading">Loading...</div>;
  }

  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setIsEditingName(true);
        break;
      case "specialization":
        setIsEditingSpecialization(true);
        break;
      case "discount":
        setIsEditingDiscount(true);
        break;
      case "shortdescription":
        setIsEditingShortDescription(true);
        break;
      case "description":
        setIsEditingDescription(true);
        break;
      default:
        break;
    }
  };

  const handleSaveClick = async (fieldName) => {
    try {
      switch (fieldName) {
        case "name":
          setIsEditingName(false);
          break;
        case "specialization":
          setIsEditingSpecialization(false);
          break;
        case "discount":
          setIsEditingDiscount(false);
          break;
        case "shortdescription":
          setIsEditingShortDescription(false);
          break;
        case "description":
          setIsEditingDescription(false);
          break;
        // Add more cases for other fields if needed
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        // setEditedName(document.name);
        setIsEditingName(false);
        break;
      case "specialization":
        // isEditingSpecialization(document.price);
        setIsEditingSpecialization(false);
        break;
      case "discount":
        // setEditedDiscount(document.discount);
        setIsEditingDiscount(false);
        break;
      case "shortdescription":
        // setEditedShortDescription(document.short_description);
        setIsEditingShortDescription(false);
        break;
      case "description":
        // setEditedDescription(document.description);
        setIsEditingDescription(false);
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  const handleImageChange = async (event) => {
    const files = event.target.files;
    const updatedImages = []; // Create a new array to store updated images

    try {
      // Upload new images to Firebase Storage and retrieve download URLs
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const storageRef = projectStorage.ref(
          `doctors_img/${productId}/${file.name}`
        );
        await storageRef.put(file);

        // Get the download URL for the uploaded file
        const downloadURL = await storageRef.getDownloadURL();

        // Push the download URL to the updatedImages array
        updatedImages.push(downloadURL);
      }

      // Get the current images array from the document
      const currentImages = document.images || [];

      // Combine the current images with the new images
      const allImages = [...currentImages, ...updatedImages];

      // Update the "images" array in Firestore
      await projectFirestore
        .collection("doctors_team")
        .doc(productId)
        .update({ images: allImages });

      // Update the state with the updatedImages array
      setProductImages(allImages);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  // Fetch images array from the document state
  const images = document.images || [];

  const handleAddMoreImages = () => {
    // Trigger file input click using ref
    fileInputRef.current.click();
  };

  const handleToggleChange = (productId, currentStatus) => {
    console.log(currentStatus);
    setChecked(!checked);
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    projectFirestore
      .collection("doctors_team")
      .doc(productId)
      .update({ status: newStatus })
      .then(() => {
        console.log("Product status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };

  // product offers Carousel
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };

  // delete document code
  const handleDeleteProduct = async () => {
    try {
      // Open the delete confirmation popup
      setIsDeletePopupOpen(true);
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };
  const handleConfirmDeleteProduct = async () => {
    try {
      await deleteDocument(productId);
      window.location.href = "/our-team";
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };
  return (
    <>
      <div className="services-details-parent-div">
        <Popup
          showPopupFlag={isDeletePopupOpen}
          setShowPopupFlag={setIsDeletePopupOpen}
          msg="Are you sure you want to delete this service?"
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              handleConfirmDeleteProduct();
            }
          }}
        />
        <div className="container">
          <div className="services_heading more_carousel_services_heading">
            <h1 id="top_heading">More Products</h1>
          </div>
          <div className="owl-carousel owl-theme carousel_services more-products-carosel">
            {allProducts.map((product) => (
              <div
                key={product.id}
                className="item carousel_img_parent"
                onClick={() => handleImageClick(product.id)}
              >
                <img
                  src={product.thumbnail}
                  alt={product.name}
                  className="more_services_carousel_img"
                />
                <h2 className="more_services_img_heading">{product.name}</h2>
              </div>
            ))}
          </div>
          <div
            className="d-flex justify-content-center product-details-pg-srvc-heading"
            style={{
              backgroundColor: "var(--click-color)",
              height: "100px",
              borderRadius: "20px",
            }}
          >
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: document.name.toString("html"),
                }}
              ></p>
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: document.specialization.toString("html"),
                }}
              ></p>
            </div>
          </div>

          <div className="services-images-slider-div-child">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="pcs_image_area">
                  <div className="bigimage_container">
                    <Gallery
                      items={images
                        .filter((url) => url)
                        .map((url) => ({
                          original: url,
                          thumbnail: url,
                        }))}
                      slideDuration={1000}
                    />
                    {/* Box for adding more images */}
                    {user && user.role == "admin" && (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          multiple
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <button
                          className="btn_fill"
                          onClick={handleAddMoreImages}
                        >
                          Add More Images
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Doctor:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingName ? (
                            <div>
                              <TextEditor
                                initialDescription={editedName}
                                docFieldName="name"
                                documentId={productId}
                                collectionName="doctors_team"
                                onSave={() => handleSaveClick("name")}
                                onCancel={() => handleCancelClick("name")}
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: document.name.toString("html"),
                                }}
                              ></p>
                              {!isEditingName &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() => handleEditClick("name")}
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Expertize</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingSpecialization ? (
                            <div>
                              <TextEditor
                                initialDescription={editedSpecialization}
                                docFieldName="specialization"
                                documentId={productId}
                                collectionName="doctors_team"
                                onSave={() => handleSaveClick("specialization")}
                                onCancel={() =>
                                  handleCancelClick("specialization")
                                }
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document.specialization.toString("html"),
                                }}
                              ></p>
                              {!isEditingSpecialization &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() =>
                                      handleEditClick("specialization")
                                    }
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />

                    {/* {document.discount ? (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Discount:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {isEditingDiscount ? (
                                <div>
                                  <input
                                    type="text"
                                    value={editedDiscount}
                                    onChange={(e) =>
                                      setEditedDiscount(e.target.value)
                                    }
                                  />
                                  <div className="d-flex">
                                    <button
                                      className="product_edit_save_btn"
                                      onClick={handleSaveDiscountClick}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="product_edit_save_btn cancel-btn"
                                      onClick={handleCancelDiscountEdit}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {productDiscount || document.discount}
                                  {!isEditingName &&
                                    user &&
                                    user?.role == "admin" && (
                                      <FaEdit
                                        style={{
                                          fontSize: 20,
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          color: "var(--pink)",
                                        }}
                                        onClick={handleEditDiscountClick}
                                      />
                                    )}
                                </>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    ) : null} */}
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Short Description:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingShortDescription ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedShortDescription}
                                  docFieldName="short_description"
                                  documentId={productId}
                                  collectionName="doctors_team"
                                  onSave={() =>
                                    handleSaveClick("shortdescription")
                                  }
                                  onCancel={() =>
                                    handleCancelClick("shortdescription")
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document.short_description.toString("html"),
                                }}
                              ></p>
                              {!isEditingShortDescription &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() =>
                                      handleEditClick("shortdescription")
                                    }
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    {user && user.role == "admin" && (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Status: </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <p style={{ marginTop: "2px" }}> Inactive</p>
                                </div>{" "}
                                <ReactSwitch
                                  checked={checked}
                                  onChange={() =>
                                    handleToggleChange(
                                      document.id,
                                      document.status
                                    )
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  {" "}
                                  <p style={{ marginTop: "2px" }}>Active</p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    )}
                    <div className="d-flex align-items-center namesd">
                      <MDBCol sm="3" className="d-flex">
                        <MDBCardText style={{ cursor: "pointer" }}>
                          <span
                            className="material-symbols-outlined"
                            onClick={shareURL}
                            style={{ fontSize: "23px", padding: "6px 0px" }}
                          >
                            share
                          </span>
                        </MDBCardText>
                        <MDBCardText
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            className={`material-symbols-outlined ${
                              isHeartClicked ? "heart-pink" : ""
                            }`}
                            style={{ fontSize: "23px", padding: "6px 0px" }}
                            onClick={handleHeartClick}
                          >
                            favorite
                          </span>
                        </MDBCardText>
                        {user && user.role == "admin" && (
                          <MDBCardText
                            onClick={handleDeleteProduct}
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "23px", padding: "6px 0px" }}
                            >
                              delete {/* Render the delete icon */}
                            </span>
                          </MDBCardText>
                        )}
                      </MDBCol>

                      {/* <Link to="/contactus">
                        <button
                          type="submit"
                          className="booking_services_book_now_btn"
                        >
                          Book Now
                        </button>
                      </Link> */}
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
          </div>

          {/* product offer images Carousel */}

          <OfferBanners />

          <div className="services_details_bottom_details_parent">
            <section className="services_details_bottom_details_child">
              <MDBRow className="justify-content-center align-items-center">
                <MDBCard style={{ borderRadius: "15px" }}>
                  <MDBCardBody className="p-4">
                    <MDBTypography tag="h3">
                      <strong style={{ color: "var(--pink)" }}>
                        Description
                      </strong>
                    </MDBTypography>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-start align-items-center">
                      <MDBCardText className="mb-0">
                        <MDBCardText className="text-muted">
                          {isEditingDescription ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedDescription}
                                  docFieldName="description"
                                  documentId={productId}
                                  collectionName="doctors_team"
                                  onSave={() => handleSaveClick("description")}
                                  onCancel={() =>
                                    handleCancelClick("description")
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: document.description.toString("html"),
                                }}
                              ></p>

                              {!isEditingDescription &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() =>
                                      handleEditClick("description")
                                    }
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCardText>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorDetails;
