import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, Link } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Insurances from "../../components/Insurances";
import Counter from "../../components/Counter";
import "../../components/ContactUs.css";
import "./PGDermaAds.scss";
import WhatsAppIcon from "../../components/WhatsAppIcon";
import CallUsIcon from "../../components/CallUsIcon";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const PGDermaAds = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const navigate = useNavigate();

  const { user } = useAuthContext();
  const { addDocument } = useFirestore("enquiries");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const phoneNumber = "+97142636717"; // Your phone number

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsPending(true);

    try {
      // Add contact data using addDocument hook
      await addDocument({
        ...formData,
        createdAt: timestamp.fromDate(new Date()),
      });

      setIsPending(false);
      setSuccessMessage(
        "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
      );
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
      // navigate("/thank-you");
      // Redirect to thank-you page
      window.location.href = "/thank-you";
    } catch (err) {
      setError("Error sending data to Firebase");
      setIsPending(false);
      console.log("Error:", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDownloadEnquiries = async () => {
    try {
      // Fetch enquiries collection data from Firestore
      const enquiriesSnapshot = await projectFirestore
        .collection("enquiries")
        .get();

      // Create a CSV string
      let csvData = "Name,Email,Phone Number,Subject,Message,Timestamp\n"; // Header row

      // Iterate over each document in the collection
      enquiriesSnapshot.forEach((doc) => {
        const data = doc.data();
        // Append data to CSV string
        csvData += `${data.name},${data.email},${data.phoneNumber},${
          data.subject
        },${data.message},${data.createdAt.toDate()}\n`;
      });

      // Convert CSV string to Blob
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "enquiries.csv";
      a.click();
    } catch (error) {
      console.error("Error downloading enquiries:", error);
    }
  };

  // videos arrey
  const youtubeVideos = [
    "https://youtu.be/eiFhpJOj6DY?si=fB_teuJ0wxe1QIFC",
    "https://youtu.be/uEfrzJ0cA3A?si=KjUXaTbh5QnzjzNT",
    "https://youtu.be/cNXLkA1_lMk?si=cTZ-VPpcA4JN-uOp",
    "https://youtu.be/X4P1DB5EE8c?si=60xbBXtGWh3W-XNH",
    "https://youtu.be/X7zh2Q9GYeM?si=48WCY4Imdejmq6F4",
    "https://youtu.be/SKjkUZGC-bQ?si=QIDqfLImFvAdi9dT",
  ];

  const getEmbedUrl = (youtubeVideos) => {
    if (typeof youtubeVideos === "string") {
      // Check for YouTube shorts URL
      const shortsMatch = youtubeVideos.match(
        /youtube\.com\/shorts\/([\w-]+)\??/
      );
      if (shortsMatch) {
        const mediaId = shortsMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }

      // Check for regular YouTube media URL
      const regularMatch = youtubeVideos.match(/youtu\.be\/([\w-]+)/);
      if (regularMatch) {
        const mediaId = regularMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }
    }
    // Add other social platforms as needed
    // Example for Vimeo: if (youtubeVideos.includes("vimeo.com/")) return "vimeo embed URL";
    // Default case
    return youtubeVideos;
  };

  // Create a ref for the Contact Us section
  const contactUsRef = useRef(null);

  // Scroll to the Contact Us section when the button is clicked
  const handleBookAppointmentClick = () => {
    if (contactUsRef.current) {
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <WhatsAppIcon />
      <CallUsIcon />
      <div className="derma-ads-banner-div">
        <div className="pg_contact_us">
          <div className="banner relative">
            <img src="./assets/img/Untitled design.webp" alt="Banner 32" />
            <div className="banner_content">
              <div className="bc_inner">
                <div className="container">
                  <h3 className="banner_subheading">BOOK AN APPOINTMENT</h3>
                  <h2
                    className="banner_heading"
                    style={{ color: "var(--white-color)" }}
                  >
                    Dentamax Dermatology Clinic
                  </h2>
                  <button
                    className="btn_fill"
                    onClick={handleBookAppointmentClick}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="container">
        <div className="row ads-category-card">
          <div className="col-12 col-md-6 d-flex justify-content-center flex-column">
            <h1 className="heading_below-underline"></h1>
            <br />
            <h1 className="heading_below-underline">
              <span>
                Welcome to Dentamax Dermatology Clinic, Dubai's Premier Skincare
                Destination
              </span>
            </h1>
            <br />
            <p style={{ fontSize: "20px" }}>
              At Dentamax Dermatology Clinic, we're dedicated to helping you
              achieve your beauty goals with confidence. As Dubai's leading
              dermatology clinic, we specialize in:
            </p>
            <br />
            <h5 style={{ margin: "0px 0px 30px 0px" }}>
              <b>Customized Skincare Routines:</b> Tailored treatments to suit
              your unique skin type and concerns.
              <br />
              <br />
              <b>Advanced Dermatology Treatments:</b> State-of-the-art
              procedures for exceptional results.
              <br />
              <br />
              <b>Expert Care:</b> Led by experienced dermatologists committed to
              your skin's health and beauty.
              <br />
              <br />
              <b>Comprehensive Solutions:</b> From rejuvenation to treating skin
              conditions, we offer a wide range of solutions.
              <br />
              <br />
              <b> Why Choose Us?</b> <br />
              <br />
              <b>Trusted Reputation:</b> Recognized as &nbsp;
              <span style={{ fontWeight: "700", color: "var(--pink)" }}>
                Dubai's #1 Dermatology Clinic.
              </span>{" "}
              <br /> <br />
              <b>Personalized Approach:</b> Each treatment plan is personalized
              to meet your specific needs. <br />
              <br />
              <b>Commitment to Excellence:</b> Ensuring you receive the highest
              quality of care and results. Experience the difference at Dentamax
              Dermatology Clinic. Contact us today to schedule your consultation
              and take the first step towards radiant, healthy skin.
            </h5>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center flex-column">
            <img
              src="/assets/img/22-logo.webp"
              className="award-ribbon-number"
            />
            <br />
            <button className="btn_fill" onClick={handleBookAppointmentClick}>
              Book an Appointment
            </button>
          </div>
          <br />
          <hr />

          <li
            className="col-12 col-md-6 col-lg-4"
            style={{
              fontSize: "20px",
              color: "var(--pink)",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            100+ Fillers Added
          </li>
          <li
            className="col-12 col-md-6 col-lg-4"
            style={{
              fontSize: "20px",
              color: "var(--pink)",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            98+ Botox Treatments
          </li>
          <li
            className="col-12 col-md-6 col-lg-4"
            style={{
              fontSize: "20px",
              color: "var(--pink)",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            200+ Skin Booster Treatments
          </li>
          <li
            className="col-12 col-md-6 col-lg-4"
            style={{
              fontSize: "20px",
              color: "var(--pink)",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            20+ Fat Reduction Treatments
          </li>
          <li
            className="col-12 col-md-6 col-lg-4"
            style={{
              fontSize: "20px",
              color: "var(--pink)",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            50+ Laser Hair Removal
          </li>
          <li
            className="col-12 col-md-6 col-lg-4"
            style={{
              fontSize: "20px",
              color: "var(--pink)",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            60+ Hydera Facial Treatments
          </li>
        </div>
      </div>
      <div className="counter_derma-ads">
        <Counter />
      </div>
      <br />
      <div
        className="d-flex justify-content-center"
        id="redirect-to-contactus-section"
      >
        <button className="btn_fill" onClick={handleBookAppointmentClick}>
          Book an Appointment
        </button>
      </div>
      <br />

      <br />
      <section className="ads_dental_services" id="services-section">
        <div className="" id="skin_services">
          <div className="container">
            <div className="section-title">
              <p>Skin Care Treatments</p>
              <h2>Advance care for any skin condition</h2>
            </div>
            <div className="himherbutton">
              <div className="himherimg desktop">
                <Link to="/services">
                  <img
                    className="himimg"
                    src="./assets/img/him-her-img.svg"
                    alt="her him img"
                  />
                </Link>
              </div>
              <div className="himherimg mobile">
                <Link to="/services">
                  <img
                    className="herimg"
                    src="./assets/img/him-her-img.svg"
                    alt="him her img"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-center">
        <a href={`tel:${phoneNumber}`}>
          <button className="btn_fill">Book a Consultation Call</button>
        </a>
      </div>
      <br />
      <div className="container">
        <div className="section-title">
          <p>Our Dermatology Experts</p>
          <h2>Find top skin specialist at Dentamax Medical Center</h2>
        </div>
        <div className="row ads-category-card">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column img-first">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2Fteam12.webp?alt=media&token=962edf62-5034-4c5e-98c4-596099cb3f23"
              alt="Doctor img"
              className="award-ribbon-number"
              style={{ borderRadius: "20px" }}
            />
            <br />
            <h5>Dr. Raihana</h5>
            <h6 style={{ color: "var(--pink)", fontWeight: "800" }}>
              DERMATOLOGIST
            </h6>
            <br />
            <button className="btn_fill" onClick={handleBookAppointmentClick}>
              Book an Appointment
            </button>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center text-first">
            <h5 style={{ margin: "30px 0px 30px 0px" }}>
              <b>Meet Dr. Raihana:</b> Your Expert Dermatologist for Radiant
              Beauty
              <br />
              <br />
              Welcome to the dedicated care of Dr. Raihana, where expertise
              meets your skincare needs. With over 10 years of experience, Dr.
              Raihana is a trusted authority in dermatology, specializing in:
              <br />
              <br />
              <b>Botox & Filler Injections:</b> Precision treatments for
              natural-looking rejuvenation.
              <br />
              <br />
              <b>Clinical Diagnosis & Treatment:</b> Comprehensive care for skin
              conditions and concerns.
              <br />
              <br />
              <b>PRP for Face & Scalp:</b> Harnessing your body’s own healing
              power for youthful skin and healthy hair.
              <br />
              <br />
              <b>Skin Booster Injections:</b> Enhancing skin hydration and
              vitality with advanced techniques.
              <br />
              <br />
              Transform your skincare routine with Dr. Raihana’s expertise.
              Contact us today to schedule your consultation and discover
              personalized solutions for your radiant beauty.
            </h5>
          </div>
        </div>
        <div className="row ads-category-card ">
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center text-first">
            <h5 style={{ margin: "30px 0px 30px 0px" }}>
              Discover Comprehensive Skincare Treatments with <b>Ms. Leo</b>
              <br />
              <br />
              Welcome to a world of personalized skincare excellence with Ms.
              Leo. With over 17 years of experience and a DHA license approval,
              Ms. Leo offers advanced skills in aesthetic treatments, including:
              <br />
              <br />
              <b>All Skincare Treatments:</b> Tailored solutions for all your
              skincare needs.
              <br />
              <br />
              <b>Laser Hair Removal:</b> Effective and lasting results for
              smooth, hair-free skin.
              <br />
              <br />
              <b>Advanced Aesthetic Treatments:</b> Cutting-edge procedures to
              enhance your natural beauty.
              <br />
              <br />
              Transform your beauty routine with Ms. Leo’s expertise. Contact us
              today to schedule your consultation and embark on your journey to
              radiant, confident skin.
            </h5>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column img-first">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2FWdhatsApp_Image_2024-04-25_at_3f.07.35_PM-removebg-preview%20(1).png?alt=media&token=e60b39fd-9be8-46ae-8f6a-49ce16c5bcef"
              alt="Doctor img"
              className="award-ribbon-number"
              style={{ borderRadius: "20px" }}
            />
            <br />
            <h5>Ms. Leo</h5>
            <h6 style={{ color: "var(--pink)", fontWeight: "800" }}>
              AESTHETICIAN / BEAUTY / LASER SPECIALIST
            </h6>
            <br />
            <button className="btn_fill" onClick={handleBookAppointmentClick}>
              Book an Appointment
            </button>
          </div>
        </div>
      </div>

      <br />

      {/* START BEFORE AFTER BEAUTY */}
      <div className="image_slider_parents_div">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 d-flex align-items-center">
              <div className="beforeaftrer-left-div">
                <h4 style={{ color: "var(--black-color)" }}>
                  Our Beauty Gallery
                </h4>
                <h1 style={{ color: "var(--black-color)" }}>
                  Radiant Beauty Awaits with Just One Visit
                </h1>
                <p style={{ color: "var(--black-color)" }}>
                  Our beauty gallery captures the stunning transformations we've
                  achieved for our clients, showcasing the transformative power
                  of healthy, radiant skin. From customized skincare routines to
                  advanced dermatological treatments, we offer a spectrum of
                  tailored solutions to help you achieve your beauty goals.
                  Explore our gallery and uncover how we can assist you in
                  revitalizing and enhancing your skin's natural radiance today!
                </p>
                <button
                  className="btn_fill"
                  onClick={handleBookAppointmentClick}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex justify-content-center">
              <div
                className="smile-gallery-parent"
                style={{ position: "relative" }}
              >
                <ReactCompareSlider
                  style={{
                    height: "500px",
                    // width: "400px",
                    borderRadius: "10px",
                    border: "3px solid pink",
                  }}
                  itemOne={
                    <>
                      <ReactCompareSliderImage
                        src="/assets/img/afterbeauty1.jfif"
                        srcSet="/assets/img/afterbeauty1.jfif"
                        alt="Image one"
                      />
                      <div
                        className="smile-label"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 10,
                          backgroundColor: "grey",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        <h5>Before</h5>
                      </div>
                    </>
                  }
                  itemTwo={
                    <>
                      <ReactCompareSliderImage
                        alt="Image two"
                        src="/assets/img/beforebeauty1.jfif"
                        srcSet="/assets/img/beforebeauty1.jfif"
                      />
                      <div
                        className="smile-label"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          backgroundColor: "grey",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        <h5>After</h5>
                      </div>
                    </>
                  }
                />
              </div>
            </div>

            <div className="beforeafter_dta_right">
              <p>
                Our smile gallery showcases the amazing transformations we’ve
                accomplished for our patients, highlighting the power of a
                beautiful smile. From whitening to orthodontics, we offer a
                range of tailored solutions to help you achieve your dream
                smile. Explore our gallery and discover how we can help you
                transform your smile today!
              </p>
              <button className="btn_fill" onClick={handleBookAppointmentClick}>
                Book an Appointment
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* END BEFORE AFTER BEAUTY */}

      {/* YOUTUBE VIDEOS */}
      <br />
      <br />
      <div className="container">
        <div className="row">
          {youtubeVideos.map((videolink, index) => (
            <div className="col-12 col-md-4">
              <iframe
                title={`Social Video ${index + 1}`}
                src={getEmbedUrl(videolink)}
                frameBorder="0"
                allowFullScreen
                className="youtube-media-style"
              ></iframe>
            </div>
          ))}
        </div>
      </div>
      {/* contact us section start */}
      <section className="form_sec" id="redirected-to-contactus-section">
        <div className="contact_us_left_img">
          <img src="./assets/img/22-logo.webp" alt="22 year logo img" />
        </div>
        <div
          className="right_form"
          style={{
            backgroundImage: "url('./assets/img/contactformbg.jpg')",
          }}
          ref={contactUsRef}
        >
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="d-flex justify-content-center">
                <h3
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginBottom: "30px",
                  }}
                >
                  Contact Us
                </h3>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">Person</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">mail</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">call</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">subject</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form_field">
                  <textarea
                    type="text"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">chat</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="submit_btn">
                  <button
                    type="submit"
                    className="btn_fill"
                    disabled={isPending}
                  >
                    {isPending ? "Sending..." : "Send"}
                  </button>
                </div>
                {error && <p className="error">{error}</p>}
                {successMessage && <p className="success">{successMessage}</p>}
              </div>
              {user && user.role == "admin" && (
                <button className="btn_fill" onClick={handleDownloadEnquiries}>
                  Download Enquiries
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
      {/* contact us section end */}
      <div className="container">
        <div className="section-title">
          <p>Our Insurances Partners</p>
          <h2>Comprehensive protection with our insurance plans</h2>
        </div>
      </div>

      <Insurances />
    </div>
  );
};

export default PGDermaAds;
