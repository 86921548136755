import { Link, useLocation } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCollection } from "../hooks/useCollection";
import { useDocument } from "../hooks/useDocument";
// styles & images
import "./Navbar.css";

export default function Navbar() {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const phoneNumber = "+97142636717";

  const location = useLocation(); // Get the current location
  const { documents: productsdocuments, error: productserror } =
    useCollection("products");

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );

  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const isAdmin = user && user.role === "admin";
  const hasActiveProducts =
    productsdocuments &&
    productsdocuments.some((product) => product.status === "active");

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // code for show only first name of full name
  let fullName = "";
  let firstName = "";

  if (user && user.fullName) {
    fullName = user.fullName;
    const nameParts = fullName.split(" ");
    if (nameParts.length > 0) {
      firstName = nameParts[0];
    }
  }

  // for show active tab with diff color
  const [activeMenu, setActiveMenu] = useState("home");
  const showProfile = () => {
    setActiveMenu("showProfile");
  };

  const showHome = () => {
    navigate("/");
  };

  const showSecondPage = () => {
    navigate("/services");
  };

  const showThirdPage = () => {
    navigate("/our-team");
  };

  const showFourthPage = () => {
    navigate("/internationalpatients");
  };

  const showFifthPage = () => {
    navigate("/more");
  };

  const showSixthPage = () => {
    if (user && user.role === "admin") {
      navigate("/products");
    }
    if (user && user.role === "customer") {
      navigate("/products");
    }
    if (user && user.role === "doctor") {
      navigate("/calendarbooking");
    } else {
      navigate("/products");
    }
  };

  const showSeventhPage = () => {
    {
      user ? navigate("/customerdashboard") : navigate("/login");
    }
  };
  const showEightPage = () => {
    navigate("/products");
  };
  const showNinthPage = () => {
    navigate("/gallery");
  };
  const showLogin = () => {
    setActiveMenu("login/signup");
    navigate("/login");
  };
  //Menus as per roles
  let firstMenuIcon = "";
  let firstMenu = ""; //This is for all user type
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";
  let fifthMenu = "";
  let fifthMenuIcon = "";
  let sixthMenu = "";
  let sixthMenuIcon = "";
  let seventhMenu = "";
  let seventhMenuIcon = "";
  let eightMenu = "";
  let eightMenuIcon = "";
  let ninthMenu = "";
  let ninthMenuIcon = "";

  // start Fixed Menus for all
  firstMenuIcon = "home";
  firstMenu = "Home";
  fifthMenuIcon = "apps";
  fifthMenu = "More";
  ninthMenuIcon = "photo_library";
  ninthMenu = "Gallery";

  // end Fixed Menus for all

  if (user && user.role == "admin") {
    secondMenuIcon = "analytics";
    secondMenu = "Properties";
    thirdMenuIcon = "confirmation_number";
    thirdMenu = "Users";
  }
  if (user && user.role === "customer") {
    secondMenuIcon = "checklist";
    secondMenu = "Services";
    thirdMenuIcon = "groups";
    thirdMenu = "Team";
    fourthMenuIcon = "personal_injury";
    fourthMenu = "International Patients";
    sixthMenuIcon = "handshake";
    sixthMenu = "Appointment";
    seventhMenuIcon = "dashboard";
    seventhMenu = "Dashboard";
    eightMenuIcon = "category";
    eightMenu = "Booking";
  } else {
    secondMenuIcon = "checklist";
    secondMenu = "Services";
    thirdMenuIcon = "groups";
    thirdMenu = "Team";
    fourthMenuIcon = "personal_injury";
    fourthMenu = "International Patients";
    sixthMenuIcon = "handshake";
    sixthMenu = "Appointment";
    seventhMenuIcon = "dashboard";
    seventhMenu = "Dashboard";
    eightMenuIcon = "category";
    eightMenu = "Booking";
  }
  if (user && user.role === "frontdesk") {
    secondMenu = "Bills";
    thirdMenu = "Tickets";
  }

  return (
    <>
      <nav className={`navbar sticky-top ${isNavbarFixed ? "fixed" : ""}`}>
        <div className="container-fluid">
          <ul>
            <Link to="/">
              <li className="logo">
                <img
                  src="/assets/img/DENTAMAX_LOGO__1_-removebg-preview.png"
                  alt="logo navbar"
                />
              </li>
            </Link>

            {location.pathname == "/dermaads" ||
            location.pathname == "/dental-ads"
              ? null
              : user && (
                  <div className="small">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div></div>
                      <li>
                        <div className="navbar-notification-div">
                          <Link to="/notifications">
                            <span className="material-symbols-outlined">
                              notifications
                            </span>
                            <div></div>
                          </Link>
                        </div>
                      </li>
                    </div>
                  </div>
                )}

            {location.pathname == "/dermaads" ||
            location.pathname == "/dental-ads" ? (
              <>
                <a href={`tel:${phoneNumber}`}>
                  <button className="btn_fill">Call Us</button>
                </a>
              </>
            ) : (
              <div className="large">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div></div>
                  <div className="navbar-laptop-menu-links-div">
                    <div className="navbar-laptop-menu-links">
                      {/* home */}
                      <div
                        onClick={showHome}
                        className={
                          location.pathname === "/" ? "active_menu" : ""
                        }
                      >
                        <span className="material-symbols-outlined">
                          {firstMenuIcon}
                        </span>
                        <h1>{firstMenu}</h1>
                      </div>
                      {/* SERIVCES */}
                      {/* {location.pathname == "/products" ||
                    location.pathname == "/product-details" ||
                    location.pathname == "/customerdashboard" ||
                    location.pathname == "/allcustomerbookings" ? (
                      <></>
                    ) : (
                      <>
                        <div
                          onClick={showSecondPage}
                          className={
                            location.pathname === "/services"
                              ? "active_menu"
                              : ""
                          }
                        >
                          <span className="material-symbols-outlined">
                            {secondMenuIcon}
                          </span>
                          <h1>{secondMenu}</h1>
                        </div>
                      </>
                    )} */}
                      {/* TEAM */}
                      {location.pathname == "/products" ||
                      location.pathname == "/product-details" ||
                      location.pathname == "/customerdashboard" ||
                      location.pathname == "/allcustomerbookings" ? (
                        <>
                          <div
                            onClick={showSeventhPage}
                            className={
                              location.pathname === "/customerdashboard"
                                ? "active_menu"
                                : ""
                            }
                          >
                            <span className="material-symbols-outlined">
                              {seventhMenuIcon}
                            </span>
                            <h1>{seventhMenu}</h1>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={showThirdPage}
                            className={
                              location.pathname === "/our-team"
                                ? "active_menu"
                                : ""
                            }
                          >
                            <span className="material-symbols-outlined">
                              {thirdMenuIcon}
                            </span>
                            <h1>{thirdMenu}</h1>
                          </div>
                        </>
                      )}
                      {/* GALLERY */}
                      {location.pathname == "/products" ||
                      location.pathname == "/product-details" ||
                      location.pathname == "/customerdashboard" ||
                      location.pathname == "/allcustomerbookings" ? (
                        <></>
                      ) : (
                        <>
                          <div
                            onClick={showNinthPage}
                            className={
                              location.pathname === "/gallery"
                                ? "active_menu"
                                : ""
                            }
                          >
                            <span className="material-symbols-outlined">
                              {ninthMenuIcon}
                            </span>
                            <h1>{ninthMenu}</h1>
                          </div>
                        </>
                      )}
                      {/* International Patients */}
                      {location.pathname == "/products" ||
                      location.pathname == "/product-details" ||
                      location.pathname == "/customerdashboard" ||
                      location.pathname == "/allcustomerbookings" ? null : (
                        <>
                          <div
                            onClick={showFourthPage}
                            className={
                              location.pathname === "/internationalpatients"
                                ? "active_menu"
                                : ""
                            }
                          >
                            <span className="material-symbols-outlined">
                              {fourthMenuIcon}
                            </span>
                            <h1>{fourthMenu}</h1>
                          </div>
                        </>
                      )}
                      {/* Appointment */}

                      {appointmentPermission &&
                        appointmentPermission.permission == true && (
                          <>
                            {(isAdmin ||
                              (productsdocuments &&
                                productsdocuments.length !== 0 &&
                                hasActiveProducts)) &&
                              (location.pathname === "/products" ||
                              location.pathname === "/product-details" ||
                              location.pathname === "/customerdashboard" ||
                              location.pathname === "/allcustomerbookings" ? (
                                <div
                                  onClick={showEightPage}
                                  className={
                                    location.pathname === "/products"
                                      ? "active_menu"
                                      : ""
                                  }
                                >
                                  <span className="material-symbols-outlined">
                                    {sixthMenuIcon}
                                  </span>
                                  <h1>{sixthMenu}</h1>
                                </div>
                              ) : (
                                <div
                                  onClick={showSixthPage}
                                  className={
                                    location.pathname === "/products" ||
                                    location.pathname === "/calendarbooking"
                                      ? "active_menu"
                                      : ""
                                  }
                                >
                                  <span className="material-symbols-outlined">
                                    {sixthMenuIcon}
                                  </span>
                                  <h1>{sixthMenu}</h1>
                                </div>
                              ))}
                          </>
                        )}

                      {/* More */}
                      <div
                        onClick={showFifthPage}
                        className={
                          location.pathname === "/more" ? "active_menu" : ""
                        }
                      >
                        <span className="material-symbols-outlined">
                          {fifthMenuIcon}
                        </span>
                        <h1>{fifthMenu}</h1>
                      </div>

                      {appointmentPermission &&
                        appointmentPermission.permission == true && (
                          <>
                            {!user && (
                              <div
                                onClick={showLogin}
                                className={
                                  activeMenu === "login/signup"
                                    ? "active_menu"
                                    : ""
                                }
                              >
                                <span class="material-symbols-outlined">
                                  login
                                </span>
                                <h1>Login/Signup</h1>
                              </div>
                            )}
                          </>
                        )}
                    </div>

                    {appointmentPermission &&
                      appointmentPermission.permission == true && (
                        <>
                          {" "}
                          {user && (
                            <div className="navbar-laptop-menu-icons-div">
                              <Link to="/profile">
                                <div
                                  className={`navbar_profile ${
                                    activeMenu === "showProfile"
                                      ? "active_menu"
                                      : ""
                                  }`}
                                  onClick={showProfile}
                                >
                                  <div className="np_inner">
                                    <h5 style={{ color: "var(--white-color)" }}>
                                      Hi, {firstName}
                                    </h5>

                                    {user.photoURL === "" ? (
                                      <img
                                        src="https://firebasestorage.googleapis.com/v0/b/slotify-dev.appspot.com/o/userThumbnails%2FUser-Profile-Icon-9mces.png?alt=media&token=393d4a36-3605-43fc-850e-763a959e861a&_gl=1*1out07e*_ga*MTE4NDUwODc2LjE2NzgxOTYyNjk.*_ga_CW55HF8NVT*MTY4NjM2NzY3My4xMDUuMS4xNjg2MzY4MDcxLjAuMC4w"
                                        alt="dummy profile photo"
                                      />
                                    ) : (
                                      <img
                                        src={user.photoURL}
                                        alt="navbar user photo"
                                      />
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      )}
                  </div>
                </div>
              </div>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}
