import React from "react";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";

// style
import "./CollapsibleGroup.css";

const CollapsibleGroup = () => {
  return (
    <div className="parent_div">
      <div className="container">
        <div className="row">
          {/* Left section */}
          <div className="col-md-6 col-sm-12 d-flex justify-content-center img-div">
            <img src="./assets/img/faq_img.webp" />
          </div>

          {/* Right section */}
          <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-column right_sec">
            <div>
              <div className="faq_services_heading">
                <p>FAQs</p>
                <h1 id="faq_top_heading">Common Queries Clarified</h1>
              </div>
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "30px",
                  color: "var(--black-color)",
                }}
              >
                Dentamax: Your comprehensive healthcare solution. We offer
                Dental, Dermatology, Homeopathy, and Hijama services,
                prioritizing your well-being with expert care and advanced
                technology. Experience a brighter, healthier future with
                Dentamax.
              </p>
            </div>

            <div className="collaps-main">
              <div className="d-flex align-items-center collaps-main-child">
                <div className="collaps-icon">
                  <i class="bi bi-house-heart-fill"></i>
                </div>
                <Collapsible
                  trigger="What Services Does Dentamax Offer?"
                  className="collaps_trigger"
                >
                  <p
                    style={{
                      marginLeft: "30px",
                      color: "var(--black-color)",
                      letterSpacing: "1px",
                    }}
                  >
                    Dentamax provides a range of healthcare services, including
                    Dental care, Dermatology treatments, Homeopathy solutions,
                    and Hijama therapy. Our diverse offerings ensure
                    comprehensive care for your well-being.
                  </p>
                </Collapsible>
              </div>

              <div className="d-flex align-items-center collaps-main-child">
                <div className="collaps-icon">
                  <i class="bi bi-currency-exchange"></i>
                </div>
                <Collapsible
                  trigger="How Can I Book an Appointment?"
                  className="collaps_trigger"
                >
                  <p
                    style={{
                      marginLeft: "30px",
                      color: "var(--black-color)",
                      letterSpacing: "1px",
                    }}
                  >
                    To book an appointment, click the "Appointment" menu, select
                    a product or service, and choose a slot. Fill in your
                    details, pick a date and time, and click "Book Slot." Your
                    appointment will be confirmed.
                  </p>
                </Collapsible>
              </div>

              <div className="d-flex align-items-center collaps-main-child">
                <div className="collaps-icon">
                  <i class="bi bi-database-fill-down"></i>
                </div>
                <Collapsible
                  trigger="Are Your Doctors Experienced?"
                  className="collaps_trigger"
                >
                  <p
                    style={{
                      marginLeft: "30px",
                      color: "var(--black-color)",
                      letterSpacing: "1px",
                    }}
                  >
                    Yes, our team consists of certified and experienced doctors
                    across various specialties. They bring their expertise to
                    ensure you receive the best possible care and treatment.
                  </p>
                </Collapsible>
              </div>

              <div className="d-flex align-items-center collaps-main-child">
                <div className="collaps-icon">
                  <i class="bi bi-star-fill"></i>
                </div>
                <Collapsible
                  trigger="Do You Accept Insurance?"
                  className="collaps_trigger"
                >
                  <p
                    style={{
                      marginLeft: "30px",
                      color: "var(--black-color)",
                      letterSpacing: "1px",
                    }}
                  >
                    Dentamax collaborates with a range of insurance providers.
                    Contact our support team to verify if your insurance plan is
                    accepted, making your healthcare experience even more
                    accessible.
                  </p>
                </Collapsible>
              </div>

              <div className="d-flex align-items-center collaps-main-child">
                <div className="collaps-icon">
                  <i class="bi bi-shield-lock-fill"></i>
                </div>
                <Collapsible
                  trigger="What Safety Measures Are in Place?"
                  className="collaps_trigger"
                >
                  <p
                    style={{
                      marginLeft: "30px",
                      color: "var(--black-color)",
                      letterSpacing: "1px",
                    }}
                  >
                    Your safety is our priority. Dentamax strictly follows
                    sterilization protocols, maintains a clean and sanitized
                    environment, and adheres to all recommended health
                    guidelines to ensure your well-being during every visit.
                  </p>
                </Collapsible>
              </div>
            </div>
            <Link to="/faq">
              <div className="doctor-btn">
                <button className="btn_fill home_faq_Button">More</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleGroup;
