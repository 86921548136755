import React, { useState, useEffect } from "react";
import { projectFirestore, timestamp } from "../firebase/config";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFirestore } from "../hooks/useFirestore";

import "./ContactUs.css";

const ContactUs = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  const { addDocument } = useFirestore("enquiries");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const phoneNumber = "+97142636717"; // Your phone number

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsPending(true);

    try {
      // Add contact data using addDocument hook
      await addDocument({
        ...formData,
        createdAt: timestamp.fromDate(new Date()),
      });

      setIsPending(false);
      setSuccessMessage(
        "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
      );
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    } catch (err) {
      setError("Error sending data to Firebase");
      setIsPending(false);
      console.log("Error:", err);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError(null);
  //   setIsPending(true);

  //   try {
  //     // Add contact data to Firebase collection
  //     await projectFirestore.collection("enquiries").add({
  //       ...formData,
  //       createdAt: timestamp.fromDate(new Date()),
  //     });

  //     setIsPending(false);
  //     setSuccessMessage(
  //       "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
  //     );
  //     setFormData({
  //       name: "",
  //       email: "",
  //       phoneNumber: "",
  //       subject: "",
  //       message: "",
  //     });
  //   } catch (err) {
  //     setError("Error sending data to Firebase");
  //     setIsPending(false);
  //     console.log("Error:", err);
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDownloadEnquiries = async () => {
    try {
      // Fetch enquiries collection data from Firestore
      const enquiriesSnapshot = await projectFirestore
        .collection("enquiries")
        .get();

      // Create a CSV string
      let csvData = "Name,Email,Phone Number,Subject,Message,Timestamp\n"; // Header row

      // Iterate over each document in the collection
      enquiriesSnapshot.forEach((doc) => {
        const data = doc.data();
        // Append data to CSV string
        csvData += `${data.name},${data.email},${data.phoneNumber},${
          data.subject
        },${data.message},${data.createdAt.toDate()}\n`;
      });

      // Convert CSV string to Blob
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "enquiries.csv";
      a.click();
    } catch (error) {
      console.error("Error downloading enquiries:", error);
    }
  };

  return (
    <>
      <div className="pg_contact_us">
        <div className="banner relative">
          <img src="./assets/img/contact_page_banner (2).webp" alt="Banner 1" />
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">
                  CONTACT US FOR ANY INQUIRIES
                </h3>
                <h2 className="banner_heading">
                  Reach out to us for assistance
                </h2>
                {/* <Link to="contactus"> */}

                <a href={`tel:${phoneNumber}`} className="btn_fill">
                  <button className="btn_fill">Call Us</button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        <section className="loc_em_ph">
          <div className="container">
            <h2
              className="map_heading"
              style={{ textAlign: "center", color: "var(--pink)" }}
            >
              Dentamax Medical Center, Dubai
            </h2>
            <div
              className="loc_em_ph_inner"
              style={{
                backgroundImage: "url('./assets/img/counterbg.png')",
              }}
            >
              <div className="lep_single">
                <div>
                  <div className="icon_div ">
                    <img
                      src="./assets/img/location_f_cpg.png"
                      alt="location img"
                    ></img>
                  </div>
                  <h4>Address</h4>
                </div>
                <h6 className="lep_single_address">
                  Dentamax Medical Center LLC
                  <br />
                  Belresheed Bldg. <br />
                  Office 101-102 Al Nahda Street
                  <br />
                  Al Qusais 2, Dubai
                </h6>
              </div>
              <div className="lep_single">
                <div>
                  <div className="icon_div">
                    <img src="./assets/img/emailcpg.png" alt="email img"></img>
                  </div>
                  <h4>Email</h4>
                </div>
                <h6>
                  <a
                    href="mailto:hello@dentamaxclinic.com"
                    style={{ color: "var(--black-color)" }}
                  >
                    hello@dentamaxclinic.com
                  </a>
                </h6>
              </div>
              <div className="lep_single">
                <div>
                  <div className="icon_div">
                    <span className="material-symbols-outlined ">
                      deskphone
                    </span>
                  </div>
                  <h4>Phone</h4>
                </div>
                <h6>
                  <a
                    href="tel:+97142636717"
                    style={{ color: "var(--black-color)" }}
                  >
                    +971 42 636 717
                  </a>
                  <br />
                  <br />
                  <a
                    href="tel:+971545171799"
                    style={{ color: "var(--black-color)" }}
                  >
                    +971 54 517 1799
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </section>

        <section className="loc_em_ph">
          <div className="container">
            <h2
              className="map_heading"
              style={{ textAlign: "center", color: "var(--pink)" }}
            >
              Velcare Orthodontic & Dental Clinic
            </h2>
            <div
              className="loc_em_ph_inner"
              style={{
                backgroundImage: "url('./assets/img/counterbg.png')",
              }}
            >
              <div className="lep_single">
                <div>
                  <div className="icon_div ">
                    <img
                      src="./assets/img/location_f_cpg.png"
                      alt="location logo img"
                    ></img>
                  </div>
                  <h4>Address</h4>
                </div>
                <h6 className="lep_single_address">
                  Velcare Orthodontic And Dental Clinic
                  <br />
                  Ground Floor, <br />
                  Al Mashroom Building, 23 Street
                  <br />
                  Al Warqa 1, Dubai
                </h6>
              </div>
              <div className="lep_single">
                <div>
                  <div className="icon_div">
                    <img
                      src="./assets/img/emailcpg.png"
                      alt="email logo img"
                    ></img>
                  </div>
                  <h4>Email</h4>
                </div>
                <h6>
                  <a
                    href="mailto:info@velcaredentalclinic.com"
                    style={{ color: "var(--black-color)" }}
                  >
                    info@velcaredentalclinic.com
                  </a>
                </h6>
              </div>
              <div className="lep_single">
                <div>
                  <div className="icon_div">
                    <span className="material-symbols-outlined ">
                      deskphone
                    </span>
                  </div>
                  <h4>Phone</h4>
                </div>

                <h6>
                  <a
                    href="tel:+97142858755"
                    style={{ color: "var(--black-color)" }}
                  >
                    +971 4 285 8755
                  </a>
                  <br />
                  <br />
                  <a
                    href="tel:+971522715060"
                    style={{ color: "var(--black-color)" }}
                  >
                    +971 52 271 5060
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="form_sec">
          <div className="contact_us_left_img">
            <img src="./assets/img/22-logo.webp" alt="22 year logo img" />
          </div>
          <div
            className="right_form"
            style={{
              backgroundImage: "url('./assets/img/contactformbg.jpg')",
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form_field">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="field_icon">
                      <span className="material-symbols-outlined">Person</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form_field">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="field_icon">
                      <span className="material-symbols-outlined">mail</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form_field">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="field_icon">
                      <span className="material-symbols-outlined">call</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form_field">
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="field_icon">
                      <span className="material-symbols-outlined">subject</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form_field">
                    <textarea
                      type="text"
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="field_icon">
                      <span className="material-symbols-outlined">chat</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="submit_btn">
                    <button
                      type="submit"
                      className="btn_fill"
                      disabled={isPending}
                    >
                      {isPending ? "Sending..." : "Send"}
                    </button>
                  </div>
                  {error && <p className="error">{error}</p>}
                  {successMessage && (
                    <p className="success">{successMessage}</p>
                  )}
                </div>
                {user && user.role == "admin" && (
                  <button
                    className="btn_fill"
                    onClick={handleDownloadEnquiries}
                  >
                    Download Enquiries
                  </button>
                )}
              </div>
            </form>
          </div>
          <h2 className="map_heading" style={{ color: "var(--pink )" }}>
            Dentamax Medical Center, Dubai
          </h2>
          <iframe
            title="Dentamax Clinic Location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3608.098239761867!2d55.3774914!3d25.2672805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d35b0d8fc8b%3A0x181c5db217cac9ee!2sDentamax%20Medical%20Center%2C%20Dubai!5e0!3m2!1sen!2sin!4v1695569822534!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>

          <h2 className="map_heading" style={{ color: "var(--pink)" }}>
            Velcare Orthodontic & Dental Clinic
          </h2>
          <iframe
            title="Dentamax Clinic Location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14441.579218868024!2d55.4043646!3d25.189905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f60d561d19a21%3A0xafe7da4468fa906a!2sVelcare%20Orthodontic%20and%20Dental%20clinic!5e0!3m2!1sen!2sin!4v1691830711462!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
