import React from "react";
import { Link } from "react-router-dom";
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import { format } from "date-fns";

const UpcomingBookingsCard = () => {
  const { user } = useAuthContext();

  const { documents: dbBookingsDocuments, error: dbBookingsError } =
    useCollection("bookings", ["status", "in", ["upcoming", "approved"]]);

  // Filter bookings to include only the ones created by the logged-in user
  const userBookings =
    dbBookingsDocuments && user
      ? dbBookingsDocuments.filter((booking) => booking.createdBy === user.uid)
      : [];

  // Find the latest upcoming booking from the user's bookings
  const latestUserBooking =
    dbBookingsDocuments && dbBookingsDocuments.length > 0
      ? dbBookingsDocuments.reduce((latest, booking) => {
          return new Date(booking.date) > new Date(latest.date)
            ? booking
            : latest;
        }, dbBookingsDocuments[0])
      : null;

  return (
    <div>
      {(
        user && user.role === "admin"
          ? dbBookingsDocuments && dbBookingsDocuments.length > 0
          : userBookings && userBookings.length > 0
      ) ? (
        <Link to="/allcustomerbookings" style={{ color: "var(--black-color)" }}>
          <div className="upcom-booking-home-parent">
            <div className="col-5">
              <div className="d-flex flex-column justify-content-center align-items-center home-dte-child-div">
                <div className="home-up-date">
                  {user && user.role == "admin" ? (
                    <>
                      <h1>
                        {dbBookingsDocuments && dbBookingsDocuments.length > 1
                          ? dbBookingsDocuments.length
                          : dbBookingsDocuments &&
                            dbBookingsDocuments[0].bookingDate
                          ? format(
                              dbBookingsDocuments[0].bookingDate.toDate(),
                              "dd MMM yy"
                            )
                          : null}
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1>
                        {dbBookingsDocuments &&
                        dbBookingsDocuments.filter(
                          (booking) => booking && booking.createdBy === user.uid
                        ).length > 1
                          ? dbBookingsDocuments.filter(
                              (booking) =>
                                booking && booking.createdBy === user.uid
                            ).length
                          : dbBookingsDocuments &&
                            dbBookingsDocuments.filter(
                              (booking) =>
                                booking && booking.createdBy === user.uid
                            ).length === 1
                          ? dbBookingsDocuments.filter(
                              (booking) =>
                                booking && booking.createdBy === user.uid
                            )[0].bookingDate
                            ? format(
                                dbBookingsDocuments
                                  .filter(
                                    (booking) =>
                                      booking && booking.createdBy === user.uid
                                  )[0]
                                  .bookingDate.toDate(),
                                "dd MMM yy"
                              )
                            : null
                          : null}
                      </h1>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column justify-content-center align-items-center home-day-child-div">
                <div className="d-flex align-items-center home-up-upcom-text">
                  <img
                    src="/assets/img/output-onlinegiftools.gif"
                    alt="bell icon gif"
                    style={{ width: "40px" }}
                    className="upcoming_booking-bell-icon-gif"
                  ></img>
                  &nbsp; &nbsp; &nbsp;
                  <h4>Upcoming Booking</h4>
                </div>
                <div className="home-up-service-nm">
                  {dbBookingsDocuments && dbBookingsDocuments.length == 1 ? (
                    <h5>
                      {latestUserBooking && latestUserBooking.productName}
                    </h5>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center home_right-aroe-ico">
              <span class="material-symbols-outlined">
                keyboard_arrow_right
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <Link
          to={user ? "/products" : "/login"}
          style={{ color: "var(--black-color)" }}
        >
          <div className="upcom-booking-home-parent">
            <div className="col-5">
              <div className="d-flex flex-column justify-content-center align-items-center home-dte-child-div">
                <div className="home-up-date">
                  <>
                    <h1>Book Now</h1>
                  </>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column justify-content-center align-items-center home-day-child-div">
                <div className="home-up-upcom-text">
                  <h4>Book Your Appointment</h4>
                </div>
              </div>
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center home_right-aroe-ico">
              <span class="material-symbols-outlined">
                keyboard_arrow_right
              </span>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default UpcomingBookingsCard;
