import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Footer() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const showLogin = () => {
    navigate("/signup");
  };

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <footer
        style={{
          backgroundImage: "url('/assets/img/footerbg.png')",
        }}
      >
        <div className="newsletter-area">
          <div className="container">
            <div className="row newsletter-wrap align-items-center">
              <div className="row">
                <div className="newsletter-item col-12 col-md-8 col-lg-8 d-flex flex-column justify-content-center">
                  <h2>Join the Dentamax Community</h2>
                  <p>Don't miss out on receiving latest offers</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="tabby-tamara-payment-parent">
                    <img
                      src="https://www.pfgrowth.com/wp-content/uploads/2023/03/tabby-logo-1.png"
                      alt="tabby img"
                      className="tabby-logo"
                    />
                    <img
                      src="https://assets-global.website-files.com/622f49a4352dfc1131b9c1d0/62bb1cb42f682a7472585f2e_Tamara_Logo-EN.png"
                      alt="tamara img"
                      className="tamara-logo"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="newsletter-item">
                  <div className="newsletter-form">
                    <form className="newsletter-form">
                      {!user && (
                        <div>
                          <input
                            type="email"
                            placeholder="Enter Your Email"
                            name="EMAIL"
                            className="form-control"
                          />
                          <Link to="/signup">
                            <button
                              onClick={showLogin}
                              type="submit"
                              className="btn_fill newsletter-btn"
                            >
                              Subscribe
                            </button>
                          </Link>
                        </div>
                      )}
                      {user && (
                        <div>
                          <div>
                            <input
                              type="email"
                              placeholder={user.email}
                              value={user.email}
                              name="EMAIL"
                              className="form-control"
                            />
                            <button
                              type="submit"
                              className="btn_fill newsletter-btn"
                            >
                              Subscribed
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3 column-parent">
              <div className="footer-item">
                <div className="footer-contact">
                  <h3>Dentamax Contact</h3>
                  <ul>
                    <li>
                      <img
                        alt="emailicon img"
                        src="/assets/img/emailicon.png"
                        className="footer_icon"
                      ></img>
                      <div>
                        <a href="mailto:hello@dentamaxclinic.com">
                          hello@dentamaxclinic.com
                        </a>
                      </div>
                    </li>
                    <br></br>
                    <li>
                      <img
                        alt="footer icon img"
                        src="/assets/img/phone-callicon.png"
                        className="footer_icon"
                      ></img>
                      <div>
                        <a href="tel:+97142636717">+971 42 636 717</a>
                        <a href="tel:+971545171799">+971 54 517 1799</a>
                      </div>
                    </li>
                    <br></br>
                    <li>
                      <div>
                        Dentamax Medical Center LLC
                        <br />
                        Belresheed Bldg. <br />
                        Office 101-102 Al Nahda Street
                        <br />
                        Al Qusais 2, Dubai
                      </div>
                    </li>
                    <br />
                    <br />
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 column-parent">
              <div className="footer-item">
                <div className="footer-contact">
                  <h3>Velcare Contact</h3>
                  <ul>
                    <li>
                      <img
                        alt="email img"
                        src="/assets/img/emailicon.png"
                        className="footer_icon"
                      ></img>
                      <div>
                        <a href="mailto:info@velcaredentalclinic.com">
                          info@velcaredentalclinic.com
                        </a>{" "}
                      </div>
                    </li>
                    <br></br>
                    <li>
                      <img
                        alt="footer img"
                        src="/assets/img/phone-callicon.png"
                        className="footer_icon"
                      ></img>
                      <div>
                        <a href="tel:+97142858755">+971 4 285 8755</a>
                        <a href="tel:+971522715060">+971 52 271 5060</a>
                      </div>
                    </li>
                    <br></br>
                    <li>
                      <div>
                        Velcare Orthodontic & Dental Clinic
                        <br />
                        Ground Floor, <br />
                        Al Mashroom Building, 23 Street
                        <br />
                        Al Warqa 1, Dubai
                      </div>
                    </li>
                    <br />
                    <br />
                    {/* <div className="social_links_parent">
                            <a
                              className="social_links"
                              target="_blank"
                              href="https://www.youtube.com/@DentamaxMedical/about"
                            >
                              <img
                                src="/assets/img/youtube-icon.png"
                                className="youtube_icon"
                              ></img>
                            </a>
                            <a
                              target="_blank"
                              className="social_links"
                              href="https://www.instagram.com/dentamaxmedicalcenter/"
                            >
                              <img
                                src="/assets/img/insta-icon.png"
                                className="insta_icon"
                              ></img>
                            </a>
                            <a
                              target="_blank"
                              className="social_links"
                              href="https://www.threads.net/@dentamaxmedicalcenter"
                            >
                              <img
                                src="/assets/img/threads-icon.png"
                                className="threads_icon"
                              ></img>
                            </a>
                            <a
                              target="_blank"
                              className="social_links"
                              href="https://twitter.com/home?lang=en-in"
                            >
                              <img
                                src="/assets/img/twitter-icon.png"
                                className="twitter_icon"
                              ></img>
                            </a>
                            <a
                              target="_blank"
                              className="social_links"
                              href="https://www.facebook.com/dentamaxmedicalcenter/"
                            >
                              <img
                                src="/assets/img/facebook-icon.png"
                                className="facebook_icon"
                              ></img>
                            </a>
                          </div> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 column-parent">
              <div className="footer-item">
                <div className="footer-quick">
                  <h3>Our Services</h3>
                  <ul>
                    <li>
                      <a href="#services-section">Dental</a>
                    </li>
                    <li>
                      <a href="#skin_services">Dermatology</a>
                    </li>
                    <li>
                      <Link to="/services">
                        {" "}
                        <a>Homeopathy</a>
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/services">
                        {" "}
                        <a>Hijama</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 column-parent">
              <div className="footer-item">
                <div className="footer-quick">
                  <h3>Quick Links</h3>
                  <ul>
                    <Link to="/our-team">
                      <li>Our Team</li>
                    </Link>
                    <Link to="/contactus">
                      <li>Contact Us</li>
                    </Link>
                    <Link to="/about">
                      <li>About</li>
                    </Link>
                    <Link to="/privacy-policy">
                      <li>Privacy policy</li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>

            {/* start social sites section */}
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="social_links_parent">
                    <a
                      className="social_links"
                      target="_blank"
                      href="https://www.youtube.com/@DentamaxMedical/about"
                    >
                      <img
                        alt="youtube logo"
                        src="/assets/img/youtube-icon.png"
                        className="youtube_icon"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      className="social_links"
                      href="https://www.instagram.com/dentamaxmedicalcenter/"
                    >
                      <img
                        src="/assets/img/insta-icon.png"
                        alt="insta logo img"
                        className="insta_icon"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      className="social_links"
                      href="https://www.threads.net/@dentamaxmedicalcenter"
                    >
                      <img
                        alt="threads logo img"
                        src="/assets/img/threads-icon.png"
                        className="threads_icon"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      className="social_links"
                      href="https://twitter.com/home?lang=en-in"
                    >
                      <img
                        alt="x logo img"
                        src="/assets/img/x-logo.webp"
                        className="twitter_icon"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      className="social_links"
                      href="https://www.facebook.com/dentamaxmedicalcenter/"
                    >
                      <img
                        alt="facebook logo"
                        src="/assets/img/facebook-icon.png"
                        className="facebook_icon"
                      ></img>
                    </a>
                    <a
                      target="_blank"
                      className="social_links"
                      href="https://www.snapchat.com/add/dentamaxmedical"
                    >
                      <img
                        alt="snapchat logo img"
                        src="/assets/img/snapchat-icon.webp"
                        className="snapchat_icon"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="d-flex justify-content-center">
                  <img
                    alt="footer img logo"
                    className="footer-img-logo"
                    src="/assets/img/22-logo.webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-item">
            <p>© {currentYear} All rights reserved</p>
            <p>
              Designed & Developed By <br />
              <a
                href="https://www.hyperclouddigital.com/"
                target="_blank"
                style={{
                  color: "var(--pink)",
                }}
              >
                <b>Hyper Cloud Digital Solutions</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
