import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";
import { projectFirestore, projectStorage } from "../../firebase/config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useRef } from "react";
import Popup from "../../components/Popup";
// import owl carousel
import ReactSwitch from "react-switch";
import TextEditor from "../../components/TextEditor.jsx";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import { FaEdit } from "react-icons/fa";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ServiceDetails.css";
import OfferBanners from "../../components/OfferBanners";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(rating) {
  return `${rating} Star${rating !== 1 ? "s" : ""}, ${labels[rating]}`;
}

const ServiceDetails = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // delete document
  const { deleteDocument } = useFirestore("services");
  // delete popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const { updateDocument, updateDocumentError } = useFirestore("services");

  const fileInputRef = useRef(null);
  const { user } = useAuthContext();
  const [isHeartClicked, setIsHeartClicked] = useState(false);
  const [checked, setChecked] = useState(); // Toggle button state
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedPrice, setEditedPrice] = useState("");
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [editedDiscount, setEditedDiscount] = useState("");
  const [isEditingShortDescription, setIsEditingShortDescription] =
    useState(false);
  const [editedShortDescription, setEditedShortDescription] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productDiscount, setProductDiscount] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [rating, setRating] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  const { productId } = useParams();
  const [productImages, setProductImages] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  // Use selectedProductId for fetching document and error
  const { document, error } = useDocument(
    "services",
    selectedProductId || productId
  );
  // Function to handle image click and update selectedProductId
  const handleImageClick = (productId) => {
    setSelectedProductId(productId); // Update selectedProductId with the clicked product ID
    window.history.replaceState(null, null, `/services-details/${productId}`);
  };

  useEffect(() => {
    // Fetch document data and set editedName
    if (document) {
      setProductName(document.sub_service);
      setEditedName(document.sub_service); // Initialize editedName with the product name
      setProductPrice(document.price);
      setEditedPrice(document.price);
      setProductShortDescription(document.short_description);
      setEditedShortDescription(document.short_description);
      setProductDiscount(document.discount);
      setEditedDiscount(document.discount);
      setProductDescription(document.description);
      setEditedDescription(document.description);
      setProductImages(document.images || []);
      // setRating(document.rating || 0);
    }
  }, [document]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all services
        const servicesCollection = await projectFirestore
          .collection("services")
          .get();
        const servicesData = [];

        servicesCollection.forEach((doc) => {
          const product = doc.data();
          servicesData.push({
            id: doc.id,
            name: product.sub_service,
            thumbnail: product.product_img_1,
            status: product.status,
          });
        });

        setAllProducts(servicesData);

        // Find the selected product in the fetched services
        const selectedProduct = servicesData.find(
          (product) => product.id === productId
        );

        if (selectedProduct) {
          // Update the 'checked' state based on the current status
          setChecked(selectedProduct.status === "active");

          // Fetch product images for the selected product
          const productDoc = await projectFirestore
            .collection("services")
            .doc(productId)
            .get();
          if (productDoc.exists) {
            const productData = productDoc.data();
            const imageUrls = [];

            for (let i = 1; productData[`product_img_${i}`]; i++) {
              imageUrls.push(productData[`product_img_${i}`]);
            }
            setProductImages(imageUrls);
          }

          // Optionally, you can scroll to the top of the page or perform other actions
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed
      }
    };

    // Call the function to fetch all services and product images
    fetchData();
  }, [productId]);

  //START  OWL CAROUSEL
  useEffect(() => {
    // Initialize Owl Carousel with the fetched products
    window.$(".owl-carousel").owlCarousel({
      className: "owl-theme",
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 5000,
      smartSpeed: 4000,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }, [allProducts]);
  //END OWL CAROUSEL

  useEffect(() => {
    const fetchProductImages = async () => {
      try {
        const productDoc = await projectFirestore
          .collection("services")
          .doc(productId)
          .get();
        if (productDoc.exists) {
          const productData = productDoc.data();
          const imageUrls = [];

          for (let i = 1; productData[`product_img_${i}`]; i++) {
            imageUrls.push(productData[`product_img_${i}`]);
          }
          setProductImages(imageUrls);
        }
      } catch (error) {
        console.error("Error fetching product images:", error);
      }
    };

    fetchProductImages();
  }, [productId]);

  if (error) {
    return (
      <div className="error" style={{ marginTop: "100px" }}>
        Data Not Found
      </div>
    );
  }
  if (!document) {
    return <div className="loading">Loading...</div>;
  }

  const handleImageChange = async (event) => {
    const files = event.target.files;
    const updatedImages = []; // Create a new array to store updated images

    try {
      // Upload new images to Firebase Storage and retrieve download URLs
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const storageRef = projectStorage.ref(
          `product_img/${productId}/${file.name}`
        );
        await storageRef.put(file);

        // Get the download URL for the uploaded file
        const downloadURL = await storageRef.getDownloadURL();

        // Push the download URL to the updatedImages array
        updatedImages.push(downloadURL);
      }

      // Get the current images array from the document
      const currentImages = document.images || [];

      // Combine the current images with the new images
      const allImages = [...currentImages, ...updatedImages];

      // Update the "images" array in Firestore
      await projectFirestore
        .collection("services")
        .doc(productId)
        .update({ images: allImages });

      // Update the state with the updatedImages array
      setProductImages(allImages);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };
  // Fetch images array from the document state
  const images = document.images || [];

  const handleAddMoreImages = () => {
    // Trigger file input click using ref
    fileInputRef.current.click();
  };

  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setIsEditingName(true);
        break;
      case "price":
        setIsEditingPrice(true);
        break;
      case "discount":
        setIsEditingDiscount(true);
        break;
      case "shortdescription":
        setIsEditingShortDescription(true);
        break;
      case "description":
        setIsEditingDescription(true);
        break;
      default:
        break;
    }
  };

  const handleSaveClick = async (fieldName) => {
    try {
      switch (fieldName) {
        case "name":
          setIsEditingName(false);
          break;
        case "price":
          setIsEditingPrice(false);
          break;
        case "discount":
          setIsEditingDiscount(false);
          break;
        case "shortdescription":
          setIsEditingShortDescription(false);
          break;
        case "description":
          setIsEditingDescription(false);
          break;
        // Add more cases for other fields if needed
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setEditedName(document.name);
        setIsEditingName(false);
        break;
      case "price":
        setEditedPrice(document.price);
        setIsEditingPrice(false);
        break;
      case "discount":
        setEditedDiscount(document.discount);
        setIsEditingDiscount(false);
        break;
      case "shortdescription":
        setEditedShortDescription(document.short_description);
        setIsEditingShortDescription(false);
        break;
      case "description":
        setEditedDescription(document.description);
        setIsEditingDescription(false);
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };

  const handleToggleChange = (productId, currentStatus) => {
    console.log(currentStatus);
    setChecked(!checked);
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    projectFirestore
      .collection("services")
      .doc(productId)
      .update({ status: newStatus })
      .then(() => {
        console.log("Product status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };

  // RATING CODE START
  const handleRatingChange = (newValue) => {
    // Prevent setting the rating to null
    const newRating = newValue !== null ? newValue : rating;

    setRating(newRating);
    // Update the rating field in the Firebase document
    updateDocument(productId, { rating: newRating })
      .then(() => console.log("Rating updated successfully"))
      .catch((error) => console.error("Error updating rating:", error));
  };
  // END RATING CODE

  // delete document code
  const handleDeleteProduct = async () => {
    try {
      // Open the delete confirmation popup
      setIsDeletePopupOpen(true);
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };
  const handleConfirmDeleteProduct = async () => {
    try {
      await deleteDocument(productId);
      // Redirect to the services page
      window.location.href = "/services";
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };
  return (
    <>
      <div className="services-details-parent-div">
        <Popup
          showPopupFlag={isDeletePopupOpen}
          setShowPopupFlag={setIsDeletePopupOpen}
          msg="Are you sure you want to delete this service?"
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              handleConfirmDeleteProduct();
            }
          }}
        />
        <div className="container">
          <div className="services_heading more_carousel_services_heading">
            <h1 id="top_heading">More Services</h1>
          </div>
          <div className="owl-carousel owl-theme carousel_services more-products-carosel">
            {allProducts &&
              allProducts
                .filter((product) => product.status === "active") // Filter by active status
                .map((product) => (
                  <div
                    key={product.id}
                    className="item carousel_img_parent"
                    onClick={() => handleImageClick(product.id)}
                  >
                    <img
                      src={product.thumbnail} // Use the URL of the first image
                      alt={product.sub_service}
                      className="more_services_carousel_img"
                    />
                    <h2 className="more_services_img_heading">
                      <div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: product.name.toString("html"),
                          }}
                        ></p>
                      </div>
                    </h2>
                  </div>
                ))}
          </div>

          <div
            className="d-flex justify-content-center product-details-pg-srvc-heading"
            style={{
              backgroundColor: "var(--click-color)",
              height: "100px",
              borderRadius: "20px",
            }}
          >
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: document.sub_service.toString("html"),
                }}
              ></p>
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: document.service.toString("html"),
                }}
              ></p>
            </div>
          </div>

          <div className="services-images-slider-div-child">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="pcs_image_area">
                  <div className="bigimage_container">
                    <Gallery
                      items={images
                        .filter((url) => url)
                        .map((url) => ({
                          original: url,
                          thumbnail: url,
                        }))}
                      slideDuration={1000}
                    />
                    {/* Box for adding more images */}
                    {/* {user && user.role == "admin" && (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          multiple
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <button
                          className="btn_fill"
                          onClick={handleAddMoreImages}
                        >
                          Add More Images
                        </button>
                      </div>
                    )} */}

                    {/* Box for adding more images */}
                    {user && user.role == "admin" && (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          multiple
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <button
                          className="btn_fill"
                          onClick={handleAddMoreImages}
                        >
                          Add More Images
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Category:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {/* {isEditingName ? (
                            <div>
                              <input
                                type="text"
                                value={editedName}
                                onChange={(e) => setEditedName(e.target.value)}
                              />
                              <div className="d-flex">
                                <button
                                  className="product_edit_save_btn"
                                  onClick={handleSaveNameClick}
                                >
                                  Save
                                </button>
                                <button
                                  className="product_edit_save_btn cancel-btn"
                                  onClick={handleCancelNameEdit}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          ) : (
                            <> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: document.service.toString("html"),
                            }}
                          ></p>
                          {/* </>
                          )} */}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>

                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Name:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingName ? (
                            <div>
                              <TextEditor
                                initialDescription={editedName}
                                docFieldName="sub_service"
                                documentId={productId}
                                collectionName="services"
                                onSave={() => handleSaveClick("name")}
                                onCancel={() => handleCancelClick("name")}
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: document.sub_service.toString("html"),
                                }}
                              ></p>
                              {!isEditingName &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() => handleEditClick("name")}
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>

                    <hr />
                    {document.rating && (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Rating:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <MDBRow>
                                <div className="d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: 200,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Rating
                                      name="hover-feedback"
                                      value={parseFloat(document.rating)}
                                      precision={0.5}
                                      readOnly={
                                        (user && user.role !== "admin") || !user
                                      } // Set readOnly based on isAdmin condition
                                      getLabelText={getLabelText}
                                      onChange={(event, newValue) =>
                                        handleRatingChange(newValue)
                                      }
                                      onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                      }}
                                      emptyIcon={
                                        <StarIcon
                                          style={{ opacity: 0.45 }}
                                          fontSize="inherit"
                                        />
                                      }
                                    />
                                  </Box>
                                </div>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}

                    {/* <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Price:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingPrice ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedPrice}
                                  docFieldName="price"
                                  documentId={productId}
                                  collectionName="services"
                                  onSave={() => handleSaveClick("price")}
                                  onCancel={() => handleCancelClick("price")}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: document.price.toString("html"),
                                }}
                              ></p>
                              {!isEditingName &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() => handleEditClick("price")}
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    {document.discount ? (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Discount:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {isEditingDiscount ? (
                                <div>
                                  <TextEditor
                                    initialDescription={editedDiscount}
                                    docFieldName="discount"
                                    documentId={productId}
                                    collectionName="services"
                                    onSave={() => handleSaveClick("discount")}
                                    onCancel={() =>
                                      handleCancelClick("discount")
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="d-flex align-items-center">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        document.discount.toString("html"),
                                    }}
                                  ></p>
                                  {!isEditingDiscount &&
                                    user &&
                                    user.role == "admin" && (
                                      <FaEdit
                                        style={{
                                          fontSize: 20,
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          color: "var(--pink)",
                                        }}
                                        onClick={() =>
                                          handleEditClick("discount")
                                        }
                                      />
                                    )}
                                </div>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : null} */}

                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Short Description:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingShortDescription ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedShortDescription}
                                  docFieldName="short_description"
                                  documentId={productId}
                                  collectionName="services"
                                  onSave={() =>
                                    handleSaveClick("shortdescription")
                                  }
                                  onCancel={() =>
                                    handleCancelClick("shortdescription")
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document.short_description.toString("html"),
                                }}
                              ></p>
                              {!isEditingShortDescription &&
                                user &&
                                user.role == "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() =>
                                      handleEditClick("shortdescription")
                                    }
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    {user && user.role == "admin" && (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Status: </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex">
                                <div>
                                  <p style={{ marginTop: "2px" }}> Inactive</p>
                                </div>
                                <ReactSwitch
                                  checked={checked}
                                  onChange={() =>
                                    handleToggleChange(
                                      document.id,
                                      document.status
                                    )
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p style={{ marginTop: "2px" }}>Active</p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                      </>
                    )}
                    <div className="d-flex align-items-center namesd">
                      <MDBCol sm="3" className="d-flex">
                        <MDBCardText style={{ cursor: "pointer" }}>
                          <span
                            className="material-symbols-outlined"
                            onClick={shareURL}
                            style={{ fontSize: "23px", padding: "6px 0px" }}
                          >
                            share
                          </span>
                        </MDBCardText>
                        <MDBCardText
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            className={`material-symbols-outlined ${
                              isHeartClicked ? "heart-pink" : ""
                            }`}
                            style={{ fontSize: "23px", padding: "6px 0px" }}
                            onClick={handleHeartClick}
                          >
                            favorite
                          </span>
                        </MDBCardText>

                        {user && user.role == "admin" && (
                          <MDBCardText
                            onClick={handleDeleteProduct}
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "23px", padding: "6px 0px" }}
                            >
                              delete {/* Render the delete icon */}
                            </span>
                          </MDBCardText>
                        )}
                      </MDBCol>

                      {/* <Link to="/contactus">
                        <button
                          type="submit"
                          className="booking_services_book_now_btn"
                        >
                          Book Now
                        </button>
                      </Link> */}
                      {/* {user ? (
                        <div className="more-div-big-icon-div-inner pointer">
                          {user.role == "customer" ? (
                            <Link
                              to={`/addbooking?doctor=${encodeURIComponent(
                                document.service
                              )}&imageURL=${encodeURIComponent(
                                document.product_img_1
                              )}&productId=${encodeURIComponent(
                                document.productId
                              )}`}
                            >
                              <button
                                type="submit"
                                className="booking_services_book_now_btn"
                              >
                                Book Now
                              </button>
                            </Link>
                          ) : user.role == "admin" ? (
                            <div>
                              &nbsp;
                              <Link to={`/setupslots/${productId}`}>
                                <button className="theme_btn btn_fill">
                                  Setup Slot
                                </button>
                              </Link>
                            </div>
                          ) : user.role == "frontdesk" ? (
                            <Link
                              to={`/addbooking?doctor=${encodeURIComponent(
                                document.service
                              )}&imageURL=${encodeURIComponent(
                                document.product_img_1
                              )}&productId=${encodeURIComponent(
                                document.productId
                              )}`}
                            >
                              <button
                                type="submit"
                                className="booking_services_book_now_btn"
                              >
                                Book Now
                              </button>
                            </Link>
                          ) : user.role == "doctor" ? (
                            <Link to={`/setupslots/${productId}`}>
                              <button className="theme_btn btn_fill">
                                Setup Slot
                              </button>
                            </Link>
                          ) : null}
                        </div>
                      ) : (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link
                            to={`/addbooking?doctor=${encodeURIComponent(
                              document.service
                            )}&imageURL=${encodeURIComponent(
                              document.product_img_1
                            )}&productId=${encodeURIComponent(
                              document.productId
                            )}`}
                          >
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Book Now
                            </button>
                          </Link>
                        </div>
                      )} */}
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
          </div>

          <OfferBanners />

          <div className="services_details_bottom_details_parent">
            <section className="services_details_bottom_details_child">
              <MDBRow className="justify-content-center align-items-center">
                <MDBCard style={{ borderRadius: "15px" }}>
                  <MDBCardBody className="p-4">
                    <MDBTypography tag="h3">
                      <strong style={{ color: "var(--pink)" }}>
                        Description
                      </strong>
                    </MDBTypography>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-start align-items-center">
                      <MDBCardText className="mb-0">
                        <MDBCardText className="text-muted">
                          {isEditingDescription ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedDescription}
                                  docFieldName="description"
                                  documentId={productId}
                                  collectionName="services"
                                  onSave={() => handleSaveClick("description")}
                                  onCancel={() =>
                                    handleCancelClick("description")
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      document.description.toString("html"),
                                  }}
                                ></p>
                                {!isEditingPrice &&
                                  user &&
                                  user.role == "admin" && (
                                    <FaEdit
                                      style={{
                                        fontSize: 20,
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        color: "var(--pink)",
                                      }}
                                      onClick={() =>
                                        handleEditClick("description")
                                      }
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCardText>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
