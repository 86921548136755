import React, { useEffect } from "react";
import { useCollection } from "../hooks/useCollection";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDocument } from "../hooks/useDocument";
// components
import Filters from "../components/Filters";
import BookingList from "../components/BookingList";
import AddBookingIcon from "../components/AddBookingIcon";
import * as XLSX from "xlsx";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isWithinInterval,
  parseISO,
} from "date-fns";

// styles
import "./AllCustomersBookings.css";
const bookingFilter = ["UPCOMING", "COMPLETED", "CANCELLED"];

export default function PGCustomerBooking() {
  const { user } = useAuthContext();
  const isAdmin = user && user.role === "admin";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeFilter = queryParams.get("filter");
  const [filter, setFilter] = useState(activeFilter || "UPCOMING");
  const [searchTerm, setSearchTerm] = useState("");

  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isUpcomingSelected, setIsUpcomingSelected] = useState(false);
  const [isCompletedSelected, setIsCompletedSelected] = useState(false);
  const [isCancelledSelected, setIsCancelledSelected] = useState(false);
  const [dateFilter, setDateFilter] = useState(null); // State to handle date filter
  const [activeButton, setActiveButton] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState("selectyear"); // State to hold selected year

  const { documents: bookingdocuments, error: bookingerror } = useCollection(
    "bookings",
    null,
    ["bookingDate", "asc"]
  );

  const { document: bookingUser, error: bookingUserError } = useDocument(
    "users",
    user.uid
  );

  const { documents: userMappings, error: userMappingsError } = useCollection(
    "product_user_mapping",
    ["userId", "==", user.uid]
  );

  const productIds = [];

  if (userMappingsError) {
    console.error("Error fetching user mappings:", userMappingsError);
  } else {
    if (userMappings && userMappings.length > 0) {
      userMappings.forEach((mapping, index) => {
        const mappedProductId = mapping.productId;
        productIds.push(mappedProductId);
      });
    } else {
      // console.log("No user mappings found or userMappings is undefined");
    }
  }

  const filteredByProductIds =
    bookingdocuments &&
    bookingdocuments.filter((booking) =>
      productIds.includes(booking.productId)
    );

  const filteredByUser =
    bookingdocuments &&
    bookingdocuments.filter((booking) => booking.createdBy === user.uid);

  const userBookings = isAdmin
    ? bookingdocuments // If user is admin, show all bookings
    : [...(filteredByProductIds || []), ...(filteredByUser || [])];

  const { documents: productsdocuments, error: productserror } =
    useCollection("products");

  const hasActiveProducts =
    productsdocuments &&
    productsdocuments.some((product) => product.status === "active");

  const changeFilter = (newFilter) => {
    setFilter(newFilter);
    setIsUpcomingSelected(newFilter === "UPCOMING");
    setIsCompletedSelected(newFilter === "COMPLETED");
    setIsCancelledSelected(newFilter === "CANCELLED");
    setSelectedYear("selectyear"); // Reset selected year when filter changes
  };

  useEffect(() => {
    if (filter === "UPCOMING") {
      setIsUpcomingSelected(true);
    }
  }, [filter]);

  // Filter bookings based on selected date filter
  const applyDateFilter = (bookings, dateFilter) => {
    if (!dateFilter) return bookings;

    return bookings.filter((booking) => {
      const bookingDate = parseISO(booking.bookingDate.toDate().toISOString()); // Convert Firestore timestamp to Date object
      return isWithinInterval(bookingDate, {
        start: dateFilter.start,
        end: dateFilter.end,
      });
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // Create a map of productId to productName
  const productIdNameMap = {};
  if (productsdocuments) {
    productsdocuments.forEach((product) => {
      productIdNameMap[product.id] = product.name;
    });
  }

  const filteredBookings = userBookings
    ? applyDateFilter(
        userBookings
          .map((document) => ({
            ...document,
            productName: productIdNameMap[document.productId],
          }))
          .filter((document) => {
            let filteredBookings = false;
            // const productNameMatch = document.productName
            //   .toLowerCase()
            //   .includes(searchTerm.toLowerCase());
            const nameMatch =
              (document.category === "self" &&
                document.fullName &&
                document.fullName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())) ||
              (document.category !== "self" &&
                document.nameForOther &&
                document.nameForOther
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()));
            const phoneMatch =
              document.phoneForOther &&
              document.phoneForOther
                .toLowerCase()
                .includes(searchTerm.toLowerCase());

            if (searchTerm && !nameMatch && !phoneMatch) {
              return false;
            }

            switch (filter) {
              case "UPCOMING":
                if (
                  document.status === "approved" ||
                  document.status === "upcoming"
                ) {
                  filteredBookings = true;
                }
                return filteredBookings;
              case "COMPLETED":
                if (document.status === "completed") {
                  filteredBookings = true;
                }
                return filteredBookings;
              case "CANCELLED":
                if (document.status === "rejected") {
                  filteredBookings = true;
                }
                return filteredBookings;

              default:
                return true;
            }
          }),
        dateFilter
      )
    : null;

  // START PHONE VIEW FILTER
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
    if (!isCardVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };
  // END PHONE VIEW FILTER
  const handleDownloadExcel = (filter) => {
    const bookingsToDownload = userBookings
      ? applyDateFilter(
          userBookings.filter((document) => {
            switch (filter) {
              case "UPCOMING":
                return (
                  document.status === "approved" ||
                  document.status === "upcoming"
                );
              case "COMPLETED":
                return document.status === "completed";
              case "CANCELLED":
                return document.status === "rejected";
              default:
                return false;
            }
          }),
          dateFilter
        )
      : [];

    console.log("booking", bookingsToDownload);

    const worksheet = XLSX.utils.json_to_sheet(
      bookingsToDownload.map((booking) => ({
        BookingId: booking.id,
        BookingFor:
          booking && booking.bookingFor == "self"
            ? bookingUser && bookingUser.displayName
            : booking && booking.nameForOther,
        MobileNumber:
          booking && booking.bookingFor == "self"
            ? bookingUser && bookingUser.phoneNumber
            : booking && booking.nameForOther,
        Status: booking.status,
        ServiceName: booking.productName,
        BookingReason: booking.bookingReason,
        BookingDate: booking.createdAt
          ? booking.createdAt.toDate().toLocaleDateString()
          : "",
        BookedDate: booking.bookingDate
          ? booking.bookingDate.toDate().toLocaleDateString()
          : "",
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");

    const fileName = `bookings_${filter.toLowerCase()}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    // const nextYear = currentYear + 1;
    const years = [
      currentYear - 3,
      currentYear - 2,
      currentYear - 1,
      currentYear,
      // nextYear,
    ];
    setYearOptions(years.map((year) => `year${year}`));
  }, []);

  // FILTERS BY DATE, MONTH, YEAR
  const handleDateFilter = (range) => {
    setActiveButton(range); // Set the active button state
    setSelectedYear("selectyear"); // Reset selected year when filter changes
    if (range === "week") {
      const start = startOfWeek(new Date(), { weekStartsOn: 1 });
      const end = endOfWeek(new Date(), { weekStartsOn: 1 });
      setDateFilter({ start, end });
    } else if (range === "month") {
      const start = startOfMonth(new Date());
      const end = endOfMonth(new Date());
      setDateFilter({ start, end });
    } else if (range === "year") {
      const start = startOfYear(new Date());
      const end = endOfYear(new Date());
      setDateFilter({ start, end });
    } else {
      setDateFilter(null);
    }
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;

    if (selectedYear === "selectyear") {
      setDateFilter(null);
      setActiveButton(null);
      setSelectedYear("selectyear"); // Reset selected year to "selectyear"
    } else {
      const year = parseInt(selectedYear.substring(4), 10);
      const start = startOfYear(new Date(year, 0, 1));
      const end = endOfYear(new Date(year, 11, 31));

      setDateFilter({ start, end });
      setActiveButton(null);
      setSelectedYear(selectedYear); // Set selected year to the chosen year
    }
  };

  return (
    <div className="pgadmindasboard pg_padding">
      <AddBookingIcon />
      <div className="pgadmindasboard-bookingdashboard-child">
        <div
          className="d-flex align-items-center justify-content-between mb-3"
          style={{ margin: "auto", maxWidth: "95%" }}
        >
          <div className="products_heding_contner" style={{ width: "50%" }}>
            <h4>Booking History</h4>
            <h6>Explore Your Booking Records</h6>
          </div>
          {(isAdmin ||
            (productsdocuments &&
              productsdocuments.length !== 0 &&
              hasActiveProducts)) && (
            <div>
              <Link to="/products">
                <button className="btn_fill">Create Booking</button>
              </Link>
            </div>
          )}
        </div>
        {bookingerror && <p className="error">{bookingerror}</p>}
        {bookingdocuments && (
          <Filters
            changeFilter={changeFilter}
            filterList={["UPCOMING", "COMPLETED", "CANCELLED"]}
            filterLength={filteredBookings.length}
          />
        )}
        {bookingdocuments && bookingdocuments.length > 0 ? (
          <div
            className={
              user && user.role === "customer"
                ? isUpcomingSelected || isCancelledSelected
                  ? "is_upcoming_selected_class"
                  : "search_and_week_days_parent"
                : "search_and_week_days_parent"
            }
          >
            {/*START INPUT TEXT SEARCH BAR FILTER */}
            <div className="filter_search mb-2 pt-2">
              {user && user.role === "customer" && (
                <>
                  <div className="search_bar">
                    <input
                      type="text"
                      placeholder="Search By Phone, Product Name..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <div className="search_icon">
                      <span className="material-symbols-outlined">search</span>
                    </div>
                  </div>
                </>
              )}
              {/* START DATE & DAY NAME BUTTONS */}
              <div className="week-year-month-btns-div">
                <div
                  className={
                    user && user.role === "admin"
                      ? isUpcomingSelected
                        ? "search_and_week_days_parent"
                        : "is_upcoming_selected_class"
                      : "is_upcoming_selected_class"
                  }
                >
                  <button
                    onClick={() => handleDateFilter("week")}
                    className={
                      activeButton === "week"
                        ? "bookings-filter-active-btn"
                        : "bookings-filter-inactive-btn"
                    }
                  >
                    This Week
                  </button>
                </div>
                <div
                  className={
                    user && user.role === "admin"
                      ? isCancelledSelected
                        ? "is_upcoming_selected_class"
                        : "search_and_week_days_parent"
                      : "search_and_week_days_parent"
                  }
                >
                  <button
                    onClick={() => handleDateFilter("month")}
                    className={
                      activeButton === "month"
                        ? "bookings-filter-active-btn"
                        : "bookings-filter-inactive-btn"
                    }
                  >
                    This Month
                  </button>
                </div>
                <div
                  className={
                    user && user.role === "admin"
                      ? isUpcomingSelected
                        ? "is_upcoming_selected_class"
                        : "search_and_week_days_parent"
                      : "search_and_week_days_parent"
                  }
                >
                  <button
                    onClick={() => handleDateFilter("year")}
                    className={
                      activeButton === "year"
                        ? "bookings-filter-active-btn"
                        : "bookings-filter-inactive-btn"
                    }
                  >
                    This Year
                  </button>
                </div>
                {/* END DATE & DAY NAME BUTTONS */}
                <div className="filter_dropdown yearly d-flex">
                  <select
                    className="search_y_year"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    <option value="selectyear">Select Year</option>
                    {yearOptions.map((yearOption, index) => (
                      <option key={index} value={yearOption}>
                        {yearOption.substring(4)}
                      </option>
                    ))}
                  </select>
                  {user && user.role === "admin" && (
                    <div className="excel-icon-logo d-flex align-items-center justify-content-center">
                      <img
                        src="./assets/img/exel_icon.webp"
                        alt="Excel Icon"
                        className="booking_history_exel_icon"
                        onClick={
                          isUpcomingSelected
                            ? () => handleDownloadExcel("UPCOMING")
                            : isCancelledSelected
                            ? () => handleDownloadExcel("CANCELLED")
                            : isCompletedSelected
                            ? () => handleDownloadExcel("COMPLETED")
                            : null
                        }
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="material-symbols-outlined filters-icon-user-list"
                    onClick={toggleCardVisibility}
                    style={{ fontSize: "25px" }}
                  >
                    tune
                  </span>
                </div>
              </div>
              {isCardVisible && (
                <div>
                  <div
                    className="overlay-stop"
                    onClick={toggleCardVisibility}
                  ></div>
                  <div className="animated-card">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h5
                          style={{
                            padding: "15px",
                            fontWeight: "700",
                            color: "var(--pink)",
                          }}
                        >
                          Filter Bookings
                        </h5>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#e7e7e7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                          marginRight: "20px",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                        }}
                        onClick={toggleCardVisibility}
                      >
                        <span class="material-symbols-outlined">close</span>
                      </div>
                    </div>

                    <div className="filter_dropdown yearly">
                      <h6
                        style={{
                          margin: "5px 0px 10px 15px",
                          fontWeight: "600",
                          color: "var(--click-color)",
                        }}
                      >
                        By Date
                      </h6>
                      <select
                        className="search_y_year_mobile"
                        value={selectedYear}
                        onChange={handleYearChange}
                      >
                        <option value="selectyear">Select Year</option>
                        {yearOptions.map((yearOption, index) => (
                          <option key={index} value={yearOption}>
                            {yearOption.substring(4)}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <h6
                        style={{
                          margin: "20px 0px 10px 15px",
                          fontWeight: "600",
                          color: "var(--click-color)",
                        }}
                      >
                        By Status
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          className={`${
                            isCompletedSelected
                              ? "filter-user-selected-button"
                              : "search_roles_mobile"
                          }`}
                        >
                          This Month
                        </button>
                        <button
                          className={`${
                            isCancelledSelected
                              ? "filter-user-selected-button"
                              : "search_roles_mobile"
                          }`}
                        >
                          This Year
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "30px",
                      }}
                    >
                      <button
                        className="cancel_filter_btn_border"
                        onClick={toggleCardVisibility}
                      >
                        Cancel
                      </button>
                      <button
                        className="apply_filter_btn_border"
                        onClick={toggleCardVisibility}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*END INPUT TEXT SEARCH BAR FILTER */}
          </div>
        ) : (
          <div className="no-bookings-message mt-4">
            {filter === "UPCOMING" && <h5>No upcoming bookings available.</h5>}
            {filter === "COMPLETED" && (
              <h5>No completed bookings available.</h5>
            )}
            {filter === "CANCELLED" && (
              <h5>No cancelled bookings available.</h5>
            )}
          </div>
        )}
        <div className="bookingdetils_card_left">
          <div className="container-fluid">
            <div className="row">
              {filteredBookings &&
                filteredBookings.map((booking) => (
                  <BookingList booking={booking && booking} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
