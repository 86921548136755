// SnackBarNotification.jsx
import * as React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";

const SnackBar = ({ showSnackbar }) => {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (showSnackbar !== null) {
      if (showSnackbar) {
        enqueueSnackbar("Access Granted", { variant: "success" });
      } else {
        enqueueSnackbar("Access Removed", { variant: "warning" });
      }
    }
  }, [showSnackbar, enqueueSnackbar]);

  return null;
};

export default function SnackBarNotification({ showSnackbar }) {
  return (
    <SnackbarProvider maxSnack={3}>
      <SnackBar showSnackbar={showSnackbar} />
    </SnackbarProvider>
  );
}
