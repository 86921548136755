import React, { useEffect } from "react";
import "./ThankYou.scss";
import { useLocation, Link } from "react-router-dom";
const ThankYou = () => {
  const location = useLocation();

  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="thank-you-container">
      <div className="thank-you-content">
        <h1>Thank You!</h1>
        <p>Your submission has been received.</p>
        <p>We appreciate your time and will get back to you soon.</p>
        <img src="https://pngimg.com/d/thank_you_PNG139.png" alt="Thank You" />
        <Link to="/">
          <button>Return to Home</button>
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;
