import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDocument } from "../../hooks/useDocument";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";
import { projectFirestore, projectStorage } from "../../firebase/config";
import { useCollection } from "../../hooks/useCollection";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/icons-material/Close";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useRef } from "react";
import ReactSwitch from "react-switch";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Typography } from "@mui/material";
import TextEditor from "../../components/TextEditor";
import SnackBarNotification from "../../components/SnackBarNotification";

import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import { FaEdit } from "react-icons/fa";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ProductDetails.css";
import SetupSlots from "../../components/SetupSlots";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

import {
  Modal,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
} from "@mui/material";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(rating) {
  return `${rating} Star${rating !== 1 ? "s" : ""}, ${labels[rating]}`;
}
const ProductDetails = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const navigate = useNavigate(); // Ensure you're importing useNavigate correctly
  const { productId } = useParams();

  // Start firebase code
  const { user } = useAuthContext();
  const { deleteDocument } = useFirestore("products");

  const { deleteDocument: deleteProductUserMapping } = useFirestore(
    "product_user_mapping"
  );

  const { documents: userMappings, error: userMappingsError } = useCollection(
    "product_user_mapping",
    ["productId", "==", productId]
  );

  const { documents: userList, error: userListError } = useCollection("users");

  const { addDocument } = useFirestore("product_user_mapping");

  // Fetch existing mappings when the component mounts
  useEffect(() => {
    if (userMappings) {
      const userIds = userMappings.map((mapping) => mapping.userId);
      setSelectedUsers(userIds);
    }
  }, [userMappings]);

  const { documents: allBookings, error: bookingsError } =
    useCollection("bookings");

  const { updateDocument, updateDocumentError } = useFirestore("products");

  // End Firebase code

  const fileInputRef = useRef(null);
  const [isHeartClicked, setIsHeartClicked] = useState(false);
  const [checked, setChecked] = useState(); // Toggle button state
  const [editedName, setEditedName] = useState("");
  const [productName, setProductName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedPrice, setEditedPrice] = useState("");
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [productPrice, setProductPrice] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productDiscount, setProductDiscount] = useState("");
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [editedDiscount, setEditedDiscount] = useState("");
  const [AddOption, setAddOption] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);
  const [selctedOption, setOptions] = useState([]);
  // const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [isAddOption, setIsAddOption] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [editedShortDescription, setEditedShortDescription] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [isEditingShortDescription, setIsEditingShortDescription] =
    useState(false);
  const [productImages, setProductImages] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  // const [productRating, setProductRating] = useState(0.5);
  const [rating, setRating] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  // Use selectedProductId for fetching document and error
  const [showSnackbar, setShowSnackbar] = useState(null);

  const { document, error } = useDocument(
    "products",
    selectedProductId || productId
  );
  const selectedUserDetails =
    userList && userList.filter((user) => selectedUsers.includes(user.id));

  // Function to handle image click and update selectedProductId
  const handleImageClick = (productId) => {
    setSelectedProductId(productId); // Update selectedProductId with the clicked product ID
    window.history.replaceState(null, null, `/product-details/${productId}`);
  };

  useEffect(() => {
    // Fetch document data and set editedName
    if (document) {
      setProductName(document.name);
      setEditedName(document.name); // Initialize editedName with the product name
      setProductPrice(document.price);
      setEditedPrice(document.price);
      setProductDescription(document.description);
      setEditedDescription(document.description);
      setProductShortDescription(document.short_description);
      setEditedShortDescription(document.short_description);
      setProductDiscount(document.discount);
      setEditedDiscount(document.discount);
      setProductImages(document.images || []);
      setRating(document.rating || 0);
    }
  }, [document]);

  //START FETCH IMAGES FROM FIREBASE FOR OWL CAROUSEL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const servicesCollection = await projectFirestore
          .collection("products")
          .get();
        const servicesData = [];

        servicesCollection.forEach((doc) => {
          const product = doc.data();
          servicesData.push({
            id: doc.id,
            name: product.name,
            images: product.images || [], // Assuming "images" is the array of image names
            status: product.status,
          });
        });
        setAllProducts(servicesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  //END FETCH IMAGES FROM FIREBASE FOR OWL CAROUSEL

  //START  OWL CAROUSEL
  useEffect(() => {
    // Initialize Owl Carousel with the fetched products
    window.$(".owl-carousel").owlCarousel({
      className: "owl-theme",
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 5000,
      smartSpeed: 4000,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }, [allProducts]);
  //END OWL CAROUSEL

  if (error) {
    return (
      <div className="error" style={{ marginTop: "100px" }}>
        Data Not Found
      </div>
    );
  }
  if (!document) {
    return <div className="loading">Loading...</div>;
  }

  const handleAddMoreImages = () => {
    fileInputRef.current.click();
  };

  // add new images
  const handleImageChange = async (event) => {
    const files = event.target.files;
    const updatedImages = []; // Create a new array to store updated images

    try {
      // Upload new images to Firebase Storage and retrieve download URLs
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const storageRef = projectStorage.ref(
          `product_img/${productId}/${file.name}`
        );
        await storageRef.put(file);

        // Get the download URL for the uploaded file
        const downloadURL = await storageRef.getDownloadURL();

        // Push the download URL to the updatedImages array
        updatedImages.push(downloadURL);
      }

      // Get the current images array from the document
      const currentImages = document.images || [];

      // Combine the current images with the new images
      const allImages = [...currentImages, ...updatedImages];

      // Update the "images" array in Firestore
      await projectFirestore
        .collection("products")
        .doc(productId)
        .update({ images: allImages });

      // Update the state with the updatedImages array
      setProductImages(allImages);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  // Fetch images array from the document state
  const images = document.images || [];

  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setIsEditingName(true);
        break;
      case "price":
        setIsEditingPrice(true);
        break;
      case "discount":
        setIsEditingDiscount(true);
        break;
      case "shortdescription":
        setIsEditingShortDescription(true);
        break;
      case "description":
        setIsEditingDescription(true);
        break;
      default:
        break;
    }
  };

  const handleSaveClick = async (fieldName) => {
    try {
      switch (fieldName) {
        case "name":
          setIsEditingName(false);
          break;
        case "price":
          setIsEditingPrice(false);
          break;
        case "discount":
          setIsEditingDiscount(false);
          break;
        case "shortdescription":
          setIsEditingShortDescription(false);
          break;
        case "description":
          setIsEditingDescription(false);
          break;
        // Add more cases for other fields if needed
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setEditedName(document.name);
        setIsEditingName(false);
        break;
      case "price":
        setEditedPrice(document.price);
        setIsEditingPrice(false);
        break;
      case "discount":
        setEditedDiscount(document.discount);
        setIsEditingDiscount(false);
        break;
      case "shortdescription":
        setEditedShortDescription(document.short_description);
        setIsEditingShortDescription(false);
        break;
      case "description":
        setEditedDescription(document.description);
        setIsEditingDescription(false);
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  // START CODE FOR SHARE SERVICE
  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };
  // END CODE FOR SHARE SERVICE

  // START CODE FOR CHANGE SERVICE STATUS
  const handleToggleChange = (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Update the status in Firestore using updateDocument
    updateDocument(productId, { status: newStatus })
      .then(() => {
        console.log("Product status updated successfully");
        setChecked(newStatus === "active"); // Update the toggle state based on the new status
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };
  // END CODE FOR CHANGE SERVICE STATUS

  // delete document code
  // const handleDeleteProduct = async () => {
  //   try {
  //     // Open the delete confirmation popup
  //     setIsDeletePopupOpen(true);
  //   } catch (error) {
  //     console.error("Error deleting service:", error);
  //     // Handle the error as needed
  //   }
  // };
  // const handleConfirmDeleteProduct = async () => {
  //   try {
  //     await deleteDocument(productId);
  //     // Redirect to the services page
  //     window.location.href = "/products";
  //   } catch (error) {
  //     console.error("Error deleting service:", error);
  //     // Handle the error as needed
  //   }
  // };

  const handleSaveAddOption = async () => {
    try {
      // Get the current options array from the document
      const currentOptions = document.options || [];

      // Add the new option to the array
      const updatedOptions = [...currentOptions, AddOption];

      // Update the options array in Firestore
      await projectFirestore
        .collection("products")
        .doc(productId)
        .update({ options: updatedOptions });

      // Reset the AddOption state and close the input field
      setAddOption("");
      setIsAddOption(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  // const handleCancelAddOption = () => {
  //   setIsAddOption(false);
  // };

  // const handleOptionChange = (index) => {
  //   setSelectedOption(!selectedOption);
  //   setIsOptionSelected(true);
  //   setSelectedOptionIndex(index);
  // };
  // Fetch options array from the document state
  const options = document.options || [];
  // delete option
  const handleDeleteOption = async (index) => {
    try {
      // Get the current options array from the document
      const currentOptions = document.options || [];

      // Remove the selected option from the array
      const updatedOptions = currentOptions.filter((_, i) => i !== index);

      // Update the options array in Firestore
      await projectFirestore
        .collection("products")
        .doc(productId)
        .update({ options: updatedOptions });
    } catch (error) {
      console.error("Error deleting option:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY OPTIONS

  // RATING CODE START
  const handleRatingChange = (newValue) => {
    // Prevent setting the rating to null
    const newRating = newValue !== null ? newValue : rating;

    setRating(newRating);
    // Update the rating field in the Firebase document
    updateDocument(productId, { rating: newRating })
      .then(() => console.log("Rating updated successfully"))
      .catch((error) => console.error("Error updating rating:", error));
  };
  // END RATING CODE

  // Filter bookings for the selected product and log filteredBookings
  const filteredBookings = allBookings
    ? allBookings.filter(
        (booking) => booking.productId === (selectedProductId || productId)
      )
    : [];

  // CODE FOR GIVE PERMISSION TO USER ACCESS PRODUCTS
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Ensure that userList is an array and each user has a fullName or phoneNumber field
  const filteredUsers = userList?.filter((user) => {
    const hasFullName = user?.fullName;
    const hasPhoneNumber = user?.phoneNumber;
    const includesTerm =
      (hasFullName &&
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (hasPhoneNumber && user.phoneNumber.includes(searchTerm));
    return includesTerm;
  });

  if (userListError) {
    return <div>Error loading users</div>;
  }

  // Event handler for checkbox change
  const handleCheckboxChange = async (userId) => {
    if (selectedUsers.includes(userId)) {
      setShowSnackbar(false); // Show "Permission Removed"
      await removeProductUserMapping(userId);
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setShowSnackbar(true); // Show "Permission Given"
      await addProductUserMapping(userId);
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  // Function to add document in "product_user_mapping" collection
  const addProductUserMapping = async (userId) => {
    try {
      await addDocument({
        productId: productId, // Current product ID
        userId: userId, // Selected user ID
      });
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  // Function to remove document from "product_user_mapping" collection
  const removeProductUserMapping = async (userId) => {
    try {
      const mappingToDelete = userMappings.find(
        (mapping) => mapping.userId === userId
      );
      if (mappingToDelete) {
        await deleteProductUserMapping(mappingToDelete.id);
      }
    } catch (error) {
      console.error("Error removing document:", error);
    }
  };

  const isEditingAllowedToUser =
    user && (user.role === "admin" || selectedUsers.includes(user.uid));

  //  ADD DOCUMET IN PRODUCT USER MAP COLLECTION

  return (
    <>
      <div className="services-details-parent-div">
        <div>
          <Modal open={isPopupOpen} onClose={handleClosePopup}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                // minWidth: 600,
                display: "flex",
                flexDirection: "row",
                borderRadius: "20px",
                overflow: "hidden",
              }}
              className="product-details-right-list-parent"
            >
              <IconButton
                aria-label="close"
                onClick={handleClosePopup}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* left side list  */}
              <Box sx={{ flex: 1, mr: 2 }}>
                <Typography variant="h6" gutterBottom>
                  All Users
                </Typography>
                <TextField
                  label="Search Users"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ mb: 2 }}
                />
                <List sx={{ maxHeight: "400px", overflow: "auto" }}>
                  {filteredUsers && filteredUsers.length > 0 ? (
                    filteredUsers.map((user, index) => (
                      <ListItem key={index} sx={{ py: 1 }}>
                        <div className="d-flex align-items-center">
                          <Checkbox
                            sx={{
                              color: "#149aa2",
                              "&.Mui-checked": {
                                color: "primary.main",
                              },
                            }}
                            checked={selectedUsers.includes(user.id)}
                            onChange={() => handleCheckboxChange(user.id)}
                          />
                          <div className="d-flex flex-column ms-2">
                            <ListItemText
                              primaryTypographyProps={{
                                variant: "body1",
                                sx: { mb: -1 },
                              }}
                              primary={user.phoneNumber || "No Phone Number"}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                variant: "body2",
                                sx: { mt: 0, mb: -1 },
                              }}
                              primary={user.fullName || "Unknown"}
                            />
                          </div>
                        </div>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary="No users found" />
                    </ListItem>
                  )}
                </List>
              </Box>

              {/* Right side list */}
              <Box
                sx={{
                  width: "1px",
                  backgroundColor: "grey.400",
                  mx: 2,
                }}
                className="product-details-right-list"
              />

              <Box
                sx={{ flex: 1, ml: 2 }}
                className="product-details-right-list"
              >
                <Typography variant="h6" gutterBottom>
                  Selected Users
                </Typography>
                <List sx={{ maxHeight: "400px", overflow: "auto" }}>
                  {selectedUserDetails && selectedUserDetails.length > 0 ? (
                    selectedUserDetails.map((user, index) => (
                      <MDBCard key={index} className="selected-user-card mb-3">
                        <MDBCardBody>
                          <MDBTypography tag="h6">
                            {(user && user.fullName) || "Unknown"}
                          </MDBTypography>
                          <MDBCardText>
                            {(user && user.phoneNumber) || "No Phone Number"}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    ))
                  ) : (
                    <Typography>No selected users</Typography>
                  )}
                </List>
              </Box>
            </Box>
          </Modal>
        </div>
        {/* <Popup
          showPopupFlag={isDeletePopupOpen}
          setShowPopupFlag={setIsDeletePopupOpen}
          msg="Are you sure you want to delete this service?"
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              handleConfirmDeleteProduct();
            }
          }}
        /> */}

        <div className="container">
          <div className="owl-carousel owl-theme carousel_services more-products-carosel">
            {allProducts &&
              allProducts
                .filter((product) => product.status === "active") // Filter by active status
                .map((product) => (
                  <div
                    key={product.id}
                    className="item carousel_img_parent"
                    onClick={() => handleImageClick(product.id)}
                  >
                    <img
                      src={product.images[0]} // Use the URL of the first image
                      alt={product.name}
                      className="more_services_carousel_img"
                    />
                    <h2 className="more_services_img_heading">
                      <div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: product.name.toString("html"),
                          }}
                        ></p>
                      </div>
                    </h2>
                  </div>
                ))}
          </div>
          <div
            className="d-flex justify-content-center product-details-pg-srvc-heading"
            style={{
              backgroundColor: "var(--click-color)",
              height: "100px",
              borderRadius: "20px",
            }}
          >
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: document.name.toString("html"),
                }}
              ></p>
            </div>
          </div>

          <div className="services-images-slider-div-child">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="pcs_image_area">
                  <div className="bigimage_container">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        maxWidth: "600px",
                      }}
                    >
                      {user && user.role == "admin" && (
                        <div className="gallery-img-delete-icon">
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </div>
                      )}

                      <Gallery
                        style={{ background: "red" }}
                        items={images
                          .filter((url) => url)
                          .map((url) => ({
                            original: url,
                            thumbnail: url,
                          }))}
                        slideDuration={1000}
                      />
                    </div>
                    {/* Box for adding more images */}
                    {user && user.role == "admin" && (
                      <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          multiple
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <SnackBarNotification showSnackbar={showSnackbar} />
                        <button
                          className="btn_fill"
                          onClick={handleAddMoreImages}
                        >
                          Add More Images
                        </button>
                        <br />
                        {user && user.role == "admin" && (
                          <Button onClick={handleOpenPopup}>
                            Access Management
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-6 services_details_right_dv">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Name:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingName ? (
                            <div>
                              <TextEditor
                                initialDescription={editedName}
                                docFieldName="name"
                                documentId={productId}
                                collectionName="products"
                                onSave={() => handleSaveClick("name")}
                                onCancel={() => handleCancelClick("name")}
                              />
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: document.name.toString("html"),
                                }}
                              ></p>
                              {!isEditingName && isEditingAllowedToUser && (
                                <FaEdit
                                  style={{
                                    fontSize: 20,
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "var(--pink)",
                                  }}
                                  onClick={() => handleEditClick("name")}
                                />
                              )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    {document.rating && (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Rating:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <MDBRow>
                                <div className="d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: 200,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Rating
                                      name="hover-feedback"
                                      value={parseFloat(document.rating)}
                                      precision={0.5}
                                      readOnly={
                                        (user &&
                                          user.role !== "admin" &&
                                          !isEditingAllowedToUser) ||
                                        !user
                                      }
                                      getLabelText={getLabelText}
                                      onChange={(event, newValue) =>
                                        handleRatingChange(newValue)
                                      }
                                      onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                      }}
                                      emptyIcon={
                                        <StarIcon
                                          style={{ opacity: 0.45 }}
                                          fontSize="inherit"
                                        />
                                      }
                                    />
                                  </Box>
                                </div>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Price:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingPrice ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedPrice}
                                  docFieldName="price"
                                  documentId={productId}
                                  collectionName="products"
                                  onSave={() => handleSaveClick("price")}
                                  onCancel={() => handleCancelClick("price")}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: document.price.toString("html"),
                                  }}
                                ></p>
                                &nbsp;&nbsp;
                                {!isEditingPrice && isEditingAllowedToUser && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() => handleEditClick("price")}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    {(user && user.role == "admin") || document.discount ? (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Discount:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              {isEditingDiscount ? (
                                <div>
                                  <TextEditor
                                    initialDescription={editedDiscount}
                                    docFieldName="discount"
                                    documentId={productId}
                                    collectionName="products"
                                    onSave={() => handleSaveClick("discount")}
                                    onCancel={() =>
                                      handleCancelClick("discount")
                                    }
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="d-flex align-items-center">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          document.discount.toString("html"),
                                      }}
                                    ></p>
                                    {!isEditingDiscount &&
                                      isEditingAllowedToUser && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--pink)",
                                          }}
                                          onClick={() =>
                                            handleEditClick("discount")
                                          }
                                        />
                                      )}
                                  </div>
                                </>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : null}
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Short Description:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingShortDescription ? (
                            <div>
                              <div>
                                <TextEditor
                                  initialDescription={editedShortDescription}
                                  docFieldName="short_description"
                                  documentId={productId}
                                  collectionName="products"
                                  onSave={() =>
                                    handleSaveClick("shortdescription")
                                  }
                                  onCancel={() =>
                                    handleCancelClick("shortdescription")
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    document.short_description.toString("html"),
                                }}
                              ></p>
                              {!isEditingShortDescription &&
                                isEditingAllowedToUser && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--pink)",
                                    }}
                                    onClick={() =>
                                      handleEditClick("shortdescription")
                                    }
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    {/* <MDBRow>
                      <div className="radio-container">
                        {options.map((option, index) => (
                          <label
                            key={index}
                            className={`radio-container ${
                              selectedOptionIndex === index
                                ? "radio_btn_checked"
                                : " service_details_radion_btn"
                            }`}
                          >
                            <div className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="option"
                                value={option}
                                onChange={() => handleOptionChange(index)}
                                className="radio-button"
                              />
                              <span
                                className="material-symbols-outlined"
                                style={{ fontSize: "21px" }}
                              >
                                {selectedOptionIndex === index ? "done" : "add"}
                              </span>
                              {selctedOption && options}
                              {user && user.role === "admin" ? (
                                <span
                                  class="material-symbols-outlined"
                                  onClick={() => handleDeleteOption(index)}
                                >
                                  close
                                </span>
                              ) : null}
                            </div>
                          </label>
                        ))}
                        {isAddOption ? (
                          <div>
                            <input
                              type="text"
                              value={AddOption}
                              onChange={(e) => setAddOption(e.target.value)}
                            />
                            <div className="d-flex">
                              <button
                                className="product_edit_save_btn"
                                onClick={handleSaveAddOption}
                              >
                                Save
                              </button>
                              <button
                                className="product_edit_save_btn cancel-btn"
                                onClick={handleCancelAddOption}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {user && user.role == "admin" && (
                              <span
                                class="material-symbols-outlined"
                                onClick={handleAddOptionClick}
                                style={{ fontSize: "24px" }}
                              >
                                add_box
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </MDBRow> */}
                    {((user && user.role == "admin") ||
                      isEditingAllowedToUser) && (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Status: </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex">
                                <div>
                                  <p style={{ marginTop: "2px" }}>Inactive</p>
                                </div>
                                <ReactSwitch
                                  checked={document.status === "active"}
                                  onChange={() =>
                                    handleToggleChange(
                                      document.id,
                                      document.status
                                    )
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p style={{ marginTop: "2px" }}>Active</p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </MDBCardBody>
                </MDBCard>
                <div className="d-flex align-items-center namesd">
                  <MDBCol className="d-flex">
                    <MDBCardText style={{ cursor: "pointer" }}>
                      <span
                        className="material-symbols-outlined"
                        onClick={shareURL}
                        style={{
                          fontSize: "23px",
                          padding: "6px 0px",
                        }}
                      >
                        share
                      </span>
                    </MDBCardText>
                    <MDBCardText
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        className={`material-symbols-outlined ${
                          isHeartClicked ? "heart-pink" : ""
                        }`}
                        style={{
                          fontSize: "23px",
                          padding: "6px 0px",
                        }}
                        onClick={handleHeartClick}
                      >
                        favorite
                      </span>
                    </MDBCardText>

                    {/* {user && user.role == "admin" && (
                      <MDBCardText
                        onClick={handleDeleteProduct}
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: "23px",
                            padding: "6px 0px",
                          }}
                        >
                          delete
                        </span>
                      </MDBCardText>
                    )} */}

                    <MDBCardText className="d-flex align-items-center">
                      <div
                        style={{
                          borderLeft: "1px solid black",
                          marginLeft: "15px",
                          paddingLeft: "10px",
                        }}
                      >
                        <h4 style={{ fontSize: "15px" }}>
                          {1000 + filteredBookings.length}+ Bookings
                        </h4>
                        {/* <p style={{ fontSize: "11px" }}>for this product</p> */}
                      </div>
                    </MDBCardText>
                  </MDBCol>
                  {user ? (
                    <div className="more-div-big-icon-div-inner pointer">
                      <Link to={`/addbooking/${document.id}`}>
                        <button
                          type="submit"
                          className="booking_services_book_now_btn"
                        >
                          Choose Slots
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div className="more-div-big-icon-div-inner pointer">
                      <Link to="/login">
                        <button
                          type="submit"
                          className="booking_services_book_now_btn"
                        >
                          Choose Slots
                        </button>
                      </Link>
                    </div>
                  )}
                </div>

                {/* {!isOptionSelected && (
                  <p style={{ color: "red" }}>
                    Please select an option before checking out
                  </p>
                )} */}
              </div>
            </div>
          </div>
          <div className="container services_details_bottom_details_parent">
            {/* <section className="services_details_bottom_details_child"> */}
            <MDBRow className="justify-content-center align-items-center">
              <MDBCard style={{ borderRadius: "15px" }}>
                <MDBCardBody className="p-4">
                  <MDBTypography tag="h3">
                    <strong style={{ color: "var(--pink)" }}>
                      Description
                    </strong>
                  </MDBTypography>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-start align-items-center">
                    <MDBCardText className="mb-0">
                      <MDBCardText className="text-muted">
                        {isEditingDescription ? (
                          <div>
                            <div>
                              <TextEditor
                                initialDescription={editedDescription}
                                documentId={productId}
                                docFieldName="description"
                                collectionName="products"
                                onSave={() => handleSaveClick("description")}
                                onCancel={() =>
                                  handleCancelClick("description")
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: document.description.toString("html"),
                              }}
                            ></p>
                            {!isEditingDescription &&
                              isEditingAllowedToUser && (
                                <FaEdit
                                  style={{
                                    fontSize: 20,
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "var(--pink)",
                                  }}
                                  onClick={() => handleEditClick("description")}
                                />
                              )}
                          </div>
                        )}
                      </MDBCardText>
                    </MDBCardText>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
            {/* </section> */}
          </div>
        </div>
        {((user && user.role === "admin") || isEditingAllowedToUser) && (
          <SetupSlots productId={productId} />
        )}
        <div className="next_btn_back">
          {/* {formError && <p className="error">{formError}</p>} */}

          <div className="back_book_btn_parent">
            <div className="">
              <Link to="/products">
                <button type="submit" className="back-book-btn-real">
                  {"<< Back"}
                </button>
              </Link>
            </div>
            <div className="">
              <Link to={`/addbooking/${document.id}`}>
                <button type="submit" className="back-book-btn-real">
                  {" Choose Slots >>"}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
