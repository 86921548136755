import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useDocument } from "../hooks/useDocument";
import { Link } from "react-router-dom";
import UpcomingBookingsCard from "./UpcomingBookingsCard";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";
import Dental from "./Dental";
import Hijama from "./Hijama";
import Homeopathy from "./Homeopathy";
import Dermatology from "./Dermatology";
import "./Services.css";

// import owl carousel
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Services = () => {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );

  // Add Services in firestore
  const [isEditing, setIsEditing] = useState(false);
  const [productName, setProductName] = useState("Add Sub Category");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedService, setselectedService] = useState("Select Service");
  const [selectedCategory, setselectedCategory] = useState("Select Category");
  // Create a state variable for sub-services based on the selected service
  const [subServices, setSubServices] = useState([]);

  // Function to update sub-services based on the selected service
  const updateSubServices = (service) => {
    // Use a mapping or switch statement to set sub-services for each service
    let updatedSubServices = [];
    switch (service) {
      case "Dental":
        updatedSubServices = [
          "Paediatric Dentistry",
          "Restorative Treatments",
          "Prosthodontics",
        ];
        break;
      case "Hijama":
        updatedSubServices = ["Hijama Services"];
        break;
      case "Dermatology":
        updatedSubServices = ["Aesthetic Dermatology", "Cosmetology Services"];
        break;
      case "Homeopathy":
        updatedSubServices = [
          "Gut Health",
          "Child Health",
          "Women Health",
          "Skin & Hair",
        ];
        break;
      default:
        updatedSubServices = [];
    }

    // Update the sub-services state
    setSubServices(updatedSubServices);
  };

  const handleCategoryChange = (event) => {
    setselectedCategory(event);
  };
  const handleServiceChange = (event) => {
    const selectedService = event;
    setselectedService(selectedService);
    // Update sub-services based on the selected service
    updateSubServices(selectedService);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleNameClick = () => {
    setIsEditing(true);
    // Reset the product name input field when clicked
    setProductName("");
  };

  const handleAddProduct = async () => {
    // Validation: Check if a service is selected
    if (selectedService === "Select Service") {
      alert("Please Select a Service.");
      return;
    }

    // Validation: Check if product name is provided
    if (!productName || productName === "Service Name") {
      alert("Please Provide Service Name.");
      return;
    }

    // Validation: Check if a service is selected
    if (selectedCategory === "Select Category") {
      alert("Please Select a Category.");
      return;
    }

    try {
      // Create a new product document in the "products" collection
      const newProductRef = await projectFirestore.collection("services").add({
        sub_service: productName,
        price: "Price Not Available",
        service: selectedService,
        category: selectedCategory,
        discount: "Discount Not Available",
        rating: "Rating Not Available",
        status: "active",
        short_description: "Add Short Description",
        description: "Add Full Description About Product",
        createdAt: timestamp.fromDate(new Date()),
      });

      // Perform actions with the selected image, e.g., store in local storage
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // Store imageData in local storage or handle as needed
        localStorage.setItem("productImage", imageData);
      };
      reader.readAsDataURL(selectedImage);

      // Upload the image to the "product_img" folder in Firebase Storage
      if (selectedImage) {
        // Get the ID of the newly created document
        const newProductId = newProductRef.id;

        // Use the document ID as the folder name
        const storageRef = projectStorage.ref(`product_img/${newProductId}`);

        // Upload the image to the created folder in Firebase Storage
        const imageRef = storageRef.child(selectedImage.name);
        await imageRef.put(selectedImage);

        // Get the URL of the uploaded image
        const imageUrl = await imageRef.getDownloadURL();

        // Update the document with the image URL
        await newProductRef.update({
          product_img_1: imageUrl,
        });
      }

      // Reset product name and selected image after adding the product
      setProductName("Service Name");
      setSelectedImage(null);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // Reset product name and selected image
  const handleCancel = () => {
    setProductName("Service Name");
    setSelectedImage(null);
  };

  const images = [
    "./assets/img/dentamax services  banner.png",
    "./assets/img/about-us.png",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll = (e) => {
    if (e.deltaY > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [images]);

  const [activeSection, setActiveSection] = useState("Dental");
  const serviceHeadings = {
    Dental: "Dental Services",
    Dermatology: "Dermatology Services",
    Homeopathy: "Homeopathy Services",
    Hijama: "Hijama Services",
  };

  // Use the activeSection to get the corresponding heading
  const sectionHeading = serviceHeadings[activeSection] || "";

  // Use useEffect to set the initial state when the component mounts
  useEffect(() => {
    // Get the current URL path to determine the initial active section
    const currentPath = window.location.pathname;
    if (currentPath === "/dermatology") {
      setActiveSection("Dermatology");
    } else if (currentPath === "/homeopathy") {
      setActiveSection("Homeopathy");
    } else if (currentPath === "/hijama") {
      setActiveSection("Hijama");
    }
  }, []);

  // Function to scroll to the "our-services-heading" element
  const scrollToOurServicesHeading = () => {
    const ourServicesHeading = document.getElementById("our-services-heading");
    if (ourServicesHeading) {
      ourServicesHeading.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Function to handle the service click and scroll to "our-services-heading" if a specific service is clicked
  const handleServiceClick = (service) => {
    setActiveSection(service);
    if (
      service === "Dental" ||
      service === "Dermatology" ||
      service === "Homeopathy" ||
      service === "Hijama"
    ) {
      scrollToOurServicesHeading();
    }
  };

  // Initialize state for the banner image source
  const [bannerImages, setBannerImage] = useState("");

  // Use useEffect to set the banner image source based on the active section
  useEffect(() => {
    // Define a mapping of service names to banner images
    const bannerImages = {
      Dental: "./assets/img/dentamax services  banner.webp",
      Dermatology: "./assets/img/dermatology-banner.webp",
      Homeopathy: "./assets/img/homeopathy-banner.png",
      Hijama: "./assets/img/homebanner5.jpg",
    };

    // Set the banner image source based on the active section
    setBannerImage(bannerImages[activeSection]);
  }, [activeSection]);

  // Define variables for text content of each service
  let serviceHeading = "";
  let serviceDescription = "";

  // Update text content based on the active section
  if (activeSection === "Dental") {
    serviceHeading = "Integrated Healthcare Solutions";
    serviceDescription = "Solutions Catering to Your Diverse Medical Needs";
  } else if (activeSection === "Dermatology") {
    serviceHeading = "Skin health expertise.";
    serviceDescription = "Skin health, disorders, and treatments for all ages.";
  } else if (activeSection === "Homeopathy") {
    serviceHeading = "Natural remedy using diluted substances.";
    serviceDescription =
      "Alternative medicine based on diluted natural substances.";
  } else if (activeSection === "Hijama") {
    serviceHeading = "Therapeutic cupping therapy.";
    serviceDescription =
      "Traditional healing method using controlled suction cups.";
  }

  return (
    <div>
      <div className="pg_contact_us">
        <div className="banner relative">
          <img src={bannerImages} alt="Banner 32" />
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">{serviceHeading}</h3>
                <h2 className="services_banner_heading">
                  {serviceDescription}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* USER UPCOMING BOOKINGS CODE START */}
      {appointmentPermission && appointmentPermission.permission == true && (
        <UpcomingBookingsCard />
      )}

      {/* icons services section */}
      <div style={{ marginTop: "70px" }}>
        <div className="container-fluid">
          <div className="row">
            {/* left section */}
            <div className="col col-md-12 col-sm-12 col-lg-6">
              <div className="row">
                <h2 className="service_selct_choice">Select By Choice</h2>
              </div>
              <div className="services_icon_div">
                <div className="row">
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "Dental" ? "active-service" : ""
                    }`}
                    style={{
                      borderRight: "1px solid gray",
                      borderBottom: "1px solid gray",
                    }}
                    onClick={() => handleServiceClick("Dental")}
                  >
                    <img
                      src="https://cdnl.iconscout.com/lottie/premium/thumb/tooth-digging-6735859-5583541.gif"
                      className="services_icon_images"
                      alt="service logo img"
                    />
                    <h5>Dental</h5>
                  </div>
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "Dermatology" ? "active-service" : ""
                    }`}
                    style={{
                      borderBottom: "1px solid gray",
                    }}
                    onClick={() => handleServiceClick("Dermatology")}
                  >
                    <img
                      src="https://media4.giphy.com/media/FkOv06L1Q3uJqu5Myu/giphy.gif"
                      className="services_icon_images"
                      alt="service 1 logo img"
                    />
                    <h5>Dermatology</h5>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "Homeopathy" ? "active-service" : ""
                    }`}
                    style={{
                      borderRight: "1px solid gray",
                    }}
                    onClick={() => handleServiceClick("Homeopathy")}
                  >
                    <img
                      src="https://perfectheal.in/wp-content/uploads/2022/12/48-favorite-heart-outline-2.gif"
                      className="services_icon_images"
                      alt="service 3 logo img"
                    />
                    <h5>Homeopathy</h5>
                  </div>
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "Hijama" ? "active-service" : ""
                    }`}
                    onClick={() => handleServiceClick("Hijama")}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/6173/6173465.png"
                      className="services_icon_images"
                      alt="service 4 logo img"
                    />
                    <h5>Hijama</h5>
                  </div>
                </div>
              </div>
            </div>
            {/* right section */}
            <div className="col col-md-12 col-sm-12 col-lg-6 right_row_parent_div">
              <div style={{ position: "relative" }}>
                <img
                  src="./assets/img/service_direction_girl.png"
                  alt="direction_girl 4"
                  className="direction_girl"
                />
                <img
                  src="./assets/img/sevices_direction_circle.webp"
                  alt="direction_girl 5"
                  className="sevices_direction_circle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add New Service */}
      {user && user.role == "admin" && (
        <div className="container">
          <div className="row">
            <div className="add_new_service_sec_parent">
              <div className="add_new_srvic_heading">
                <span
                  class="material-symbols-outlined"
                  style={{ color: "white" }}
                >
                  concierge
                </span>{" "}
                &nbsp;
                <h4>Add New Service</h4>
              </div>
              <div className="new_service_details_parent">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <div className="new_service_img_div">
                      <div className="doctor-top d-flex align-items-start justify-content-start">
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="product 3 logo img"
                          />
                        ) : (
                          <img
                            src="https://securitysolutions.me/wp-content/uploads/2018/12/dummy-person.jpg"
                            alt="Product 54"
                          />
                        )}
                        <div className="new_service_add_img_btn">
                          <div>
                            <input
                              type="file"
                              accept="image/*"
                              id="imageInput"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor="imageInput"
                              style={{ cursor: "pointer" }}
                              className="btn_fill_add_img_service"
                            >
                              Add Image
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm-12 col-md-8 col-lg-8">
                    <div className="new_service_text_details_div">
                      <div className="select_new_srvic_heading">
                        <span
                          class="material-symbols-outlined"
                          style={{ color: "white" }}
                        >
                          concierge
                        </span>{" "}
                        &nbsp;
                        <h4>Select Service</h4>
                      </div>
                      <div className="btn_fill_service_parent_div">
                        {["Dental", "Dermatology", "Hijama", "Homeopathy"].map(
                          (category, index) => (
                            <button
                              key={index}
                              className={`btn btn_fill_service ${
                                selectedService === category
                                  ? "selected_services_btun"
                                  : ""
                              }`}
                              // className="btn btn_fill_service"
                              onClick={() => handleServiceChange(category)}
                            >
                              <span className="material-symbols-outlined">
                                {/* Your icon here */}
                              </span>
                              {category}
                            </button>
                          )
                        )}
                      </div>

                      <div
                        style={{
                          backgroundColor: "white",
                          height: "2px",
                          margin: "10px 0px",
                        }}
                      ></div>
                      <div className="services_cancel_and_add_btn">
                        <div className="d-flex align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                              style={{ backgroundColor: "var(--pink)" }}
                            >
                              {selectedCategory}{" "}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {/* Use the subServices state to dynamically render dropdown options */}
                              {subServices.map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleCategoryChange(option)}
                                >
                                  {option}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="new_service_text_details_child_div">
                        <div className="new_service_name_price">
                          <input
                            type="text"
                            value={productName}
                            onChange={handleNameChange}
                            onBlur={handleNameBlur}
                            onClick={handleNameClick}
                            required
                            className="add_services_booking_input_field"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          style={{
                            backgroundColor: "white",
                            height: "2px",
                            margin: "10px 0px",
                          }}
                        ></div>
                        <div className="new_service_name_price">
                          <button
                            className="btn btn_fill"
                            style={{ backgroundColor: "var(--pink)" }}
                            onClick={handleCancel}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn_fill"
                            onClick={handleAddProduct}
                            style={{ backgroundColor: "var(--pink)" }}
                          >
                            Add Service
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* show services category wise */}
      <div>
        <div id="our-services-heading">
          <h1
            style={{
              textAlign: "center",
              // marginTop: "100px",
              backgroundColor: "var(--white-color)",
              color: "var(--white-color)",
              fontSize: "25px",
              padding: "10px",
              fontWeight: "700",
            }}
          >
            {sectionHeading}
          </h1>
        </div>
        <h5
          style={{
            textAlign: "center",
            // marginTop: "100px",
            backgroundColor: "var(--click-color)",
            color: "white",
            // fontSize: "30px",
            padding: "5px",
            fontWeight: "700",
          }}
        >
          {sectionHeading}
        </h5>
        {/* Conditionally render the active section */}
        {activeSection === "Dental" && <Dental />}
        {activeSection === "Dermatology" && <Dermatology />}
        {activeSection === "Homeopathy" && <Homeopathy />}
        {activeSection === "Hijama" && <Hijama />}
      </div>

      {/* top four services */}
      <div className="container parent_div">
        <div className="row">
          <div className="services_heading">
            <p>Our Services</p>
            <h1 id="top_heading">
              Our Comprehensive Range of Tailored Services
            </h1>
          </div>

          <div
            className="col-md-3 col-6 img-parent"
            onClick={() => handleServiceClick("Dental")}
          >
            <img
              src="./assets/img/dental_img.jpg"
              alt="dental_img"
              className="services-img"
            />
            <h2>Dental</h2>
            <h5>Dental care ensures a healthy smile and overall well-being.</h5>
          </div>
          <div
            className="col-md-3 col-6 img-parent"
            onClick={() => handleServiceClick("Dermatology")}
          >
            <img
              src="./assets/img/dermatolgy-service.jpg"
              alt="dental_img 2"
              className="services-img"
            />
            <h2>Dermatology</h2>
            <h5>
              Dermatology provides expert skin care for a radiant, healthy
              appearance.{" "}
            </h5>
          </div>
          <div
            className="col-md-3 col-6 img-parent"
            onClick={() => handleServiceClick("Homeopathy")}
          >
            <img
              src="./assets/img/homeopathy.jpg"
              alt="dental_img 4"
              className="services-img"
            />
            <h2>Homeopathy</h2>
            <h5>
              Homeopathy offers natural remedies tailored to enhance holistic
              wellness effectively.{" "}
            </h5>
          </div>
          <div
            className="col-md-3 col-6 img-parent"
            onClick={() => handleServiceClick("Hijama")}
          >
            <img
              src="./assets/img/hijama_img.jpg"
              alt="dental_img 5"
              className="services-img"
            />
            <h2>Hijama</h2>
            <h5>
              Hijama therapy promotes well-being through traditional cupping for
              enhanced vitality.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
